import {
  UserLightModel,
  UserLightRawModel,
} from 'product-types/src/domain/user/UserLightModel';
import { SelectOption } from 'product-ui/src/components/atoms/Interfaces/SelectOption';

export interface IpRemovalReasonCategoryRaw {
  id: number;
  reason: string;
  display_name: string;
  message_to_site: string | null;
  created_at?: string;
  created_by?: UserLightRawModel;
  updated_at?: string;
  updated_by?: UserLightRawModel;
  is_active: boolean;
}

export class IpRemovalReasonCategoryModel implements SelectOption {
  id: number;

  reason: string;

  displayName: string;

  messageToSite: string | null;

  createdAt: string | null;

  createdBy: UserLightModel | null;

  updatedAt: string | null;

  updatedBy: UserLightModel | null;

  isActive: boolean;

  constructor(
    prop: Pick<
      IpRemovalReasonCategoryModel,
      | 'id'
      | 'reason'
      | 'displayName'
      | 'messageToSite'
      | 'createdAt'
      | 'createdBy'
      | 'updatedAt'
      | 'updatedBy'
      | 'isActive'
    >,
  ) {
    this.id = prop.id;
    this.reason = prop.reason;
    this.displayName = prop.displayName;
    this.messageToSite = prop.messageToSite;
    this.createdAt = prop.createdAt;
    this.createdBy = prop.createdBy;
    this.updatedAt = prop.updatedAt;
    this.updatedBy = prop.updatedBy;
    this.isActive = prop.isActive;
  }

  get label() {
    return this.displayName;
  }

  get value() {
    return this.id;
  }

  static createFromRawModel(prop: IpRemovalReasonCategoryRaw) {
    return new IpRemovalReasonCategoryModel({
      id: prop.id,
      reason: prop.reason,
      displayName: prop.display_name,
      messageToSite: prop.message_to_site,
      createdAt: prop.created_at ?? null,
      createdBy: prop.created_by
        ? UserLightModel.createFromRawModel(prop.created_by)
        : null,
      updatedAt: prop.updated_at ?? null,
      updatedBy: prop.updated_by
        ? UserLightModel.createFromRawModel(prop.updated_by)
        : null,
      isActive: prop.is_active,
    });
  }

  static sortAlphabeticallyWithOtherLast(
    a: IpRemovalReasonCategoryModel,
    b: IpRemovalReasonCategoryModel,
  ) {
    if (a.displayName === 'Other') return 1;
    if (b.displayName === 'Other') return -1;
    return a.displayName.localeCompare(b.displayName);
  }
}
