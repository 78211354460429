import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import ProductMonitor from 'types/network/Http/productMonitor';
import { GetAllResponseModel } from 'types/network/Http/productMonitor/endpoints/imageFeatures/getAll/getAll';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { AppState } from 'store/storeAccess';
import { loadGlobalDataAction } from 'layout/FiltersBar/actions';
import { notification } from 'antd';
import {
  loadImageFeatures as loadImageFeaturesAction,
  updateImageFeatures,
} from './actions';
import {
  CREATE_IMAGE_FEATURE,
  DELETE_IMAGE_FEATURE,
  LOAD_IMAGE_FEATURES,
  UPDATE_IMAGE_FEATURE,
} from './constants';

function* loadImageFeatures() {
  yield put(
    updateImageFeatures(
      new FetchableData<Array<ModerationReason>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );
  try {
    const response: GetAllResponseModel = yield call(
      ProductMonitor.endpoints.imageFeatures.getAll.call.bind(
        ProductMonitor.endpoints.imageFeatures.getAll,
      ),
      {},
    );
    yield put(
      updateImageFeatures(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.LOADED,
          data: response.image_features.map((reason) =>
            ModerationReason.createReasonFromRaw(reason),
          ),
          error: null,
        }),
      ),
    );
  } catch (err: any) {
    console.error(err);
    yield put(
      updateImageFeatures(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: null,
          error: CustomErrorFactory.create(err),
        }),
      ),
    );
  }
}

function* deleteImageFeature(action: { id: number }) {
  const features = yield select(
    (st: AppState) => st.imageFeatures.imageFeatures.data,
  );
  yield put(
    updateImageFeatures(
      new FetchableData<Array<ModerationReason>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: features,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.imageFeatures.deleteFeature.call.bind(
        ProductMonitor.endpoints.imageFeatures.deleteFeature,
      ),
      {
        urlParams: {
          id: `${action.id}`,
        },
      },
    );
  } catch (err: any) {
    console.error(err);
  } finally {
    yield put(loadImageFeaturesAction());
    yield put(loadGlobalDataAction());
  }
}

function* updateImageFeature(action: { imageFeature: ModerationReason }) {
  const features = yield select(
    (st: AppState) => st.imageFeatures.imageFeatures.data,
  );
  yield put(
    updateImageFeatures(
      new FetchableData<Array<ModerationReason>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: features,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.imageFeatures.updateFeature.call.bind(
        ProductMonitor.endpoints.imageFeatures.updateFeature,
      ),
      {
        urlParams: {
          id: `${action.imageFeature.moderationReasonId}`,
        },
        data: action.imageFeature.toRaw(),
      },
    );
    notification.success({
      message: 'Image Feature was updated successfully',
      placement: 'bottomRight',
      duration: 3,
    });
    yield put(loadImageFeaturesAction());
  } catch (err: any) {
    console.error(err);
    yield put(
      updateImageFeatures(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: features,
          error: null,
        }),
      ),
    );
  } finally {
    yield put(loadGlobalDataAction());
  }
}

function* createImageFeature(action: { imageFeature: ModerationReason }) {
  const features = yield select(
    (st: AppState) => st.imageFeatures.imageFeatures.data,
  );
  yield put(
    updateImageFeatures(
      new FetchableData<Array<ModerationReason>>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: features,
        error: null,
      }),
    ),
  );
  try {
    yield call(
      ProductMonitor.endpoints.imageFeatures.createFeature.call.bind(
        ProductMonitor.endpoints.imageFeatures.createFeature,
      ),
      {
        data: action.imageFeature.toRaw(),
      },
    );
    notification.success({
      message: 'Image Feature was created successfully',
      placement: 'bottomRight',
      duration: 3,
    });
    yield put(loadImageFeaturesAction());
  } catch (err: any) {
    console.error(err);
    yield put(
      updateImageFeatures(
        new FetchableData<Array<ModerationReason>>({
          abortController: null,
          state: FetchableDataState.ERROR,
          data: features,
          error: null,
        }),
      ),
    );
  } finally {
    yield put(loadGlobalDataAction());
  }
}

export default function* imageFeatureSaga() {
  yield takeLatest(LOAD_IMAGE_FEATURES, loadImageFeatures);
  yield takeLatest(DELETE_IMAGE_FEATURE, deleteImageFeature);
  yield takeLatest(UPDATE_IMAGE_FEATURE, updateImageFeature);
  yield takeLatest(CREATE_IMAGE_FEATURE, createImageFeature);
}
