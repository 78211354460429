import { KeywordRaw } from 'product-types/src/domain/keyword/Keyword';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface GetHashtagResponseModel {
  keywords: Array<KeywordRaw>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GetHashtagRequestModel extends EndpointRequests {}

const getHashtags = new Endpoint<
  GetHashtagRequestModel,
  GetHashtagResponseModel
>({
  url: '/api/me/crawling_config/keywords',
  method: 'GET',
  headers: {},
});

export default getHashtags;
