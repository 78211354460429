import React, { CSSProperties } from 'react';

export enum FontWeight {
  thin = 100,
  extraLight = 200,
  light = 300,
  regular = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
  extraBold = 800,
  black = 900,
}
export enum TextTransform {
  uppercase = 'uppercase',
  lowercase = 'lowercase',
  capitalize = 'capitalize',
}

export enum TextDecoration {
  none = 'none',
  underline = 'underline',
  overline = 'overline',
  lineThrough = 'line-through',
}

export enum TypographyVariant {
  xxsmall = 'xxsmall',
  xsmall = 'xsmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  h7 = 'h7',
}

export const DefaultTagForVariant: Record<
  TypographyVariant,
  keyof JSX.IntrinsicElements
> = {
  xxsmall: 'div',
  xsmall: 'div',
  small: 'div',
  medium: 'div',
  large: 'div',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'h6',
};

export const DefaultStylesForVariant: Record<
  TypographyVariant,
  React.CSSProperties
> = {
  xxsmall: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  xsmall: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  small: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  medium: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  large: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  h1: {
    fontSize: '104px',
    lineHeight: '160px',
    marginBottom: 0,
    fontFamily: 'WhyteInktrap',
    fontWeight: 'light',
  },
  h2: {
    fontSize: '72px',
    lineHeight: '96px',
    marginBottom: 0,
    fontFamily: 'WhyteInktrap',
    fontWeight: 'medium',
  },
  h3: {
    fontSize: '64px',
    lineHeight: '88px',
    marginBottom: 0,
    fontFamily: 'WhyteInktrap',
    fontWeight: 'medium',
  },
  h4: {
    fontSize: '40px',
    lineHeight: '56px',
    marginBottom: 0,
  },
  h5: {
    fontSize: '32px',
    lineHeight: '48px',
    marginBottom: 0,
  },
  h6: {
    fontSize: '24px',
    lineHeight: '40px',
    marginBottom: 0,
  },
  h7: {
    fontSize: '20px',
    lineHeight: '32px',
    marginBottom: 0,
  },
};

export interface TypographyProps {
  children?: React.ReactNode;
  color?: string;
  tag?: keyof JSX.IntrinsicElements;
  variant?: TypographyVariant | keyof typeof TypographyVariant;
  fontWeight?: FontWeight | keyof typeof FontWeight;
  letterSpacing?: string;
  opacity?: number;
  textAlign?: CSSProperties['textAlign'];
  textOverflow?: CSSProperties['textOverflow'];
  whiteSpace?: CSSProperties['whiteSpace'];
  textTransform?: TextTransform | keyof typeof TextTransform;
  textDecoration?: TextDecoration | keyof typeof TextDecoration;
  dataTestId?: string;
  style?: React.CSSProperties;
  className?: string;
  hidden?: boolean;
  ref?: React.Ref<any>;
  onClick?: (e: any) => void;
  onMouseOver?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
}
