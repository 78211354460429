import { useEffect, useRef } from 'react';
import { useNavigationType, useLocation } from 'react-router-dom';
import { Location } from 'history';

export function useBrowserNavigation(
  cb: (location: Location, direction?: 'FORWARD' | 'BACKWARD') => void,
  navAction: 'POP' | 'PUSH' | 'REPLACE' = 'POP',
) {
  const location = useLocation();
  const navigationType = useNavigationType();

  const locationKeys = useRef<Array<string | undefined>>([]);
  const lastLocationKey = useRef<string | undefined>();

  useEffect(() => {
    const handleNavigation = () => {
      const currentKey = location.key;

      if (navigationType === 'PUSH') {
        locationKeys.current = [currentKey];
      }

      if (navigationType === navAction) {
        cb(location);
      }

      if (navigationType === 'POP') {
        if (locationKeys.current[1] === currentKey) {
          const [, ...newKeys] = locationKeys.current;
          locationKeys.current = newKeys;
          lastLocationKey.current = currentKey;
          cb(location, 'FORWARD');
        } else {
          locationKeys.current = [currentKey, ...locationKeys.current];
          lastLocationKey.current = currentKey;
          cb(location, 'BACKWARD');
        }
      }

      if (navigationType === 'REPLACE') {
        const index = locationKeys.current.findIndex(
          (key) => key === lastLocationKey.current,
        );
        if (index !== -1) {
          locationKeys.current[index] = currentKey;
        }
        if (locationKeys.current.length === 0) {
          locationKeys.current = [currentKey];
        }
      }

      lastLocationKey.current = currentKey;
    };

    handleNavigation();
  }, [location.key, navigationType, navAction]);
}
