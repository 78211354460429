import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface RetryUploadRequest extends EndpointRequests {
  data: {
    upload_id: any;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface RetryUploadResponse {}

const retryUpload = new Endpoint<RetryUploadRequest, RetryUploadResponse>({
  url: `/api/me/uploads/retry`,
  method: 'POST',
  headers: {},
});

export default retryUpload;
