/* eslint-disable react/prop-types, react/jsx-no-useless-fragment, react/no-unused-prop-types */
/* tslint:disable: TS2604 */
import React from 'react';
import { Remove } from '../../../icons/components/Remove';
import { ReactComponent as InfoCircleOutlined } from '../../../icons/navee-icons/mdi_information-outline.svg';
import { ReactComponent as Share } from '../../../icons/navee-icons/share.svg';
import { ReactComponent as ObviousCounterfeitElements } from '../../../icons/navee-icons/ObviousCounterfeitElements.svg';
import { ReactComponent as HighlySuspiciousElements } from '../../../icons/navee-icons/HighlySuspiciousElements.svg';
import { ReactComponent as DocTwo } from '../../../icons/navee-icons/DocTwo.svg';
import { ReactComponent as SuspiciousReasons } from '../../../icons/navee-icons/SuspiciousReasons.svg';
import { ReactComponent as ImageButton } from '../../../icons/navee-icons/ImageButton.svg';
import { ReactComponent as Account } from '../../../icons/navee-icons/account.svg';
import { ReactComponent as ImageFilter } from '../../../icons/navee-icons/mdi_image-filter-hdr.svg';
import { ReactComponent as AccountSmallIcon } from '../../../icons/navee-icons/accountSmallIcon.svg';
import { ReactComponent as AccountFirstVariant } from '../../../icons/navee-icons/accountBoxFirstIcon.svg';
import { ReactComponent as AccountSecondVariant } from '../../../icons/navee-icons/accountBoxSecondIcon.svg';
import { ReactComponent as AccountThirdVariant } from '../../../icons/navee-icons/accountBoxThirtIcon.svg';
import { ReactComponent as ImageFeatures } from '../../../icons/navee-icons/imageFeatures.svg';
import { ReactComponent as StartValueFalse } from '../../../icons/navee-icons/StartValueFalse.svg';
import { ReactComponent as StartValueTrue } from '../../../icons/navee-icons/StartValueTrue.svg';
import { ReactComponent as StartOnHover } from '../../../icons/navee-icons/StartOnHover.svg';
import { ReactComponent as StarCrossedOut } from '../../../icons/navee-icons/StarCrossedOut.svg';
import { ReactComponent as Phone } from '../../../icons/navee-icons/phone.svg';
import { ReactComponent as Mail } from '../../../icons/navee-icons/mail.svg';
import { ReactComponent as DotsIcon } from '../../../icons/navee-icons/dotsIcon.svg';
import { ReactComponent as OpenInNewTabs } from '../../../icons/navee-icons/OpenInNewTabs.svg';
import { ReactComponent as ModerationPercentage } from '../../../icons/navee-icons/moderationPercentageIcon.svg';
import { ReactComponent as DotsVertical } from '../../../icons/navee-icons/dotsVertical.svg';
import { ReactComponent as DollarBill } from '../../../icons/navee-icons/DollarBill.svg';
import { ReactComponent as Image } from '../../../icons/navee-icons/imageIco.svg';
import { ReactComponent as NoVendor } from '../../../icons/navee-icons/noVendorIcon.svg';
import { ReactComponent as View } from '../../../icons/navee-icons/view.svg';
import { ReactComponent as Post } from '../../../icons/navee-icons/post.svg';
import { ReactComponent as ArrowDownTwo } from '../../../icons/navee-icons/arrowDownTwo.svg';
import { ReactComponent as Vendor } from '../../../icons/navee-icons/vendorButtonIcon.svg';
import { ReactComponent as ListedBrand } from '../../../icons/navee-icons/listedBrand.svg';
import { ReactComponent as Plus } from '../../../icons/navee-icons/plusIcon.svg';
import { ReactComponent as Cross } from '../../../icons/navee-icons/crossIcon.svg';
import { ReactComponent as Notification } from '../../../icons/navee-icons/notification.svg';
import { ReactComponent as Website } from '../../../icons/navee-icons/mdi_file-document-remove-outline.svg';
import { ReactComponent as ArrowUp } from '../../../icons/navee-icons/arrowUpIcon.svg';
import { ReactComponent as Link } from '../../../icons/navee-icons/linkIcon.svg';
import { ReactComponent as Tag } from '../../../icons/navee-icons/tagIcon.svg';
import { ReactComponent as SortDecending } from '../../../icons/navee-icons/SortDecending.svg';
import { ReactComponent as SortAscending } from '../../../icons/navee-icons/SortAscending.svg';
import { ReactComponent as SortNoSort } from '../../../icons/navee-icons/SortNoSort.svg';
import { ReactComponent as Info } from '../../../icons/navee-icons/infoIcon.svg';
import { ReactComponent as Download } from '../../../icons/navee-icons/downloadIcon.svg';
import { ReactComponent as Statistics } from '../../../icons/navee-icons/statistics.svg';
import { ReactComponent as ImageSearch } from '../../../icons/navee-icons/imageSearch.svg';
import { ReactComponent as ListView } from '../../../icons/navee-icons/listViewIcon.svg';
import { ReactComponent as BlueLink } from '../../../icons/navee-icons/blueLink.svg';
import { ReactComponent as TutorialMode } from '../../../icons/navee-icons/TutorialMode.svg';
import { ReactComponent as Lock } from '../../../icons/navee-icons/lock.svg';
import { ReactComponent as UploadTag } from '../../../icons/navee-icons/uploadTag.svg';
import { ReactComponent as UploadArrow } from '../../../icons/navee-icons/uploadArrow.svg';
import { ReactComponent as Attach } from '../../../icons/navee-icons/attach.svg';
import { ReactComponent as Dashboard } from '../../../icons/navee-icons/dashboard.svg';
import { ReactComponent as reasonChecked } from '../../../icons/navee-icons/reasonChecked.svg';
import { ReactComponent as ReasonNotChecked } from '../../../icons/navee-icons/reasonNotChecked.svg';
import { ReactComponent as ImageView } from '../../../icons/navee-icons/image.svg';
import { ReactComponent as Setting } from '../../../icons/navee-icons/setting.svg';
import { ReactComponent as FullScreen } from '../../../icons/navee-icons/fullscreenIcon.svg';
import { ReactComponent as LeftArrow } from '../../../icons/navee-icons/sliderLeftArrow.svg';
import { ReactComponent as RightArrow } from '../../../icons/navee-icons/sliderRightArrow.svg';
import { ReactComponent as RightArrowAlt } from '../../../icons/navee-icons/ArrowRightAlt.svg';
import { ReactComponent as MiniInfo } from '../../../icons/navee-icons/miniInfoIcon.svg';
import { ReactComponent as InfoIconWithQuestion } from '../../../icons/navee-icons/bigInfoIconWithQuestionFlag.svg';
import { ReactComponent as ImageDownloadIcon } from '../../../icons/navee-icons/imageDownloadIcon.svg';
import { ReactComponent as TrendUp } from '../../../icons/navee-icons/trendUp.svg';
import { ReactComponent as TrendDown } from '../../../icons/navee-icons/trendDown.svg';
import { ReactComponent as ECommerce } from '../../../icons/navee-icons/eCommerce.svg';
import { ReactComponent as Export } from '../../../icons/navee-icons/export.svg';
import { ReactComponent as ExportArrowUp } from '../../../icons/navee-icons/exportArrowUp.svg';
import { ReactComponent as Star } from '../../../icons/navee-icons/star.svg';
import {
  ReactComponent as Pencil,
  ReactComponent as PencilIcon,
} from '../../../icons/navee-icons/pencil.svg';
import { ReactComponent as CheckGreen } from '../../../icons/navee-icons/check-green.svg';
import { ReactComponent as Menu } from '../../../icons/navee-icons/menu.svg';
import { ReactComponent as PostFilter } from '../../../icons/navee-icons/mdi_post-filter-hdr.svg';
import { ReactComponent as Group } from '../../../icons/navee-icons/Group.svg';
import { ReactComponent as TagPointIcon } from '../../../icons/navee-icons/tagPointIcon.svg';
import { ReactComponent as TagPointIconDisable } from '../../../icons/navee-icons/tagPointIconDisable.svg';
import { ReactComponent as SearchIcon } from '../../../icons/navee-icons/searchIcon.svg';
import { ReactComponent as BlockIcon } from '../../../icons/navee-icons/block.svg';
import { ReactComponent as CheckCircleOutline } from '../../../icons/navee-icons/CheckCircleOutline.svg';
import { ReactComponent as RoundArrow } from '../../../icons/navee-icons/roundArrow.svg';
import { ReactComponent as RefreshArrow } from '../../../icons/navee-icons/refreshIcon.svg';
import { ReactComponent as Checked } from '../../../icons/navee-icons/checked.svg';
import { ReactComponent as Check } from '../../../icons/navee-icons/check.svg';
import { ReactComponent as Validated } from '../../../icons/navee-icons/validated.svg';
import { ReactComponent as EmptyStatus } from '../../../icons/navee-icons/emptyStatus.svg';
import { ReactComponent as CheckStroked } from '../../../icons/navee-icons/CheckStroked.svg';
import { ReactComponent as MagicPill } from '../../../icons/navee-icons/MagicPill.svg';
import { ReactComponent as DocIcon } from '../../../icons/navee-icons/docIcon.svg';
import { ReactComponent as AccountModIcon } from '../../../icons/navee-icons/accountModIcon.svg';
import { ReactComponent as DollarIcon } from '../../../icons/navee-icons/dollarIcon.svg';
import { ReactComponent as SearchInDocIcon } from '../../../icons/navee-icons/searchInDocIcon.svg';
import { ReactComponent as TrashIcon } from '../../../icons/navee-icons/trash.svg';
import { ReactComponent as TrashLightIcon } from '../../../icons/navee-icons/trash-light.svg';
import { ReactComponent as EyeStroked } from '../../../icons/navee-icons/eyeStroked.svg';
import { ReactComponent as Eye } from '../../../icons/navee-icons/eye.svg';
import { ReactComponent as ArrowDownSimple } from '../../../icons/navee-icons/ArrowDown.svg';
import { ReactComponent as StockIcon } from '../../../icons/navee-icons/stockIcon.svg';
import { ReactComponent as StockFilledIcon } from '../../../icons/navee-icons/stockFilled.svg';
import { ReactComponent as RoundArticle } from '../../../icons/navee-icons/RoundArticle.svg';
import { ReactComponent as CrossCircleOutline } from '../../../icons/navee-icons/CrossCircleOutline.svg';
import { ReactComponent as Pdf } from '../../../icons/navee-icons/pdf.svg';
import { ReactComponent as Audio } from '../../../icons/navee-icons/audio.svg';
import { ReactComponent as Video } from '../../../icons/navee-icons/video.svg';
import { ReactComponent as Text } from '../../../icons/navee-icons/text.svg';
import { ReactComponent as Powerpoint } from '../../../icons/navee-icons/powerpoint.svg';
import { ReactComponent as LoadingOutlined } from '../../../icons/navee-icons/LoadingOutlined.svg';
import { ReactComponent as Word } from '../../../icons/navee-icons/word.svg';
import { ReactComponent as Xls } from '../../../icons/navee-icons/xls.svg';
import { ReactComponent as PriceIcon } from '../../../icons/navee-icons/priceIcon.svg';
import { ReactComponent as VendorBoxIcon } from '../../../icons/navee-icons/vendorBoxIcon.svg';
import { ReactComponent as PriceBoxIcon } from '../../../icons/navee-icons/priceBoxIcon.svg';
import { ReactComponent as Zip } from '../../../icons/navee-icons/zip.svg';
import { ReactComponent as File } from '../../../icons/navee-icons/file.svg';
import { ReactComponent as LinkIcon } from '../../../icons/navee-icons/linkIconBig.svg';
import { ReactComponent as Gallery } from '../../../icons/navee-icons/gallery.svg';
import { ReactComponent as Logout } from '../../../icons/navee-icons/logout.svg';
import { ReactComponent as MinusCircleOutline } from '../../../icons/navee-icons/MinusCircleOutline.svg';
import { ReactComponent as ArrowRightOutlined } from '../../../icons/navee-icons/ArrowRightOutlined.svg';
import { ReactComponent as InfringementPercentage } from '../../../icons/navee-icons/infringementPercentage.svg';
import { ReactComponent as ArrowLeftOutlined } from '../../../icons/navee-icons/ArrowLeftOutlined.svg';
import { ReactComponent as Calendar } from '../../../icons/navee-icons/calendar.svg';
import { ReactComponent as Instagram } from '../../../icons/navee-icons/instagram.svg';
import { ReactComponent as Facebook } from '../../../icons/navee-icons/facebook.svg';
import { ReactComponent as Envelop } from '../../../icons/navee-icons/envelop.svg';
import { ReactComponent as WeChat } from '../../../icons/navee-icons/weChat.svg';
import { ReactComponent as Whatsapp } from '../../../icons/navee-icons/whatsapp.svg';
import { ReactComponent as WhatsappFilled } from '../../../icons/navee-icons/whatsappFilled.svg';
import { ReactComponent as ChevronDown } from '../../../icons/navee-icons/chevronDown.svg';
import { ReactComponent as ContactInfo } from '../../../icons/navee-icons/contactInfo.svg';
import { ReactComponent as ContentCopy } from '../../../icons/navee-icons/contentCopy.svg';
import { ReactComponent as MagnifyingGlass } from '../../../icons/navee-icons/magnifyingGlass.svg';
import { ReactComponent as ManageHistory } from '../../../icons/navee-icons/manageHistory.svg';
import { ReactComponent as Warning } from '../../../icons/navee-icons/warning.svg';
import { ReactComponent as WarningTriangle } from '../../../icons/navee-icons/warning-triangle.svg';
import { ReactComponent as WorldWww } from '../../../icons/navee-icons/worldWww.svg';
import { ReactComponent as CardImage } from '../../../icons/navee-icons/cardImage.svg';
import { ReactComponent as CalendarTwoTone } from '../../../icons/navee-icons/calendarTwoTone.svg';
import { ReactComponent as ShadowFollower } from '../../../icons/navee-icons/shadowFollower.svg';
import { ReactComponent as Box } from '../../../icons/navee-icons/box.svg';
import { ReactComponent as Tags } from '../../../icons/navee-icons/tags.svg';
import { ReactComponent as UploadTypeTag } from '../../../icons/navee-icons/uploadTypeTag.svg';
import { ReactComponent as StatusPal } from '../../../icons/navee-icons/statusPal.svg';
import { ReactComponent as Web } from '../../../icons/navee-icons/web.svg';
import { ReactComponent as RoundLink } from '../../../icons/navee-icons/roundLink.svg';
import { ReactComponent as PersonSearch } from '../../../icons/navee-icons/personSearch.svg';
import { ReactComponent as PersonFilled } from '../../../icons/navee-icons/personFilled.svg';
import { ReactComponent as FontSize } from '../../../icons/navee-icons/fontSize.svg';
import { ReactComponent as ChatBubble } from '../../../icons/navee-icons/chatBubble.svg';
import { ReactComponent as PlusOutlined } from '../../../icons/navee-icons/plus-outlined.svg';
import { ReactComponent as PlusOutline } from '../../../icons/navee-icons/plus-outline.svg';
import { ReactComponent as MinusOutlined } from '../../../icons/navee-icons/minus-outlined.svg';
import { ReactComponent as ReCrawl } from '../../../icons/navee-icons/ReCrawl.svg';
import { ReactComponent as ArrowDownWithBackground } from '../../../icons/navee-icons/ArrowDownWithBackground.svg';
import { ReactComponent as Headphone } from '../../../icons/navee-icons/headphone.svg';
import { ReactComponent as ErrorPage } from '../../../icons/navee-icons/errorPage.svg';
import { ReactComponent as ProductCategories } from '../../../icons/navee-icons/productCategories.svg';
import { ReactComponent as Labels } from '../../../icons/navee-icons/labels.svg';
import { ReactComponent as General } from '../../../icons/navee-icons/general.svg';
import { ReactComponent as UserSettings } from '../../../icons/navee-icons/userSettings.svg';
import { ReactComponent as UploadCloud } from '../../../icons/navee-icons/uploadCloud.svg';
import { ReactComponent as CrawlingConfigSettings } from '../../../icons/navee-icons/crawlingConfigSettings.svg';
import { ReactComponent as Filters } from '../../../icons/navee-icons/filters.svg';
import { ReactComponent as TagAccount } from '../../../icons/navee-icons/tagAccount.svg';
import { ReactComponent as TagPost } from '../../../icons/navee-icons/tagPost.svg';
import { ReactComponent as TagImage } from '../../../icons/navee-icons/tagImage.svg';
import { ReactComponent as Cursor } from '../../../icons/navee-icons/cursor.svg';
import { ReactComponent as BoundingBox } from '../../../icons/navee-icons/boundingBox.svg';
import { ReactComponent as ShieldLock } from '../../../icons/navee-icons/shieldLock.svg';
import { ReactComponent as TableIcon } from '../../../icons/navee-icons/TableIcon.svg';
import { ReactComponent as GridIcon } from '../../../icons/navee-icons/GridIcon.svg';
import { ReactComponent as SmallGrid } from '../../../icons/navee-icons/SmallGrid.svg';
import { ReactComponent as Enforcable } from '../../../icons/navee-icons/Enforcable.svg';
import { ReactComponent as TasksView } from '../../../icons/navee-icons/tasks.svg';

const NaveeIcon = () => <></>;

interface IconProps {
  dataTestId?: string;
  height?: number | string;
  width?: number | string;
  fill?: string;
  size?: number;
  stroke?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onPointerEnter?: () => void;
  onPointerLeave?: () => void;
  href?: string;
  style?: React.CSSProperties;
}

NaveeIcon.Share = ({
  fill = 'var(--neutral-grey-500)',
  ...rest
}: IconProps) => <Share fill={fill} {...rest} />;
NaveeIcon.TrendUp = ({ size = 24, fill = '#36805D' }: IconProps) => (
  <TrendUp width={size + 2} height={size} fill={fill} />
);
NaveeIcon.ArrowDownTwo = ({
  fill = 'var(--neutral-grey-500)',
  ...rest
}: IconProps) => <ArrowDownTwo fill={fill} {...rest} />;
NaveeIcon.TrendDown = ({ size = 24, fill = '#ff6565' }: IconProps) => (
  <TrendDown width={size + 2} height={size} fill={fill} />
);
NaveeIcon.Account = ({ fill = '#8C8C8C', ...rest }: IconProps) => (
  <Account fill={fill} {...rest} />
);
NaveeIcon.StartValueFalse = ({ ...rest }: IconProps) => (
  <StartValueFalse {...rest} />
);
NaveeIcon.StartValueTrue = ({ ...rest }: IconProps) => (
  <StartValueTrue {...rest} />
);
NaveeIcon.TableIcon = ({ ...rest }: IconProps) => <TableIcon {...rest} />;
NaveeIcon.GridIcon = ({ ...rest }: IconProps) => <GridIcon {...rest} />;
NaveeIcon.SmallGrid = ({ ...rest }: IconProps) => <SmallGrid {...rest} />;
NaveeIcon.Enforcable = ({ ...rest }: IconProps) => <Enforcable {...rest} />;
NaveeIcon.SortDecending = ({ ...rest }: IconProps) => (
  <SortDecending {...rest} />
);
NaveeIcon.SortAscending = ({ ...rest }: IconProps) => (
  <SortAscending {...rest} />
);
NaveeIcon.SortNoSort = ({ ...rest }: IconProps) => <SortNoSort {...rest} />;
NaveeIcon.StartOnHover = ({ ...rest }: IconProps) => <StartOnHover {...rest} />;
NaveeIcon.StarCrossedOut = ({ ...rest }: IconProps) => (
  <StarCrossedOut {...rest} />
);
NaveeIcon.AccountSmallIcon = ({ fill = '#FF6565', ...rest }: IconProps) => (
  <AccountSmallIcon fill={fill} {...rest} />
);
NaveeIcon.AccountFirstVariant = ({
  height = 12,
  width = 11,
  disabled = false,
  fill = '#171717',
  stroke,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  style,
}: IconProps) =>
  React.createElement(AccountFirstVariant, {
    onMouseEnter,
    onMouseLeave,
    onPointerLeave,
    onPointerEnter,
    fill,
    stroke,
    height,
    width,
    disabled,
    style,
  } as any);
NaveeIcon.DocTwo = ({
  height = 16,
  width = 16,
  disabled = false,
  fill = 'none',
  stroke = 'var(--neutral-grey-600)',
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  style,
}: IconProps) =>
  React.createElement(DocTwo, {
    onMouseEnter,
    onMouseLeave,
    onPointerLeave,
    onPointerEnter,
    fill,
    stroke,
    height,
    width,
    disabled,
    style,
  } as any);
NaveeIcon.ImageFeatures = ({
  height = 16,
  width = 16,
  disabled = false,
  fill = 'none',
  stroke = 'var(--neutral-grey-600)',
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  style,
  className,
}: IconProps) =>
  React.createElement(ImageFeatures, {
    onMouseEnter,
    onMouseLeave,
    onPointerLeave,
    onPointerEnter,
    fill,
    stroke,
    height,
    width,
    disabled,
    style,
    className,
  } as any);
NaveeIcon.OpenInNewTabs = ({
  height = 24,
  width = 24,
  disabled = false,
  fill,
  stroke,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  style,
}: IconProps) =>
  React.createElement(OpenInNewTabs, {
    onMouseEnter,
    onMouseLeave,
    onPointerLeave,
    onPointerEnter,
    fill,
    stroke,
    height,
    width,
    disabled,
    style,
  } as any);

NaveeIcon.PriceIcon = PriceIcon;
NaveeIcon.HighlySuspiciousElements = HighlySuspiciousElements;
NaveeIcon.ObviousCounterfeitElements = ObviousCounterfeitElements;
NaveeIcon.SuspiciousReasons = SuspiciousReasons;
NaveeIcon.AccountSecondVariant = AccountSecondVariant;
NaveeIcon.AccountThirdVariant = AccountThirdVariant;
NaveeIcon.PriceBoxIcon = PriceBoxIcon;
NaveeIcon.VendorBoxIcon = VendorBoxIcon;
NaveeIcon.Phone = ({
  fill = '#171717',
  height = 24,
  width = 24,
  style,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
}: IconProps) => (
  <Phone
    fill={fill}
    height={height}
    width={width}
    style={style}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onPointerEnter={onPointerEnter}
    onPointerLeave={onPointerLeave}
  />
);
NaveeIcon.Mail = ({
  fill = '#171717',
  height = 24,
  width = 24,
  style,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
}: IconProps) => (
  <Mail
    fill={fill}
    height={height}
    width={width}
    style={style}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onPointerEnter={onPointerEnter}
    onPointerLeave={onPointerLeave}
  />
);
NaveeIcon.RoundArticle = RoundArticle;
NaveeIcon.LinkIcon = ({ href, fill = '#0C8CE9', ...rest }: IconProps) => (
  <LinkIcon href={href} fill={fill} {...rest} />
);
NaveeIcon.StockIcon = StockIcon;
NaveeIcon.StockFilledIcon = StockFilledIcon;
NaveeIcon.CheckCircleOutline = CheckCircleOutline;
NaveeIcon.Check = ({
  height = 16,
  width = 16,
  fill = '#0C8CE9',
  ...rest
}: IconProps) => <Check height={height} width={width} fill={fill} {...rest} />;
NaveeIcon.PencilIcon = PencilIcon;
NaveeIcon.TrashIcon = ({
  fill = '#FF7285',
  style = {},
  className,
  onClick,
  ...props
}: IconProps) => (
  <TrashIcon
    fill={fill}
    className={className}
    onClick={onClick}
    style={style}
    {...props}
  />
);
NaveeIcon.DollarBill = DollarBill;
NaveeIcon.TrashLightIcon = TrashLightIcon;
NaveeIcon.CrossCircleOutline = CrossCircleOutline;
NaveeIcon.CheckStroked = CheckStroked;
NaveeIcon.Website = (props: IconProps) => <Website {...props} />;
NaveeIcon.ArrowDownWithBackground = (props: IconProps) => (
  <ArrowDownWithBackground {...props} />
);
NaveeIcon.MagicPill = MagicPill;
NaveeIcon.EmptyStatus = EmptyStatus;
NaveeIcon.ReasonChecked = reasonChecked;
NaveeIcon.ReasonNotChecked = ReasonNotChecked;
NaveeIcon.Validated = Validated;
NaveeIcon.Menu = Menu;
NaveeIcon.Checked = Checked;
NaveeIcon.Calendar = ({
  fill = 'var(--neutral-grey-500)',
  ...rest
}: IconProps) => <Calendar fill={fill} {...rest} />;
NaveeIcon.MinusCircleOutline = ({ onClick, ...rest }: IconProps) => (
  <MinusCircleOutline onClick={onClick} {...rest} />
);
NaveeIcon.PostFilter = PostFilter;
NaveeIcon.Group = Group;
NaveeIcon.ListedBrand = ({ fill = 'rgb(0,0,0)' }: IconProps) => (
  <ListedBrand fill={fill} />
);
NaveeIcon.ArrowDownSimple = ({ style, ...rest }: IconProps) => (
  <ArrowDownSimple
    style={{
      color: 'transparent',
      ...style,
    }}
    {...rest}
  />
);
NaveeIcon.TagPointIcon = TagPointIcon;
NaveeIcon.TagPointIconDisable = TagPointIconDisable;
NaveeIcon.SearchIcon = ({ stroke = '#C7C7C7', ...rest }: IconProps) => (
  <SearchIcon stroke={stroke} {...rest} />
);
NaveeIcon.BlockIcon = BlockIcon;
NaveeIcon.RefreshArrow = RefreshArrow;
NaveeIcon.DocIcon = DocIcon;
NaveeIcon.AccountModIcon = AccountModIcon;
NaveeIcon.DollarIcon = DollarIcon;
NaveeIcon.SearchInDocIcon = SearchInDocIcon;
NaveeIcon.EyeStroked = EyeStroked;
NaveeIcon.Eye = Eye;
NaveeIcon.RoundArrow = RoundArrow;
NaveeIcon.DotsIcon = DotsIcon;
NaveeIcon.DotsVertical = DotsVertical;
NaveeIcon.Image = ({ fill = '#A1A0AE', ...rest }: IconProps) => (
  <Image fill={fill} {...rest} />
);
NaveeIcon.NoVendor = NoVendor;
NaveeIcon.View = View;
NaveeIcon.Post = (props: IconProps) => <Post {...props} />;
NaveeIcon.Vendor = (props: IconProps) => <Vendor {...props} />;
NaveeIcon.Plus = ({
  stroke = '#171717',
  fill = '#171717',
  ...rest
}: IconProps) => <Plus stroke={stroke} fill={fill} {...rest} />;
NaveeIcon.PlusOutlined = PlusOutlined;
NaveeIcon.PlusOutline = PlusOutline;
NaveeIcon.MinusOutlined = MinusOutlined;
NaveeIcon.Cross = ({
  className = '',
  onClick = () => {},
  stroke = '#171717',
  style = {},
  fill = '#171717',
  width = 14,
  height = 15,
}: IconProps) => (
  <Cross
    className={className ?? ''}
    style={{ flexShrink: 0, ...style }}
    stroke={stroke}
    fill={fill}
    width={width}
    height={height}
    onClick={onClick}
  />
);
NaveeIcon.Logout = ({
  className = '',
  style = {},
  onClick = () => {},
  fill = '#171717',
}: IconProps) => (
  <Logout
    className={className ?? ''}
    style={{ flexShrink: 0, ...style }}
    fill={fill}
    onClick={onClick}
  />
);
NaveeIcon.Notification = Notification;
NaveeIcon.ArrowUp = ArrowUp;
NaveeIcon.Link = Link;
NaveeIcon.Tag = Tag;
NaveeIcon.Info = ({ fill = 'rgb(12, 140, 233)', ...rest }: IconProps) => (
  <Info style={{ flexShrink: 0 }} fill={fill} {...rest} />
);
NaveeIcon.InfoCircleOutlined = ({ fill = '#000', ...rest }: IconProps) => (
  <InfoCircleOutlined fill={fill} {...rest} />
);

NaveeIcon.LoadingOutlined = ({
  fill = 'var(--primary-black)',
  ...rest
}: IconProps) => <LoadingOutlined fill={fill} {...rest} />;
NaveeIcon.Remove = Remove;
NaveeIcon.ArrowRightOutlined = ({ onClick, className }: IconProps) => (
  <ArrowRightOutlined onClick={onClick} className={className} />
);
NaveeIcon.ArrowLeftOutlined = ({ onClick, className, ...rest }: IconProps) => (
  <ArrowLeftOutlined onClick={onClick} className={className} {...rest} />
);
NaveeIcon.Download = Download;
NaveeIcon.Statistics = Statistics;
NaveeIcon.ReCrawl = ({
  onClick,
  style,
  onMouseEnter,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  ...rest
}: IconProps) => (
  <ReCrawl
    onClick={onClick}
    style={style}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onPointerEnter={onPointerEnter}
    onPointerLeave={onPointerLeave}
    {...rest}
  />
);
NaveeIcon.TutorialMode = TutorialMode;
NaveeIcon.ImageSearch = ({
  stroke = 'var(--neutral-grey-400)',
  ...props
}: IconProps) => <ImageSearch stroke={stroke} {...props} />;
NaveeIcon.ImageFilter = ImageFilter;
NaveeIcon.ListView = ListView;
NaveeIcon.BlueLink = ({ fill = '#0C8CE9', onClick, style }: IconProps) => (
  <BlueLink fill={fill} onClick={onClick} style={style} />
);
NaveeIcon.UploadTag = UploadTag;
NaveeIcon.UploadArrow = UploadArrow;
NaveeIcon.Attach = Attach;
NaveeIcon.Dashboard = Dashboard;
NaveeIcon.ImageView = ImageView;
NaveeIcon.Gallery = ({ fill = '#8C8C8C', ...rest }: IconProps) => (
  <Gallery fill={fill} {...rest} />
);
NaveeIcon.InfringementPercentage = ({
  fill = '#464646',
  ...rest
}: IconProps) => <InfringementPercentage fill={fill} {...rest} />;
NaveeIcon.Setting = Setting;
NaveeIcon.FullScreen = FullScreen;
NaveeIcon.LeftArrow = ({ fill = '#171717', ...restProps }: IconProps) => (
  <LeftArrow fill={fill} {...restProps} />
);
NaveeIcon.RightArrow = ({
  fill = '#171717',
  style = {},
  ...props
}: IconProps) => <RightArrow style={style} fill={fill} {...props} />;
NaveeIcon.RightArrowAlt = ({ fill = '#171717', ...props }: IconProps) => (
  <RightArrowAlt fill={fill} {...props} />
);
NaveeIcon.MiniInfo = MiniInfo;
NaveeIcon.InfoIconWithQuestion = ({
  fill = '#464646',
  ...props
}: IconProps) => <InfoIconWithQuestion fill={fill} {...props} />;
NaveeIcon.ImageButton = ({ fill }: IconProps) => <ImageButton fill={fill} />;
NaveeIcon.ImageDownloadIcon = ({ onClick = () => {}, ...props }: IconProps) => (
  <ImageDownloadIcon onClick={onClick} {...props} />
);
NaveeIcon.ECommerce = ECommerce;
NaveeIcon.Lock = Lock;
NaveeIcon.Export = Export;
NaveeIcon.ExportArrowUp = ExportArrowUp;
NaveeIcon.Star = Star;
NaveeIcon.Pencil = ({
  onClick,
  fill = 'var(--primary-black)',
  ...rest
}: IconProps) => <Pencil onClick={onClick} fill={fill} {...rest} />;
NaveeIcon.CheckGreen = ({ fill, style, className, ...rest }: IconProps) => (
  <CheckGreen fill={fill} style={style} className={className} {...rest} />
);
NaveeIcon.Pdf = Pdf;
NaveeIcon.Audio = Audio;
NaveeIcon.Video = Video;
NaveeIcon.Text = Text;
NaveeIcon.Powerpoint = Powerpoint;
NaveeIcon.Word = Word;
NaveeIcon.Xls = Xls;
NaveeIcon.Zip = Zip;
NaveeIcon.File = File;
NaveeIcon.Instagram = ({ fill = '#464646', ...rest }: IconProps) => (
  <Instagram fill={fill} {...rest} />
);
NaveeIcon.Facebook = ({ fill = '#464646', ...rest }: IconProps) => (
  <Facebook fill={fill} {...rest} />
);
NaveeIcon.Envelop = ({ fill = '#464646', ...rest }: IconProps) => (
  <Envelop fill={fill} {...rest} />
);
NaveeIcon.Tasks = ({ fill = '#464646', ...rest }: IconProps) => (
  <TasksView fill={fill} {...rest} />
);
NaveeIcon.ModerationPercentage = ({ fill = '#464646', ...rest }: IconProps) => (
  <ModerationPercentage fill={fill} {...rest} />
);
NaveeIcon.WeChat = WeChat;
NaveeIcon.Whatsapp = Whatsapp;
NaveeIcon.WhatsappFilled = WhatsappFilled;
NaveeIcon.ChevronDown = ChevronDown;
NaveeIcon.ContactInfo = ContactInfo;
NaveeIcon.ContentCopy = ({
  height = 12,
  width = 12,
  disabled = false,
  onMouseEnter,
  onClick,
  onMouseLeave,
  onPointerEnter,
  onPointerLeave,
  style,
}: IconProps) =>
  React.createElement(ContentCopy, {
    onMouseEnter,
    onMouseLeave,
    onClick,
    onPointerLeave,
    onPointerEnter,
    height,
    width,
    disabled,
    style,
  } as any);
NaveeIcon.MagnifyingGlass = ({
  height = 12,
  width = 12,
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onClick,
  onPointerEnter,
  onPointerLeave,
  style,
}: IconProps) =>
  React.createElement(MagnifyingGlass, {
    onMouseEnter,
    onMouseLeave,
    onClick,
    onPointerLeave,
    onPointerEnter,
    height,
    width,
    disabled,
    style,
  } as any);
NaveeIcon.ManageHistory = ManageHistory;
NaveeIcon.Warning = Warning;
NaveeIcon.WorldWww = WorldWww;
NaveeIcon.CardImage = CardImage;
NaveeIcon.CalendarTwoTone = CalendarTwoTone;
NaveeIcon.ShadowFollower = ShadowFollower;
NaveeIcon.Box = Box;
NaveeIcon.Tags = Tags;
NaveeIcon.UploadTypeTag = UploadTypeTag;
NaveeIcon.StatusPal = StatusPal;
NaveeIcon.Web = Web;
NaveeIcon.RoundLink = RoundLink;
NaveeIcon.PersonSearch = PersonSearch;
NaveeIcon.PersonFilled = PersonFilled;
NaveeIcon.FontSize = FontSize;
NaveeIcon.ChatBubble = ChatBubble;
NaveeIcon.Headphone = Headphone;
NaveeIcon.ErrorPage = ErrorPage;
NaveeIcon.ProductCategories = ProductCategories;
NaveeIcon.Labels = Labels;
NaveeIcon.General = General;
NaveeIcon.UserSettings = UserSettings;
NaveeIcon.UploadCloud = UploadCloud;
NaveeIcon.CrawlingConfigSettings = CrawlingConfigSettings;
NaveeIcon.Filters = Filters;
NaveeIcon.TagAccount = TagAccount;
NaveeIcon.TagPost = TagPost;
NaveeIcon.TagImage = TagImage;
NaveeIcon.Cursor = Cursor;
NaveeIcon.BoundingBox = BoundingBox;
NaveeIcon.ShieldLock = ShieldLock;
NaveeIcon.WarningTriangle = WarningTriangle;

export default NaveeIcon;
