import { EndpointContainer } from '../../../endpoint';
import { HttpClient } from '../../../client/client';
import getIpDocuments from './ipDocuments';
import getMissingIpDocuments from './getMissingIpDocuments';
import getIpDocumentFormInfo from './ipDocumentFormInfo';
import createIpDocument from './createIpDocument';
import updateIpDocument from './updateIpDocument';
import deleteIpDocument from './deleteIpDocument';
import loadPlatformsByJurisdiction from './loadPlatformsByJurisdiction';
import uploadIpDocument from './uploadIpDocument';
import downloadIpDocumentExcelFile from './downloadIpDocumentExcelFile';
import importIpDocuments from './importIpDocuments';

export interface IpPortfolioEndpoints extends EndpointContainer {
  getIpDocuments: typeof getIpDocuments;
  getMissingIpDocuments: typeof getMissingIpDocuments;
  getIpDocumentFormInfo: typeof getIpDocumentFormInfo;
  createIpDocument: typeof createIpDocument;
  updateIpDocument: typeof updateIpDocument;
  deleteIpDocument: typeof deleteIpDocument;
  uploadIpDocument: typeof uploadIpDocument;
  loadPlatformsByJurisdiction: typeof loadPlatformsByJurisdiction;
  downloadIpDocumentExcelFile: typeof downloadIpDocumentExcelFile;
  importIpDocuments: typeof importIpDocuments;
}

const ipPortfolio: IpPortfolioEndpoints = {
  getIpDocuments,
  getMissingIpDocuments,
  getIpDocumentFormInfo,
  createIpDocument,
  updateIpDocument,
  deleteIpDocument,
  uploadIpDocument,
  loadPlatformsByJurisdiction,
  downloadIpDocumentExcelFile,
  importIpDocuments,
  injectHTTPClient(client: HttpClient) {
    getIpDocuments.injectHTTPClient(client);
    getMissingIpDocuments.injectHTTPClient(client);
    getIpDocumentFormInfo.injectHTTPClient(client);
    createIpDocument.injectHTTPClient(client);
    updateIpDocument.injectHTTPClient(client);
    deleteIpDocument.injectHTTPClient(client);
    uploadIpDocument.injectHTTPClient(client);
    loadPlatformsByJurisdiction.injectHTTPClient(client);
    loadPlatformsByJurisdiction.injectHTTPClient(client);
    downloadIpDocumentExcelFile.injectHTTPClient(client);
    importIpDocuments.injectHTTPClient(client);
  },
};

export default ipPortfolio;
