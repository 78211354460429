/* eslint-disable no-nested-ternary */
import React, {
  ButtonHTMLAttributes,
  useRef,
  MouseEventHandler,
  PointerEventHandler,
  FocusEventHandler,
} from 'react';
import { Spin } from 'antd';
import { useGetClassesFromObject } from '../Typography/hooks';
import { CanBeClickable } from '../Interfaces/CanBeClickable';
import { Labellable } from '../Interfaces/Labellable';
import { Testable } from '../Interfaces/Testable';
import { StyledButton } from './StyledButton';
import NaveeIcon from '../NaveeIcon/NaveeIcon';

export enum ButtonSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Outline = 'outline',
  Text = 'text',
  Greyed = 'greyed',
  Rounded = 'rounded',
  Blacked = 'blacked',
  White = 'white',
  WhiteSecondary = 'white-secondary',
  RoundedSelected = 'rounded-selected',
  Dashboard = 'dashboard',
  Red = 'red',
}

export enum ButtonType {
  IconOnly = 'icon-only',
  IconText = 'icon-text',
}

export interface ButtonProps extends CanBeClickable, Labellable, Testable {
  id?: string;
  type?: ButtonType | keyof typeof ButtonType;
  size?: ButtonSize | keyof typeof ButtonSize;
  icon?: React.ReactNode;
  variant?: (string & ButtonVariant) | keyof typeof ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  buttonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  iconAfter?: React.ReactNode;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
  onPointerEnter?: PointerEventHandler;
  onPointerLeave?: PointerEventHandler;
  onFocus?: FocusEventHandler<HTMLElement>;
}

interface RadioButtonProps extends Omit<ButtonProps, 'variant'> {
  variant: 'Radio';
  active: boolean;
}

const loadingIcon = (
  <Spin
    indicator={
      <NaveeIcon.LoadingOutlined
        className="spinning"
        style={{
          fontSize: 24,
          color: 'var(--primary-black)',
          marginRight: '0.5em',
        }}
      />
    }
  />
);

const Button = (props: ButtonProps | RadioButtonProps) => {
  const variant = props.variant ?? ButtonVariant.Primary;
  const size = props.size ?? ButtonSize.Medium;
  const type = props.type ?? ButtonType.IconText;
  const disabled = props.disabled ?? false;

  const btnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  const classes = useGetClassesFromObject({
    [variant]: true,
    [size]: true,
    [type]: true,
    disabled,
    [props.className ?? '']: props.className,
    [props.variant === 'Radio' ? 'active' : '']:
      props.variant === 'Radio' ? props.active : undefined,
  });

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (props.onClick) {
      props.onClick(e);
      btnRef.current?.blur();
    }
  };

  return (
    <StyledButton
      id={props.id}
      className={classes()}
      ref={btnRef}
      data-testid={props.dataTestId}
      style={props.style}
      onClick={handleClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onPointerEnter={props.onPointerEnter}
      onPointerLeave={props.onPointerLeave}
      type={props.buttonType ?? 'submit'}
    >
      {props.loading ? (
        loadingIcon
      ) : props.icon ? (
        <StyledButton.IconContainer
          $hasLabel={!!props.label}
          $hasIcon={!!props.icon}
        >
          {props.icon}
        </StyledButton.IconContainer>
      ) : null}
      {props.label}
      {props.iconAfter ? (
        <StyledButton.IconAfterContainer>
          {props.iconAfter}
        </StyledButton.IconAfterContainer>
      ) : null}
    </StyledButton>
  );
};

export default Button;
