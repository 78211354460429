import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface OrganisationAccountLabelsRequestModel extends EndpointRequests {}

export type OrganisationAccountLabelsResponse = Array<LabelRaw>;

const account = new Endpoint<
  OrganisationAccountLabelsRequestModel,
  OrganisationAccountLabelsResponse
>({
  url: '/api/me/organisation/labels/account',
  method: 'GET',
  headers: {},
});

export default account;
