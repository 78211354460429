import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AssignTagsRequestModel extends EndpointRequests {
  data: {
    organization_name: string;
    tag_name: string;
    post_ids: number[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface AssignTagsResponse {}

const assignTags = new Endpoint<AssignTagsRequestModel, AssignTagsResponse>({
  url: '/api/navee_admin/tags',
  method: 'POST',
  headers: {},
});

export default assignTags;
