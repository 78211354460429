import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import {
  SortingDirectionEnum,
  Sorting,
} from 'product-types/src/common/Sorting/Sorting';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import { FilterNamespace } from '..';
import {
  FiltersContainer,
  FiltersContainerState,
} from '../MoleculesFilter/MolecileFilter';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { TableParamsFilter } from '../AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { TakedownStatusFilter } from '../AtomicFiltersImplementation/TakedownStatus/TakedownStatusFilter';

export interface RelatedAccountsFiltersModelParams {
  currentFilters?: FilterNamespace.Filter[];
  uuid?: string;
}

export class RelatedAccountsFiltersModel implements FiltersContainer {
  uuid: string;

  currentFilters: FilterNamespace.Filter[];

  constructor(params?: RelatedAccountsFiltersModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
  }

  createEmptyFilters(): FilterNamespace.Filter[] {
    return [
      new TakedownStatusFilter(),
      RelatedAccountsFiltersModel.getDefaultTableParamsFilter(),
    ];
  }

  queryParamsExcept(filterToExclude: FilterNamespace.Filter): string {
    return JSON.stringify(
      this.currentFilters
        .filter((filter) => filter.uuid !== filterToExclude.uuid)
        .reduce(
          (acc, item: FilterNamespace.Filter) => ({
            ...acc,
            ...item.queryFilterValue,
          }),
          {} as Partial<QueryValue>,
        ),
    );
  }

  get displayingFilters(): DisplayingFilterValue[] {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new RelatedAccountsFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (
            filter.uuid === filterToDelete.uuid &&
            filter.removeFilterValue !== undefined
          ) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new RelatedAccountsFiltersModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  resetFilters() {
    this.currentFilters = this.createEmptyFilters();
    return this;
  }

  static getDefaultTableParamsFilter() {
    return new TableParamsFilter({
      value: new TableParams({
        pagination: new Pagination({
          offset: 0,
          page: 1,
          perpage: 3,
        }),
        sorting: new Sorting({
          key: 'id',
          order: SortingDirectionEnum.asc,
        }),
      }),
    });
  }

  get takedownStatusFilter() {
    return this.currentFilters.find(
      (f) => f instanceof TakedownStatusFilter,
    ) as TakedownStatusFilter;
  }

  get tableParamsFilter() {
    return this.currentFilters.find(
      (f) => f instanceof TableParamsFilter,
    ) as TableParamsFilter;
  }

  get queryParams() {
    return this.currentFilters.reduce(
      (acc, filter) => ({
        ...acc,
        ...filter.queryFilterValue,
      }),
      {} as Partial<QueryValue>,
    );
  }

  // TODO: implement/change interface
  get state() {
    return {} as FiltersContainerState;
  }

  get dateFilter() {
    return {} as FilterNamespace.Filter;
  }

  get searchFilter() {
    return {} as FilterNamespace.Filter;
  }

  get currentFilterIdApplied() {
    return 0;
  }
}
