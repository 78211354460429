import React, { useCallback, useContext } from 'react';
import {
  TakedownScope,
  TakedownScopeType,
  TakedownStatus,
} from 'product-types/src/domain/takedownStatus/takedownStatus';
import { Flex, Select } from 'antd';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from 'providers/NewFilterProvider/NewFilterProvider';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import Tag from 'product-ui/src/components/atoms/Tag';
import Typography from 'product-ui/src/components/atoms/Typography';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { TakedownStatusFilter } from '../../../types/filters/AtomicFiltersImplementation/TakedownStatus/TakedownStatusFilter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface TakedownStatusFilterProps extends FilterParams {
  value: TakedownStatusFilter;
  onChange: (v: Filter) => void;
}

export const NewTakedownStatusFilter = (props: TakedownStatusFilterProps) => {
  const { takedownStatus } = useContext<NewFilterProviderContext>(
    FilterProviderContext,
  );

  const onSelectFilter = useCallback(
    (scope: TakedownScopeType, checked: boolean, takedown: TakedownStatus) => {
      if (checked) {
        props.onChange(
          new TakedownStatusFilter({
            ...props.value,
            label: scope,
            value: props.value.value.addTakeDown(scope, takedown),
          }),
        );
      } else {
        props.onChange(
          new TakedownStatusFilter({
            ...props.value,
            label: scope,
            value: props.value.value.removeTakedown(scope, takedown),
          }),
        );
      }
    },
    [props.value.value],
  );

  const tagRender = useCallback(
    (scope: TakedownScopeType) => (status: TakedownStatus) => (
      <Tag
        tag={
          new TagModel({
            id: status.value,
            name: status.label,
          })
        }
        style={{
          marginRight: '0.25rem',
          marginBottom: '0.25rem',
        }}
        disableLoading
        showRemoveIcon
        onClickRemove={() => {
          props.onChange(
            new TakedownStatusFilter({
              ...props.value,
              label: scope,
              value: props.value.value.removeTakedown(scope, status),
            }),
          );
        }}
      />
    ),
    [takedownStatus, props.value.value.takenDown],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 200,
          minHeight: 60,
          gap: '5px',
        }}
      >
        {takedownStatus?.posts && (
          <>
            <Typography
              variant="xsmall"
              color="var(--neutral-grey-70)"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="0.24px"
            >
              POST
            </Typography>
            <Select
              size="middle"
              placeholder="Post"
              optionFilterProp="label"
              mode="multiple"
              tagRender={tagRender(TakedownScope.POSTS)}
              value={props.value.value.takenDown.posts}
              onDeselect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.POSTS, false, { value, label })
              }
              onSelect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.POSTS, true, { value, label })
              }
            >
              {takedownStatus.posts
                .filter(
                  (status) =>
                    !props.value.value.takenDown.posts.find(
                      (s) => s.value === status.value,
                    ),
                )
                .map((status: TakedownStatus) => (
                  <Select.Option
                    label={status.label}
                    value={status.value}
                    key={status.label}
                  >
                    {status.label}
                  </Select.Option>
                ))}
            </Select>
          </>
        )}
        {takedownStatus?.accounts && (
          <div style={{ marginTop: '10px', width: '100%' }}>
            <Typography
              variant="xsmall"
              color="var(--neutral-grey-70)"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="0.24px"
            >
              ACCOUNT
            </Typography>
            <Select
              size="middle"
              style={{ width: '100%' }}
              placeholder="Account"
              optionFilterProp="label"
              mode="multiple"
              tagRender={tagRender(TakedownScope.ACCOUNTS)}
              value={props.value.value.takenDown.accounts}
              onDeselect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.ACCOUNTS, false, { value, label })
              }
              onSelect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.ACCOUNTS, true, { value, label })
              }
            >
              {takedownStatus.accounts
                .filter(
                  (status) =>
                    !props.value.value.takenDown.accounts.find(
                      (s) => s.value === status.value,
                    ),
                )
                .map((status: TakedownStatus) => (
                  <Select.Option
                    label={status.label}
                    value={status.value}
                    key={status.label}
                  >
                    {status.label}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
        {takedownStatus?.websites && (
          <div style={{ marginTop: '10px', width: '100%' }}>
            <Typography
              variant="xsmall"
              color="var(--neutral-grey-70)"
              fontWeight="bold"
              textTransform="uppercase"
              letterSpacing="0.24px"
            >
              WEBSITE
            </Typography>
            <Select
              style={{ width: '100%' }}
              size="middle"
              placeholder="Website"
              optionFilterProp="label"
              mode="multiple"
              value={props.value.value.takenDown.websites}
              tagRender={tagRender(TakedownScope.WEBSITES)}
              onDeselect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.WEBSITES, false, { value, label })
              }
              onSelect={(_, { value, label }: any) =>
                onSelectFilter(TakedownScope.WEBSITES, true, { value, label })
              }
            >
              {takedownStatus.websites
                .filter(
                  (status) =>
                    !props.value.value.takenDown.websites.find(
                      (s) => s.value === status.value,
                    ),
                )
                .map((status: TakedownStatus) => (
                  <Select.Option
                    label={status.label}
                    value={status.value}
                    key={status.label}
                  >
                    {status.label}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
      </Flex>
    ),
    [props.value.value.takenDown, onSelectFilter, tagRender],
  );

  return (
    <FilterWithMenuWrapper
      text="Takedown Status"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    />
  );
};
