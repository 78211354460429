import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdatePostLabelRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: LabelSaveModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UpdatePostLabelResponse {}

const updatePostLabel = new Endpoint<
  UpdatePostLabelRequestModel,
  UpdatePostLabelResponse
>({
  url: '/api/me/organisation/labels/post/:id',
  method: 'PUT',
  headers: {},
});

export default updatePostLabel;
