import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { DomainModel } from 'product-types/src/domain/domain/Domain';
import {
  Category,
  CategoryModel,
} from 'product-types/src/domain/category/Category';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { UserModel, UserRawModel } from 'product-types/src/domain/user/User';
import { GeoReasonModel } from 'product-types/src/domain/geo/Geo';
import { Contact, ContactRaw } from 'product-types/src/domain/contact/Contact';
import { Cluster } from 'product-types/src/domain/cluster/Cluster';
import {
  WebsiteDescriptionModel,
  WebsiteDescriptionRaw,
} from 'product-types/src/domain/website/WebsiteDescription';
import dayjs, { Dayjs } from 'dayjs';
import { WebsiteStatusModel } from 'product-types/src/domain/takedownStatus/WebsiteTakedownModel';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';

export interface WebsiteModerationRawModel {
  account_contacts: Array<ContactRaw>;
  account_description: Array<WebsiteDescriptionRaw>;
  account_external_link: string;
  account_image_link: string;
  account_infringing_percentage: number;
  archive_link: string | null;
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  checked_by: UserRawModel | null;
  cluster: null | any;
  crawling_date: string;
  display_color: null | string;
  domain_name: string;
  domain_url: string;
  followers_count: number | null;
  geo: string;
  geo_country: string | null;
  id: string;
  infringement_type: string;
  is_desc_counterfeit: null | boolean;
  is_for_sale: null | boolean;
  median_price: number;
  moderated_by: UserRawModel | null;
  moderation_date: string;
  moderation_method: string;
  post_moderation_percentage: number;
  poster_id: number;
  poster_name: string;
  qa_check_date: null | Date;
  qa_checked: boolean;
  rank: number;
  tags: Array<TagRaw>;
  taken_down: boolean;
  total_images_moderated: number;
  total_images_unmoderated: number;
  total_posts: number;
  total_posts_moderated: number;
  total_posts_unmoderated: number;
  validated: boolean;
  validated_by: UserRawModel | null;
  validation_date: string;
  website_category: Category;
  website_id: number;
  status_observations_consecutive_count: number;
  status_observed_last_at: string | null;
  status: 'UNKNOWN' | 'ONLINE' | 'OFFLINE' | null;
}

export class WebsiteModerationModel
  implements CanBeModeratable, HasModerationPage, Magnifiable
{
  moderation: Moderatable;

  _id: string;

  tags: Array<TagModel>;

  cluster: Cluster | null;

  description: Array<WebsiteDescriptionModel>;

  archiveLink: string | null;

  infringingPercentage: number;

  imageLink: string;

  externalLink: string;

  domain: DomainModel;

  contacts: Array<Contact>;

  crawlingDate?: dayjs.Dayjs;

  name: string;

  postModerationPercentage: number;

  followersCount: number | null;

  takenDown: boolean;

  geo: string;

  geoCountry: Array<GeoReasonModel>;

  totalPosts: number;

  totalPostsModerated: number;

  totalPostsUnmoderated: number;

  totalImagesModerated: number;

  isForSale: boolean | null;

  status: WebsiteStatusModel;

  isDescCounterfeit: boolean | null;

  websiteCategory: Category;

  totalImagesUnmoderated: number;

  constructor(
    prop: Pick<
      WebsiteModerationModel,
      | 'crawlingDate'
      | 'status'
      | 'followersCount'
      | 'geoCountry'
      | 'geo'
      | 'archiveLink'
      | 'contacts'
      | 'isForSale'
      | 'isDescCounterfeit'
      | 'domain'
      | 'takenDown'
      | 'websiteCategory'
      | 'name'
      | 'imageLink'
      | 'externalLink'
      | 'description'
      | 'postModerationPercentage'
      | 'totalPostsModerated'
      | 'totalPostsUnmoderated'
      | 'totalPosts'
      | 'totalImagesModerated'
      | 'totalImagesUnmoderated'
      | 'infringingPercentage'
      | 'tags'
      | 'cluster'
      | 'moderation'
      | '_id'
    >,
  ) {
    this.moderation = prop.moderation;
    this._id = prop._id;
    this.cluster = prop.cluster;
    this.tags = prop.tags;
    this.name = prop.name;
    this.status = prop.status;
    this.archiveLink = prop.archiveLink;
    this.takenDown = prop.takenDown;
    this.geo = prop.geo;
    this.geoCountry = prop.geoCountry;
    this.followersCount = prop.followersCount;
    this.description = prop.description;
    this.domain = prop.domain;
    this.contacts = prop.contacts;
    this.isForSale = prop.isForSale;
    this.isDescCounterfeit = prop.isDescCounterfeit;
    this.websiteCategory = prop.websiteCategory;
    this.imageLink = prop.imageLink;
    this.externalLink = prop.externalLink;
    this.infringingPercentage = prop.infringingPercentage;
    this.postModerationPercentage = prop.postModerationPercentage;
    this.totalPostsModerated = prop.totalPostsModerated;
    this.totalPostsUnmoderated = prop.totalPostsUnmoderated;
    this.totalImagesModerated = prop.totalImagesModerated;
    this.totalImagesUnmoderated = prop.totalImagesUnmoderated;
    this.totalPosts = prop.totalPosts;
    this.crawlingDate = prop.crawlingDate;
  }

  get midSizeImage() {
    return this.imageLink;
  }

  get id() {
    return (this._id || '').slice(3);
  }

  get isPoster() {
    return false;
  }

  get isWebsite() {
    return true;
  }

  get title() {
    return `Website #${this.id}`;
  }

  get formattedName() {
    return `WEB#${this.id}`;
  }

  get linkToModerationPage() {
    return `/website/${this.id}`;
  }

  get linkToAssociatedImagesFeedPage() {
    return `/image?date_filter_type=crawling_date&interval=until_today&account_website_id=${this.id}`;
  }

  get linkToRelatedPostsFeedPage() {
    return `/post?date_filter_type=crawling_date&interval=until_today&account_website_id=${this.id}`;
  }

  get linkToRelatedWebsitesFeedPage() {
    return `/post?date_filter_type=crawling_date&interval=until_today&related_to_website_id=${this.id}`;
  }

  private static getFollowerCount(
    prop: WebsiteModerationRawModel,
  ): number | null {
    let result = prop.followers_count;
    if (!result) {
      result =
        prop.account_description &&
        prop.account_description[0] &&
        prop.account_description[0].followers_count;
    }

    return result;
  }

  static createFromRawModel(prop: WebsiteModerationRawModel) {
    return new WebsiteModerationModel({
      name: prop.poster_name,
      geo: prop.geo,
      status: WebsiteStatusModel.createFromTakedownRawModel({
        status: prop.status,
        status_observed_last_at: prop.status_observed_last_at,
        status_observations_consecutive_count:
          prop.status_observations_consecutive_count,
      }),
      followersCount: WebsiteModerationModel.getFollowerCount(prop),
      geoCountry:
        (GeoReasonModel.checkIfJsonValid(prop.geo_country) &&
          GeoReasonModel.createFromRawString(prop.geo_country as string)) ||
        [],
      isForSale: prop.is_for_sale,
      archiveLink: prop.archive_link,
      contacts: (prop.account_contacts || []).map(Contact.createFromRawModel),
      isDescCounterfeit: prop.is_desc_counterfeit,
      takenDown: prop.taken_down,
      domain: new DomainModel({
        name: prop.domain_name,
        url: prop.domain_url,
      }),
      crawlingDate:
        (prop.crawling_date && dayjs(prop.crawling_date)) || undefined,
      websiteCategory: prop.website_category,
      imageLink: prop.account_image_link,
      externalLink: prop.account_external_link,
      description: prop.account_description.map(
        WebsiteDescriptionModel.createFromRawModel,
      ),
      infringingPercentage: prop.account_infringing_percentage,
      postModerationPercentage: prop.post_moderation_percentage,
      totalImagesModerated: prop.total_images_moderated,
      totalImagesUnmoderated: prop.total_images_unmoderated,
      totalPostsModerated: prop.total_posts_moderated,
      totalPostsUnmoderated: prop.total_posts_unmoderated,
      totalPosts: prop.total_posts,
      _id: prop.id,
      cluster: prop.cluster
        ? new Cluster({
            contactInfo: prop.cluster.contact_info,
            description: prop.cluster.description,
            estimatedGeo: prop.cluster.estimated_geo,
            id: prop.cluster.id,
            imageLink: prop.cluster.image_link,
            infringementPercentage: prop.cluster.infringement_percentage,
            moderationPercentage: prop.cluster.moderation_percentage,
            name: prop.cluster.name,
            rank: prop.cluster.rank,
            totalPosts: prop.cluster.total_posts,
          })
        : null,
      tags: (prop.tags || []).map(TagModel.createFromRawModel),
      moderation: new Moderatable({
        canEditModeration: prop.can_edit_moderation,
        canEditQaCheck: prop.can_edit_qa_check,
        canEditReasons: prop.can_edit_reasons,
        canEditValidation: prop.can_edit_validation,
        canRemoveValidation: prop.can_remove_validation,
        canViewQaCheck: prop.can_view_qa_check,
        canViewValidation: prop.can_view_validation,
        moderation: prop.infringement_type,
        moderationColor: 'transparent',
        moderationMethod: prop.moderation_method,
        qaChecked: prop.qa_checked,
        validated: prop.validated,
        qaCheckedDate:
          (prop.qa_check_date &&
            (
              (dayjs as any).utc(prop.qa_check_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedDate:
          (prop.moderation_date &&
            (
              (dayjs as any).utc(prop.moderation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        validatedDate:
          (prop.validation_date &&
            (
              (dayjs as any).utc(prop.validation_date).local() as Dayjs
            ).toDate()) ||
          undefined,
        moderatedBy:
          (prop.moderated_by && UserModel.createFromRaw(prop.moderated_by)) ||
          undefined,
        qaCheckedBy:
          (prop.checked_by && UserModel.createFromRaw(prop.checked_by)) ||
          undefined,
        validatedBy:
          (prop.validated_by && UserModel.createFromRaw(prop.validated_by)) ||
          undefined,
      }),
    });
  }

  static createEmpty() {
    return new WebsiteModerationModel({
      _id: '',
      archiveLink: null,
      tags: new Array<TagModel>(),
      cluster: null,
      description: new Array<WebsiteDescriptionModel>(),
      externalLink: '',
      followersCount: null,
      contacts: new Array<Contact>(),
      domain: DomainModel.createEmpty(),
      geo: '',
      imageLink: '',
      infringingPercentage: 0,
      isDescCounterfeit: null,
      isForSale: null,
      moderation: Moderatable.createEmpty(),
      name: '',
      status: WebsiteStatusModel.createEmpty(),
      postModerationPercentage: 0,
      takenDown: false,
      websiteCategory: CategoryModel.createEmpty().toRawModel(),
      totalImagesModerated: 0,
      totalImagesUnmoderated: 0,
      totalPosts: 0,
      totalPostsModerated: 0,
      totalPostsUnmoderated: 0,
      geoCountry: new Array<GeoReasonModel>(),
      crawlingDate: dayjs(),
    });
  }
}
