import { IpRemovalReasonCategoryModel } from 'product-types/src/domain/ipCertificates/IpRemovalReasonCategoryModel';

export interface LabelRaw {
  can_edit: boolean;
  color: string;
  id: number;
  is_deprecated: boolean;
  is_infringement: boolean;
  is_actionable_infringement: boolean;
  is_potential_infringement: boolean;
  keyboard_shortcut: string;
  name: string;
  organisation_id: number;
  removal_reason_category_id: number | null;
}

export interface LabelSaveModel {
  name: string;
  color: string;
  keyboard_shortcut: string;
  is_actionable_infringement: boolean;
  is_potential_infringement: boolean;
  is_infringement?: boolean;
  is_deprecated?: boolean;
  removal_reason_category_id?: number;
}

export interface Label {
  label: string;
  value: string;
  can_edit: boolean;
  color: string;
  id: number;
  is_infringement: boolean;
  is_deprecated: boolean;
  is_actionable_infringement: boolean;
  is_potential_infringement: boolean;
  keyboard_shortcut: string;
  name: string;
  organisation_id: number;
  removal_reason_category: IpRemovalReasonCategoryModel | null;
}

export const copyLabel = (label: Label): Label => ({
  label: label.label,
  value: label.value,
  can_edit: label.can_edit,
  color: label.color,
  id: label.id,
  is_infringement: label.is_infringement,
  is_deprecated: label.is_deprecated,
  is_actionable_infringement: label.is_actionable_infringement,
  is_potential_infringement: label.is_potential_infringement,
  keyboard_shortcut: label.keyboard_shortcut,
  name: label.name,
  organisation_id: label.organisation_id,
  removal_reason_category: label.removal_reason_category,
});

export interface EditableLabel {
  label: string;
  value: string;
  can_edit: boolean;
  color: string;
  id: number | string;
  is_infringement: boolean;
  is_actionable_infringement: boolean;
  is_potential_infringement: boolean;
  keyboard_shortcut_new: string;
  keyboard_shortcut: string;
  name: string;
  organisation_id: number;
  error: string;
  new_label: boolean;
  new_name: string;
  is_loading: boolean;
  is_deprecated: boolean;
  is_dirty: boolean;
  removal_reason_category: IpRemovalReasonCategoryModel | null;
}

export const createNewEditableLabel = (): EditableLabel => ({
  label: '',
  value: '',
  id: 0,
  is_infringement: false,
  is_actionable_infringement: false,
  is_potential_infringement: false,
  keyboard_shortcut: '',
  keyboard_shortcut_new: '',
  name: '',
  organisation_id: 0,
  error: '',
  new_label: true,
  color: '#fff',
  can_edit: true,
  new_name: '',
  is_loading: false,
  is_deprecated: false,
  is_dirty: false,
  removal_reason_category: null,
});

export const mapLabelToEditableLabel = (label: Label): EditableLabel => ({
  ...label,
  new_label: false,
  error: '',
  new_name: '',
  is_loading: false,
  is_dirty: false,
  keyboard_shortcut_new: label.keyboard_shortcut,
});

export const mapLabelRawToLabel = (
  labels: Array<LabelRaw>,
  ipRemovalReasons?: Array<IpRemovalReasonCategoryModel>,
): Array<Label> =>
  labels.map((label) => ({
    label: label.name,
    value: label.name,
    ...label,
    is_potential_infringement: label.is_potential_infringement ?? false,
    is_actionable_infringement: label.is_actionable_infringement ?? false,
    removal_reason_category:
      ipRemovalReasons?.find(
        (reason) => reason.id === label.removal_reason_category_id,
      ) ?? null,
  }));
