import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteUserRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DeleteUserResponse {}

const deleteUser = new Endpoint<DeleteUserRequestModel, DeleteUserResponse>({
  url: '/api/users/:id',
  method: 'DELETE',
  headers: {},
});

export default deleteUser;
