import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import {
  Sorting,
  SortingDirectionEnum,
} from 'product-types/src/common/Sorting/Sorting';
import { FilterNamespace } from '..';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { FiltersContainer } from '../MoleculesFilter/MolecileFilter';
import { SimpleSearchFilter } from '../AtomicFiltersImplementation/SimpleSearch';
import { TableParamsFilter } from '../AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { ImageFeatureStatusFilter } from '../AtomicFiltersImplementation/ImageFeatureStatus/ImageFeatureStatusFilter';

export interface ImageFeatureFilterModelParams {
  currentFilters?: FilterNamespace.Filter[];
  uuid?: string;
}
export class ImageFeatureFilterModel
  implements Omit<FiltersContainer, 'dateFilter'>
{
  uuid: string;

  currentFilters: FilterNamespace.Filter[];

  constructor(params?: ImageFeatureFilterModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
  }

  queryParamsExcept(filterToExclude: FilterNamespace.Filter): string {
    return JSON.stringify(
      this.currentFilters
        .filter((filter) => filter.uuid !== filterToExclude.uuid)
        .reduce(
          (acc, item: FilterNamespace.Filter) => ({
            ...acc,
            ...item.queryFilterValue,
          }),
          {} as Partial<QueryValue>,
        ),
    );
  }

  get displayingFilters(): DisplayingFilterValue[] {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new ImageFeatureFilterModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (
            filter.uuid === filterToDelete.uuid &&
            filter.removeFilterValue !== undefined
          ) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new ImageFeatureFilterModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  createEmptyFilters() {
    return [
      new SimpleSearchFilter(),
      new ImageFeatureStatusFilter(),
      new TableParamsFilter({
        value: new TableParams({
          pagination: new Pagination({
            offset: 0,
            page: 1,
            perpage: 10,
          }),
          sorting: new Sorting({
            key: 'name',
            order: SortingDirectionEnum.asc,
          }),
        }),
      }),
    ];
  }

  get searchFilter() {
    const filter = this.currentFilters.find(
      (f) => f instanceof SimpleSearchFilter,
    );
    if (!filter) {
      throw new Error('Search filter not found');
    }
    return filter as SimpleSearchFilter;
  }

  get tableParamsFilter() {
    const filter = this.currentFilters.find(
      (f) => f instanceof TableParamsFilter,
    );
    if (!filter) {
      throw new Error('Table params filter not found');
    }
    return filter as TableParamsFilter;
  }

  get imageFeatureFilter() {
    const filter = this.currentFilters.find(
      (f) => f instanceof ImageFeatureStatusFilter,
    );
    if (!filter) {
      throw new Error('ImageFeature filter not found');
    }
    return filter as ImageFeatureStatusFilter;
  }

  resetFilters() {
    this.currentFilters = this.createEmptyFilters();
    return this;
  }
}
