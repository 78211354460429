import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface RemoveImageLabelRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface RemoveImageLabelResponse {}

const removeImageLabel = new Endpoint<
  RemoveImageLabelRequestModel,
  RemoveImageLabelResponse
>({
  url: '/api/me/organisation/labels/image/:id',
  method: 'DELETE',
  headers: {},
});

export default removeImageLabel;
