import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { TaskFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/TasksFilterModel';
import { FeatureDetectionTaskListModel } from 'product-types/src/domain/task';
import { LOAD_TASKS } from './constants';

export const loadTasksAction = ({
  pageState,
  successAction,
  abortController,
}: {
  pageState: TaskFilterQueryValue;
  successAction: string;
  abortController?: AbortController;
}) => ({
  type: LOAD_TASKS,
  abortController,
  successAction,
  pageState,
});

export const loadTasksSuccessAction = (
  type: string,
  tasksData: FetchableData<FeatureDetectionTaskListModel>,
) => ({
  type,
  tasksData,
});
