export const LOAD_IMAGE_FEATURES =
  'app/SettingsPage/ImageFeatures/LOAD_IMAGE_FEATURES';
export const UPDATE_IMAGE_FEATURES =
  'app/SettingsPage/ImageFeatures/UPDATE_IMAGE_FEATURES';
export const UPDATE_IMAGE_FEATURE =
  'app/SettingsPage/ImageFeatures/UPDATE_IMAGE_FEATURE';
export const SET_IMAGE_FEATURE_FILTERS =
  'app/SettingsPage/ImageFeatures/SET_IMAGE_FEATURE_FILTERS';
export const UPDATE_IMAGE_FEATURE_FILTER =
  'app/SettingsPage/ImageFeatures/UPDATE_IMAGE_FEATURE_FILTER';
export const SET_IMAGE_FEATURE_TABLE_PARAMS =
  'app/SettingsPage/ImageFeatures/SET_IMAGE_FEATURE_TABLE_PARAMS';
export const DELETE_IMAGE_FEATURE =
  'app/SettingsPage/ImageFeatures/DELETE_IMAGE_FEATURE';
export const CREATE_IMAGE_FEATURE =
  'app/SettingsPage/ImageFeatures/CREATE_IMAGE_FEATURE';
