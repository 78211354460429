/* eslint-disable no-unused-vars, no-shadow */
import { v4 as uuidFunc } from 'uuid';

export enum ContactType {
  PhoneNumber = 'phone_number',
  Wechat = 'wechat',
  Whatsapp = 'whatsapp',
  EmailAddress = 'email_address',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linktree = 'linktree',
}

export interface ContactRaw {
  contact: string;
  id: number | string;
  contact_type_key: string;
  contact_type: string;
}

export interface ContactMainInfo {
  label: string;
  value: string;
  children: Array<ContactMainInfo>;
}

export type ContactForSaving = {
  contact: string;
  contact_type: string;
};

export class Contact {
  contact: string;

  type: ContactType;

  key: string;

  id: number | string;

  constructor(
    prop: Pick<Contact, 'contact' | 'key' | 'type'> & Partial<Contact>,
  ) {
    this.contact = prop.contact;
    this.id = prop.id || uuidFunc();
    this.key = prop.key;
    this.type = prop.type;
  }

  get uid() {
    return this.id;
  }

  static createFromRawModel(prop: ContactRaw) {
    return new Contact({
      contact: prop.contact,
      id: prop.id,
      key: prop.contact_type,
      type: prop.contact_type_key as ContactType,
    });
  }

  static createFromRawModelInverse(prop: ContactRaw) {
    return new Contact({
      contact: prop.contact,
      id: prop.id,
      key: prop.contact_type_key,
      type: prop.contact_type as ContactType,
    });
  }

  toSavingModel(): ContactForSaving {
    return {
      contact: this.contact,
      contact_type: this.type.toString(),
    };
  }

  isValid() {
    return (
      this.contact &&
      this.contact.length > 0 &&
      this.type &&
      this.type.length > 0
    );
  }
}
