import * as AdvancedStatistics from './AdvancedStatistics/AdvancedStatistics';
import * as Vendors from './Clusters/Clusters';
import * as Feed from './Feed/Feed';
import * as Post from './Post/Post';
import * as Account from './Account/Account';
import * as RelatedAccounts from './Account/RelatedAccounts';
import * as RelatedWebsites from './Website/RelatedWebsites';
import * as Website from './Website/Website';
import * as Dashboard from './Dashboard/index';
import * as UploadHistory from './UploadHistory/UploadHistory';
import * as Task from './Task/Task';
import * as IPAssets from './IPAssets/IPAssets';

const Network = {
  AdvancedStatistics,
  Vendors,
  Website,
  Feed,
  Post,
  Account,
  Dashboard,
  UploadHistory,
  RelatedAccounts,
  RelatedWebsites,
  Task,
  IPAssets,
};

export default Network;
