import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';

interface UpdateRequestModel extends EndpointRequests {
  urlParams: { id: string };
  data: ModerationReasonRaw;
}

const updateFeature = new Endpoint<UpdateRequestModel, object>({
  url: 'api/me/image_features/:id',
  method: 'PUT',
  headers: {},
});

export default updateFeature;
