import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getAll from './getAll/getAll';
import deleteFeature from './delete/delete';
import updateFeature from './update/update';
import createFeature from './create/create';

export interface ImageEndpoints extends EndpointContainer {
  getAll: typeof getAll;
  deleteFeature: typeof deleteFeature;
  updateFeature: typeof updateFeature;
  createFeature: typeof createFeature;
}

const imageFeatures: ImageEndpoints = {
  getAll,
  updateFeature,
  deleteFeature,
  createFeature,
  injectHTTPClient(client: HttpClient) {
    getAll.injectHTTPClient(client);
    updateFeature.injectHTTPClient(client);
    deleteFeature.injectHTTPClient(client);
    createFeature.injectHTTPClient(client);
  },
};

export default imageFeatures;
