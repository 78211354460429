import React from 'react';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import styled from 'styled-components';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import { SimpleSearchFilter } from '../../../types/filters/AtomicFiltersImplementation/SimpleSearch';

const StyledInput = styled(Input)`
  .ant-input-prefix {
    margin-inline-end: 8px;
  }
`;

export interface SimpleSearchProps extends FilterParams {
  placeholder?: string;
  disabled?: boolean;
  value: SimpleSearchFilter;
  onChange: (v: Filter) => void;
}
export const SimpleSearchFilterComponent = (props: SimpleSearchProps) => (
  <StyledInput
    disabled={props.disabled}
    style={{ flex: 1 }}
    value={props.value.value.search}
    onChange={(e) => {
      const newSimpleSearchFilter = new SimpleSearchFilter({
        ...props.value,
        value: props.value.value.setSearch(e.target.value),
      });
      props.onChange(newSimpleSearchFilter);
    }}
    prefix={<NaveeIcon.SearchIcon />}
    placeholder={props.placeholder || 'Search a domain name'}
  />
);
