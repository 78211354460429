import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import * as Domain from 'product-types/src/domain/Domain';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import {
  TakedownScopeType,
  TakedownStatus,
} from 'product-types/src/domain/takedownStatus/takedownStatus';
import { NewTakedownStatusFilter } from '../../../../components/Filters/NewTakedownStatusFilter';
import { NewTakedownStatusFilterValue } from '../../../../components/Filters/NewTakedownStatusFilter/NewTakedownStatusFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import {
  readFilterFromQueryProps,
  TakedownStatusValue,
} from './TakedownStatusValue';

export interface TakedownStatusFilterQueryValue extends QueryValue {
  taken_down_posts: Array<Domain.TakedownStatus.TakedownStatus>;
  taken_down_accounts: Array<Domain.TakedownStatus.TakedownStatus>;
  taken_down_websites: Array<Domain.TakedownStatus.TakedownStatus>;
}

export class TakedownStatusFilter implements Filter {
  uuid: string;

  label: string;

  value: TakedownStatusValue;

  constructor(
    params?: Partial<Pick<TakedownStatusFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TakedownStatusValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    const { posts, accounts, websites } = this.value.takenDown;

    const filterLabelWithScope = (
      scope: TakedownScopeType,
      label: string,
    ): string => {
      const capitalize = (value: string) =>
        value.charAt(0).toUpperCase() + String(value).slice(1);
      return `${capitalize(scope)}: ${label}`;
    };

    const toDisplayingFilterValue =
      (scope: TakedownScopeType) =>
      (takenDownType: Domain.TakedownStatus.TakedownStatus) => ({
        name: FilterTypeEnum.takenDown,
        value: {
          ...takenDownType,
          label: filterLabelWithScope(scope, takenDownType.label),
        },
        uuid: this.uuid,
        key: this.uuid + FilterTypeEnum.takenDown + takenDownType.value + scope,
        scope,
      });

    return [
      ...posts.map(toDisplayingFilterValue('posts')),
      ...accounts.map(toDisplayingFilterValue('accounts')),
      ...websites.map(toDisplayingFilterValue('websites')),
    ];
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (
      removingFilter.scope &&
      removingFilter.name === FilterTypeEnum.takenDown
    ) {
      this.value = this.value.removeTakedown(
        removingFilter.scope,
        removingFilter.value as Domain.TakedownStatus.TakedownStatus,
      );
    }
    return new TakedownStatusFilter(this);
  }

  get component() {
    return NewTakedownStatusFilter;
  }

  get displayFilterComponent() {
    return NewTakedownStatusFilterValue;
  }

  get queryFilterValue(): TakedownStatusFilterQueryValue {
    return {
      taken_down_posts: this.value.takenDown.posts,
      taken_down_accounts: this.value.takenDown.accounts,
      taken_down_websites: this.value.takenDown.websites,
    };
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): TakedownStatusFilter {
    return new TakedownStatusFilter({
      value: TakedownStatusValue.readFilterFromQuery(props),
    });
  }

  static readFilterFromSavedFitler(
    props: Omit<
      SavedFilterModel,
      'toRawModel' | 'prepareForCompareWithLocation'
    > & { takedownOptions: TakedownStatus[] },
  ): TakedownStatusFilter {
    return new TakedownStatusFilter({
      value: TakedownStatusValue.readFromSavedFilter(props),
    });
  }
}
