import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationTaskConfigModelRaw } from 'product-types/src/domain/task/ModerationTaskConfigModel';

interface ModerationTaskRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: {
    color: string;
    keybind: string;
    image_feature_id: number;
  };
}

const createModerationTaskConfig = new Endpoint<
  ModerationTaskRequestModel,
  ModerationTaskConfigModelRaw
>({
  url: 'api/me/feature_detection_tasks/:id/configs',
  method: 'POST',
  headers: {},
});

export default createModerationTaskConfig;
