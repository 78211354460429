import { RelatedAccountRaw } from 'product-types/src/domain/account/RelatedAccount';
import { RelatedAccountsRequest } from 'types/network/Account/RelatedAccounts';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface RelatedAccoutnsRequest extends EndpointRequests {
  urlParams: { id: string };
  data: RelatedAccountsRequest;
}

export interface RelatedAccountsResponse {
  posters?: Array<RelatedAccountRaw>;
  total?: number;
}

const relatedAccounts = new Endpoint<
  RelatedAccoutnsRequest,
  RelatedAccountsResponse
>({
  url: '/api/me/accounts/:id/related_posters',
  method: 'POST',
  headers: {},
});

export default relatedAccounts;
