import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationTaskConfigModelRaw } from 'product-types/src/domain/task/ModerationTaskConfigModel';

interface ModerationTaskRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

interface ModerationTaskConfigsResponseModel {
  feature_detection_task_configs: ModerationTaskConfigModelRaw[];
}

const moderationTaskConfigs = new Endpoint<
  ModerationTaskRequestModel,
  ModerationTaskConfigsResponseModel
>({
  url: 'api/me/feature_detection_tasks/:id/configs',
  method: 'GET',
  headers: {},
});

export default moderationTaskConfigs;
