import React, { useCallback } from 'react';
import styled from 'styled-components';
import { SelectProps, Tooltip } from 'antd';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { BrandModel } from 'product-types/src/domain/ipCertificates/BrandModel';
import { CreatableTagSelect } from 'product-ui/src/components/atoms/CreatableTagSelect/CreatableTagSelect';

const Wrapper = styled.div`
  .ant-select-selection-wrap {
    padding-right: 1.5rem;
  }
`;

interface Props {
  value?: Array<BrandModel>;
  onChange?: (tags: Array<BrandModel>) => void;
}

export const BrandTags = (props: Props) => {
  const brandToTag = (brand: BrandModel) =>
    new TagModel({
      id: brand.name,
      name: brand.name,
      tagType: 'brand',
    });

  const tagToBrand = useCallback(
    (tag: TagModel) => {
      const brand = props.value?.find((val) => val.name === tag.name);
      return new BrandModel({ ...brand, name: tag.name });
    },
    [props.value],
  );

  const renderMaxTagPlaceholder: SelectProps['maxTagPlaceholder'] = (
    omittedValues,
  ) => {
    const title = omittedValues.map((tag) => tag.label).join(', ');
    return <Tooltip title={title}>+{omittedValues.length}</Tooltip>;
  };

  return (
    <Wrapper>
      <CreatableTagSelect
        dataTestId="brand-names-select"
        value={(props.value ?? []).map(brandToTag)}
        tags={[]}
        disableIcon
        notFoundContent={null}
        maxTagPlaceholder={renderMaxTagPlaceholder}
        placeholder="Brand name"
        maxTagCount="responsive"
        onChange={(tags) => {
          if (props.onChange) {
            props.onChange(tags.map(tagToBrand));
          }
        }}
      />
    </Wrapper>
  );
};
