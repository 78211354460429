import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AddGeographyRequest extends EndpointRequests {
  urlParams: { id: string };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface AddGeographyResponse {}

const addGeography = new Endpoint<AddGeographyRequest, AddGeographyResponse>({
  url: '/api/me/geography/account/:id',
  method: 'POST',
  headers: {},
});

export default addGeography;
