import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UpdateIPAssetResponse {}
interface UpdateIPAssetRequest extends EndpointRequests {
  data: {
    name: string;
    image_link: string;
    brand_id: number | null;
    scraped_keyword_ids: Array<number>;
    moderation_reason_ids: Array<number>;
  };
  urlParams: {
    id: string;
  };
}

const updateIPAsset = new Endpoint<UpdateIPAssetRequest, UpdateIPAssetResponse>(
  {
    url: '/api/me/ip_assets/:id',
    method: 'PUT',
    headers: {},
  },
);

export default updateIPAsset;
