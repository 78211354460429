import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type PlatformsResponse = {
  trackers: Array<string>;
};

interface PlatformsRequest extends EndpointRequests {
  params: {
    jurisdiction_code: string;
  };
}

const loadPlatformsByJurisdiction = new Endpoint<
  PlatformsRequest,
  PlatformsResponse
>({
  url: '/api/me/enforcement/trackers/',
  method: 'GET',
  headers: {},
});

export default loadPlatformsByJurisdiction;
