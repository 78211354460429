import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SimilarImagesRequestModel extends EndpointRequests {
  data: {
    image_url: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SimilarImagesResponseModel {}

const getSimilarImages = new Endpoint<
  SimilarImagesRequestModel,
  SimilarImagesResponseModel
>({
  url: '/api/search/similar-images',
  method: 'POST',
  headers: {},
});

export default getSimilarImages;
