import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface RemovePostLabelRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface RemovePostLabelResponse {}

const removePostLabel = new Endpoint<
  RemovePostLabelRequestModel,
  RemovePostLabelResponse
>({
  url: '/api/me/organisation/labels/post/:id',
  method: 'DELETE',
  headers: {},
});

export default removePostLabel;
