import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteFileRequest extends EndpointRequests {
  params: {
    id: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface DeleteFileResponse {}

const deleteFile = new Endpoint<DeleteFileRequest, DeleteFileResponse>({
  url: '/api/me/moderation_comment/files',
  method: 'DELETE',
  headers: {},
});

export default deleteFile;
