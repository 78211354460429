import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { Filter, FilterNamespace } from '..';
import { DisplayingFilterValue } from '../AtomicFilters/DisplayingFilterValue';
import { FiltersContainer } from '../MoleculesFilter/MolecileFilter';
import { tagsFilterParams as TagsProps } from '../AtomicFiltersImplementation/Tags/TagsFilterValue';
import { CrawlingDateFilterValue } from '../AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilterValue';
import {
  UserFilterValue,
  readFilterFromQueryProps as UsersProps,
} from '../AtomicFiltersImplementation/UserFilter/UserFilterValue';
import { SearchQueryValue } from '../AtomicFiltersImplementation/Search/Search';
import { UserFilterQueryValue } from '../AtomicFiltersImplementation/UserFilter/UserFilter';
import { CrawlingDateFilterQueryValue } from '../AtomicFiltersImplementation/CrawlingDate/CrawlingDateFilter';
import { TableParamsQueryValue } from '../AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { TaskStatusFilterQueryValue } from '../AtomicFiltersImplementation/TaskStatus/TaskStatusFilter';

export interface TasksFilterModelParams {
  currentFilters?: FilterNamespace.Filter[];
  uuid?: string;
}

export interface TaskFilterQueryValue
  extends QueryValue,
    SearchQueryValue,
    UserFilterQueryValue,
    TableParamsQueryValue,
    TaskStatusFilterQueryValue,
    CrawlingDateFilterQueryValue {}

export interface readFromQueryParams extends UsersProps {
  tags: TagsProps;
}

export class TasksFilterModel implements FiltersContainer {
  uuid: string;

  currentFilters: FilterNamespace.Filter[];

  constructor(params?: TasksFilterModelParams) {
    this.uuid = params?.uuid ?? uuid();
    this.currentFilters = params?.currentFilters || this.createEmptyFilters();
  }

  get queryParams(): TaskFilterQueryValue {
    return {
      ...(this.currentFilters[0] as Filter.CrawlingDate).queryFilterValue,
      ...(this.currentFilters[1] as Filter.User).queryFilterValue,
      ...(this.currentFilters[2] as Filter.TaskStatus).queryFilterValue,
      ...(this.currentFilters[3] as Filter.Search).queryFilterValue,
      ...(this.currentFilters[4] as Filter.TableParams).queryFilterValue,
      ...(this.currentFilters[5] as Filter.TaskAssignee).queryFilterValue,
    };
  }

  get queryParamsAsString(): QueryValue {
    // todo: read actual values from filters and return type with exact keys
    return JSON.stringify(this.queryParams);
  }

  get displayingFilters(): DisplayingFilterValue[] {
    return this.currentFilters
      .map((filter) => filter.displayingFilterValue)
      .flat(1);
  }

  removeFilterValue(filterToDelete: DisplayingFilterValue) {
    return new TasksFilterModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (
            filter.uuid === filterToDelete.uuid &&
            filter.removeFilterValue !== undefined
          ) {
            filter.removeFilterValue(filterToDelete as DisplayingFilterValue);
          }
          return filter;
        },
      ),
    });
  }

  updateFilterValue(filterToUpdate: FilterNamespace.Filter) {
    return new TasksFilterModel({
      uuid: this.uuid,
      currentFilters: this.currentFilters.map(
        (filter: FilterNamespace.Filter) => {
          if (filter.uuid === filterToUpdate.uuid) {
            return filterToUpdate;
          }
          return filter;
        },
      ),
    });
  }

  createEmptyFilters() {
    return [
      new Filter.CrawlingDate({
        value: new CrawlingDateFilterValue({
          ...CrawlingDateFilterValue.defaultValue,
          crawlingDateOptions: CrawlingDateFilterValue.TaskOptions,
        }),
      }),
      new Filter.User({
        value: new UserFilterValue({
          users: [],
          options: UserFilterValue.VendorOptions,
        }),
      }),
      new Filter.TaskStatus(),
      new Filter.Search(),
      new Filter.TableParams(),
      new Filter.TaskAssignee(),
    ];
  }

  createFiltersFromQuery(props: readFromQueryParams) {
    return [
      Filter.CrawlingDate.readFilterFromQuery(
        new CrawlingDateFilterValue({
          ...CrawlingDateFilterValue.defaultValue,
          crawlingDateOptions: CrawlingDateFilterValue.VendorOptions,
        }),
      ),
      Filter.User.readFilterFromQuery(props, UserFilterValue.VendorOptions),
      // TODO: use status options from props
      Filter.TaskStatus.readFilterFromQuery(),
      Filter.Search.readFilterFromQuery(),
      Filter.TableParams.readFilterFromQuery(),
      Filter.TaskAssignee.readFilterFromQuery(),
    ];
  }

  get dateFilter() {
    return this.currentFilters[0];
  }

  get searchFilter(): Filter.Search {
    return this.currentFilters[3] as Filter.Search;
  }

  resetFilters() {
    this.currentFilters = this.createEmptyFilters();
    return this;
  }

  readFilterFromQuery(props: readFromQueryParams) {
    return new TasksFilterModel({
      uuid: this.uuid,
      currentFilters: this.createFiltersFromQuery(props),
    });
  }
}
