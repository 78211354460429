import { useWindowResize } from './useWindowResize';

export const usePrintPage = () => {
  const setPrintPageSize = () => {
    const pageWidth = 1440;
    const pageHeight = 1222;

    const styleSheet = document.styleSheets[0];

    const cssRule = `
      @media print {
        @page {
          size: ${pageWidth}px ${pageHeight}px;
          margin: 10mm;
        }

        body {
          height: auto !important;
          overflow: visible !important;
        }

        img {
          image-rendering: crisp-edges;
        }

        aside.ant-layout-sider {
          display: none;
        }
      }
    `;

    styleSheet.insertRule(cssRule, styleSheet.cssRules.length);
  };

  useWindowResize(setPrintPageSize);
};
