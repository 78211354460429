import { GoalsRaw } from 'product-types/src/domain/goals/goals';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GoalsRequest extends EndpointRequests {}

const goals = new Endpoint<GoalsRequest, GoalsRaw>({
  url: '/api/me/goal',
  method: 'GET',
  headers: {},
});

export default goals;
