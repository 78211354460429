import { InjectSagaParameter, useInjectSaga } from 'hooks/useInjectSaga';
import { useInjectReducer } from 'store/injectReducer';
import uploadsImageSaga from 'containers/Uploads/Image/saga';
import filtersBarSaga from 'layout/FiltersBar/saga';
import filtersBarReducer from 'layout/FiltersBar/reducer';
import feedFooterReducer from 'layout/FeedFooter/reducer';
import feedFooterSaga from 'layout/FeedFooter/saga';
import navbarReducer from 'layout/Navbar/reducer';
import uploadsPostSaga from 'containers/Uploads/Post/saga';
import uploadsAccountSaga from 'containers/Uploads/Account/saga';
import navbarSaga from 'layout/Navbar/saga';
import imageModerationSaga from 'containers/ImageModerationContainer/saga';
import websiteViewContainerReducer from 'containers/WebsiteViewContainer/reducer';
import websiteViewContainerSaga from 'containers/WebsiteViewContainer/saga';
import moderationReducer from 'containers/ModerationPage/reducer';
import moderationSaga from 'containers/ModerationPage/saga';
import notificationsPageReducer from 'containers/NotificationsPage/reducer';
import notificationsPageSaga from 'containers/NotificationsPage/saga';
import imageViewPageSaga from 'containers/ImageView/saga';
import imageViewPageReducer from 'containers/ImageView/reducer';
import LoginPageSaga from 'containers/LoginPage/saga';
import LoginPageReducer from 'containers/LoginPage/reducer';
import uploadHistoryReducer from 'containers/UploadHistory/reducer';
import uploadHistorySaga from 'containers/UploadHistory/saga';
import accountViewContainerReducer from 'containers/AccountViewContainer/reducer';
import accountViewContainerSaga from 'containers/AccountViewContainer/saga';
import postViewReducer from 'containers/PostViewContainer/reducer';
import postViewSaga from 'containers/PostViewContainer/saga';
import imageFeaturesSaga from 'containers/SettingsPage/imageFeatures/saga';
import taskViewReducer from 'containers/TaskViewContainer/reducer';
import { useSelector } from 'react-redux';
import { AppState } from 'store/storeAccess';
import { imageFeatureReducer } from 'containers/SettingsPage/imageFeatures/reducer';
import taskViewSaga from 'containers/TaskViewContainer/saga';

export const useInjectRedux = () => {
  useInjectReducer({ key: 'filters_bar', reducer: filtersBarReducer });
  useInjectSaga({
    key: 'filters_bar',
    saga: filtersBarSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'uploadsImage',
    saga: uploadsImageSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'uploadAccount',
    saga: uploadsAccountSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'uploadPost',
    saga: uploadsPostSaga,
  } as InjectSagaParameter);
  useInjectReducer({ key: 'navbar', reducer: navbarReducer });
  useInjectReducer({
    key: 'account_view_state',
    reducer: accountViewContainerReducer,
  });
  useInjectReducer({ key: 'image_view_page', reducer: imageViewPageReducer });
  useInjectReducer({ key: 'upload_history', reducer: uploadHistoryReducer });
  useInjectReducer({ key: 'moderationPage', reducer: moderationReducer });
  useInjectSaga({
    key: 'moderationPage',
    saga: moderationSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'imageModerationSaga',
    saga: imageModerationSaga,
  } as InjectSagaParameter);
  useInjectSaga({ key: 'navbar', saga: navbarSaga } as InjectSagaParameter);
  useInjectReducer({
    key: 'notificationsState',
    reducer: notificationsPageReducer,
  });
  useInjectReducer({ key: 'loginPage', reducer: LoginPageReducer });
  useInjectSaga({
    key: 'loginPage',
    saga: LoginPageSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'notificationsPage',
    saga: notificationsPageSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'imageFeatures',
    saga: imageFeaturesSaga,
  } as InjectSagaParameter);
  useInjectReducer({ key: 'imageFeatures', reducer: imageFeatureReducer });
  useInjectSaga({
    key: 'footer_state',
    saga: feedFooterSaga,
  } as InjectSagaParameter);
  useInjectReducer({ key: 'footer_state', reducer: feedFooterReducer });
  useInjectSaga({
    key: 'uploadHistory',
    saga: uploadHistorySaga,
  } as InjectSagaParameter);
  useInjectReducer({
    key: 'account_view_state',
    reducer: accountViewContainerReducer,
  });
  useInjectSaga({
    key: 'account_view_state',
    saga: accountViewContainerSaga,
  } as InjectSagaParameter);
  useInjectReducer({
    key: 'websiteViewState',
    reducer: websiteViewContainerReducer,
  });
  useInjectSaga({
    key: 'websiteViewState',
    saga: websiteViewContainerSaga,
  } as InjectSagaParameter);
  useInjectSaga({
    key: 'image_view_page',
    saga: imageViewPageSaga,
  } as InjectSagaParameter);
  useInjectReducer({ key: 'post_view_state', reducer: postViewReducer });
  useInjectSaga({
    key: 'post_view_state',
    saga: postViewSaga,
  } as InjectSagaParameter);
  useInjectReducer({ key: 'task_view_state', reducer: taskViewReducer });
  useInjectSaga({
    key: 'task_view_state',
    saga: taskViewSaga,
  } as InjectSagaParameter);

  const filterBarMounted = useSelector(
    (state: AppState) => state.filters_bar?.reducerMounted,
  );
  const loginPage = useSelector((state: AppState) => state.loginPage?.mounted);

  return filterBarMounted && loginPage;
};
