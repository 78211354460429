import { removeEmptyProperties } from 'product-utils/src/object';
import { FeedFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { FeedRequestParameter } from '../Feed/Feed';

export function getRequestParameter(
  action: FeedFilterQueryValue,
): FeedRequestParameter {
  return removeEmptyProperties<FeedRequestParameter>({
    perpage: action.perpage ?? 10,
    offset: action.offset,
    start_date: action.start_date,
    sort_by: action.sort_by || 'id',
    reverse: action.reverse,
  });
}
