import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SaveImageLabelRequestModel extends EndpointRequests {
  data: LabelSaveModel & { is_infringement: boolean };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SaveImageLabelResponse {}

const saveImageLabel = new Endpoint<
  SaveImageLabelRequestModel,
  SaveImageLabelResponse
>({
  url: '/api/me/organisation/labels/image',
  method: 'POST',
  headers: {},
});

export default saveImageLabel;
