import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadPostFileManualRequestModel extends EndpointRequests {
  data: FormData;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UploadPostFileManualResponse {}

const uploadPostFileManual = new Endpoint<
  UploadPostFileManualRequestModel,
  UploadPostFileManualResponse
>({
  url: '/api/me/uploads/post/manual/file',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadPostFileManual;
