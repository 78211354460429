import React, { useCallback } from 'react';
import { ModerationStatusOptions } from 'product-types/src/domain/ModrationStatus/ModrationStatus';
import { Flex } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import { Select } from 'product-ui/src/components/atoms/Select';
import {
  GetModerationMethodStatusFilterOptionByValue,
  ModerationMethodEnum,
  ModerationMethodOptions,
} from 'product-types/src/domain/moderationMethod/ModerationMethod';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { RadioButton } from 'product-ui/src/components/atoms/RadioButton/RadioButton';
import { ModerationFilter } from '../../../types/filters/AtomicFiltersImplementation/Moderation/ModerationFilter';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper/index';

export interface ModerationMethodFilterProps extends FilterParams {
  value: ModerationFilter;
  onChange: (v: Filter) => void;
}

export const ModerationFilterComponent = (
  props: ModerationMethodFilterProps,
) => {
  const onSelectPost = useCallback(
    (values: Array<string>) => {
      const posts = ModerationStatusOptions.filter((option) =>
        window.Cypress
          ? values === option.value
          : values.includes(option.value),
      );
      const newModerationFilter = new ModerationFilter({
        ...props.value,
        value: props.value.value.addPost(posts),
      });
      props.onChange(newModerationFilter);
    },
    [props.value.value, props.onChange],
  );

  const onSelectImage = useCallback(
    (values: Array<string>) => {
      const images = ModerationStatusOptions.filter((option) =>
        window.Cypress
          ? values === option.value
          : values.includes(option.value),
      );
      props.onChange(
        new ModerationFilter({
          ...props.value,
          value: props.value.value.addImage(images),
        }),
      );
    },
    [props.value.value, props.onChange],
  );

  const onSelectAccount = useCallback(
    (values: Array<string>) => {
      const accounts = ModerationStatusOptions.filter((option) =>
        window.Cypress
          ? values === option.value
          : values.includes(option.value),
      );
      props.onChange(
        new ModerationFilter({
          ...props.value,
          value: props.value.value.addAccount(accounts),
        }),
      );
    },
    [props.value.value, props.onChange],
  );

  const onModerationMethodChange = useCallback(
    (event: ModerationMethodEnum) => {
      props.onChange(
        new ModerationFilter({
          ...props.value,
          value: props.value.value.changeMethod(event),
        }),
      );
    },
    [props.value, props.onChange],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        align="stretch"
        gap="1rem"
        style={{
          width: 244,
          padding: '1.5rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
        }}
      >
        <Flex align="stretch" vertical gap="0.25rem">
          <Typography
            variant="xsmall"
            color="var(--neutral-grey-70)"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="0.24px"
          >
            Moderation Method
          </Typography>
          <Select
            dataTestId="moderation-method"
            style={{ flexGrow: 1, width: '100%' }}
            value={undefined}
            placeholder={
              GetModerationMethodStatusFilterOptionByValue(
                props.value.value.method.value,
              )?.label
            }
            onChange={onModerationMethodChange}
            dropdownStyle={{ padding: '1rem 0.25rem' }}
            options={ModerationMethodOptions.map((option) => ({
              value: option.value,
              label: (
                <StyledCheckbox $isRadio>
                  <RadioButton
                    checked={option.value === props.value.value.method.value}
                    key={option.value}
                    style={{ padding: '0.25rem 0.75rem' }}
                  >
                    {option.label}
                  </RadioButton>
                </StyledCheckbox>
              ),
            }))}
            labelRender={(labeledValue) =>
              GetModerationMethodStatusFilterOptionByValue(labeledValue.value)
                ?.label
            }
          />
        </Flex>
        <Flex align="stretch" vertical gap="0.25rem">
          <Typography
            variant="xsmall"
            color="var(--neutral-grey-70)"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="0.24px"
          >
            Post
          </Typography>
          <Select
            dataTestId="moderation-filter-post"
            style={{
              flexGrow: 1,
              width: '100%',
            }}
            allowClear
            mode={window.Cypress ? undefined : 'multiple'}
            placeholder="Status"
            value={props.value.value.post}
            onChange={onSelectPost}
            options={ModerationStatusOptions.filter(
              (option) =>
                !props.value.value.post
                  .map((p) => p.value)
                  .includes(option.value),
            )}
          />
        </Flex>
        <Flex align="stretch" vertical gap="0.25rem">
          <Typography
            variant="xsmall"
            color="var(--neutral-grey-70)"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="0.24px"
          >
            Image
          </Typography>
          <Select
            dataTestId="moderation-filter-image"
            style={{
              flexGrow: 1,
              width: '100%',
            }}
            allowClear
            mode={window.Cypress ? undefined : 'multiple'}
            placeholder="Status"
            value={props.value.value.image}
            onChange={onSelectImage}
            options={ModerationStatusOptions.filter(
              (option) =>
                !props.value.value.image
                  .map((p) => p.value)
                  .includes(option.value),
            )}
          />
        </Flex>
        <Flex align="stretch" vertical gap="0.25rem">
          <Typography
            variant="xsmall"
            color="var(--neutral-grey-70)"
            fontWeight="bold"
            textTransform="uppercase"
            letterSpacing="0.24px"
          >
            Account/Website
          </Typography>
          <Select
            dataTestId="moderation-filter-account"
            style={{
              flexGrow: 1,
              width: '100%',
            }}
            allowClear
            mode={window.Cypress ? undefined : 'multiple'}
            placeholder="Status"
            value={props.value.value.account}
            onChange={onSelectAccount}
            options={ModerationStatusOptions.filter(
              (option) =>
                !props.value.value.account
                  .map((p) => p.value)
                  .includes(option.value),
            )}
          />
        </Flex>
      </Flex>
    ),
    [props.value.value, onSelectPost, onSelectImage, onSelectAccount],
  );
  return (
    <FilterWithMenuWrapper
      text="Moderation"
      dataTestId="moderation-filter"
      renderer={renderer}
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
    ></FilterWithMenuWrapper>
  );
};
