import { TaskFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/TasksFilterModel';
import { removeEmptyProperties } from 'product-utils/src/object';
import { FeedRequestParameter } from '../Feed/Feed';

export function getRequestParameter(
  action: TaskFilterQueryValue,
): FeedRequestParameter {
  return removeEmptyProperties<FeedRequestParameter>({
    ...action,
    perpage: action.perpage ?? 10,
    timezone_offset: new Date().getTimezoneOffset(),
  });
}
