import { ScrapedKeywordRaw } from 'product-types/src/domain/ipAsset/ScrapedKeywords';
import { Endpoint } from 'types/network/Http/endpoint';
import { BrandRaw } from 'product-types/src/domain/ipCertificates/BrandModel';
import { ModerationReasonRaw } from 'product-types/src/domain/ipAsset/ModerationReason';
import { IpAssetRaw } from 'types/src/domain/ipCertificates/IpAssetModel';
import { IpJurisdictionRaw } from 'types/src/domain/ipCertificates/IpJurisdictionModel';
import { IpClassRaw } from 'types/src/domain/ipCertificates/IpClassModel';
import { IpRemovalReasonCategoryRaw } from 'types/src/domain/ipCertificates/IpRemovalReasonCategoryModel';

export interface IpDocumentFormInfoResponse {
  brands: Array<BrandRaw>;
  moderation_reasons: Array<ModerationReasonRaw>;
  scraped_keywords: Array<ScrapedKeywordRaw>;
  ip_jurisdictions: Array<IpJurisdictionRaw>;
  ip_removal_reason_categories: Array<IpRemovalReasonCategoryRaw>;
  ip_assets: Array<IpAssetRaw>;
  ip_classes: Array<IpClassRaw>;
  type_of_mark: { [key: string]: string };
}

const getIpDocumentFormInfo = new Endpoint<object, IpDocumentFormInfoResponse>({
  url: '/api/me/ip_portfolio_info',
  method: 'GET',
  headers: {},
});

export default getIpDocumentFormInfo;
