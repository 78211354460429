import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import moderationTask from './moderationTask/index';
import moderationTaskConfigs from './moderationTaskConfigs/get';
import createModerationTaskConfig from './moderationTaskConfigs/post';
import updateModerationTaskConfig from './moderationTaskConfigs/update';
import deleteModerationTaskConfig from './moderationTaskConfigs/delete';
export interface TasksEndpoints extends EndpointContainer {
  moderationTask: typeof moderationTask;
  moderationTaskConfigs: typeof moderationTaskConfigs;
  createModerationTaskConfig: typeof createModerationTaskConfig;
  updateModerationTaskConfig: typeof updateModerationTaskConfig;
  deleteModerationTaskConfig: typeof deleteModerationTaskConfig;
}

const vendors: TasksEndpoints = {
  moderationTask,
  moderationTaskConfigs,
  createModerationTaskConfig,
  updateModerationTaskConfig,
  deleteModerationTaskConfig,
  injectHTTPClient(client: HttpClient) {
    moderationTask.injectHTTPClient(client);
    moderationTaskConfigs.injectHTTPClient(client);
    createModerationTaskConfig.injectHTTPClient(client);
    updateModerationTaskConfig.injectHTTPClient(client);
    deleteModerationTaskConfig.injectHTTPClient(client);
  },
};

export default vendors;
