import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { TaskStatusOption } from 'product-types/src/domain/task';
import NewTaskStatusFilter from 'components/Filters/NewTaskStatusFilter/NewTaskStatusFilter';
import { NewTakedownStatusFilterValue } from '../../../../components/Filters/NewTakedownStatusFilter/NewTakedownStatusFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { TaskStatusFilterValue } from './TaskStatusFilterValue';

export interface TaskStatusFilterQueryValue extends QueryValue {
  task_status: string[];
}

export class TaskStatusFilter implements Filter {
  uuid: string;

  label: string;

  value: TaskStatusFilterValue;

  constructor(
    params?: Partial<Pick<TaskStatusFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TaskStatusFilterValue.defaultValue;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.status.map(
      (status) =>
        ({
          name: FilterTypeEnum.taskStatus,
          value: status,
          uuid: this.uuid,
          key: this.uuid + status.value,
        }) as DisplayingFilterValue,
    );
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.taskStatus) {
      this.value = this.value.removeTaskStatus(
        removingFilter.value as TaskStatusOption,
      );
    }
    return new TaskStatusFilter(this);
  }

  get component() {
    return NewTaskStatusFilter;
  }

  get displayFilterComponent() {
    return NewTakedownStatusFilterValue;
  }

  get queryFilterValue(): TaskStatusFilterQueryValue {
    return {
      task_status: this.value.status.map((status) => status.value),
    };
  }

  static readFilterFromQuery(): TaskStatusFilter {
    return new TaskStatusFilter({
      value: TaskStatusFilterValue.readFilterFromQuery(),
    });
  }
}
