import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import updateOrganisation from './updateOrganisation';
import createOrganisation from './createOrganisation';
import uploadNewLogo from './uploadNewLogo';
import poaTemplate from './poaTemplate';
import complianceDocuments from './complianceDocuments';
import uploadComplianceDocument from './uploadComplianceDocument';

export interface OrganisationEndpoints extends EndpointContainer {
  updateOrganisation: typeof updateOrganisation;
  createOrganisation: typeof createOrganisation;
  uploadNewLogo: typeof uploadNewLogo;
  poaTemplate: typeof poaTemplate;
  complianceDocuments: typeof complianceDocuments;
  uploadComplianceDocument: typeof uploadComplianceDocument;
}

const organisation: OrganisationEndpoints = {
  updateOrganisation,
  createOrganisation,
  uploadNewLogo,
  poaTemplate,
  complianceDocuments,
  uploadComplianceDocument,
  injectHTTPClient(client: HttpClient) {
    updateOrganisation.injectHTTPClient(client);
    createOrganisation.injectHTTPClient(client);
    uploadNewLogo.injectHTTPClient(client);
    poaTemplate.injectHTTPClient(client);
    complianceDocuments.injectHTTPClient(client);
    uploadComplianceDocument.injectHTTPClient(client);
  },
};

export default organisation;
