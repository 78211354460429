import * as Domain from 'product-types/src/domain/Domain';
import {
  GetTakeDownStatusFilterOptionByValue,
  TakedownScope,
  TakedownScopeType,
  TakedownStatus,
} from 'product-types/src/domain/takedownStatus/takedownStatus';
import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { TakedownOptions } from 'product-types/src/domain/takedownStatus/TakedownModel';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  takenDown: TakedownOptions;
}

export class TakedownStatusValue implements FilterValue {
  takenDown: TakedownOptions;

  constructor(params: Pick<TakedownStatusValue, 'takenDown'>) {
    this.takenDown = params.takenDown;
  }

  setTakedown(scope: string, value: Domain.TakedownStatus.TakedownStatus[]) {
    return new TakedownStatusValue({
      takenDown: {
        ...this.takenDown,
        [scope]: value,
      },
    });
  }

  addTakeDown(scope: string, value: Domain.TakedownStatus.TakedownStatus) {
    return new TakedownStatusValue({
      takenDown: {
        ...this.takenDown,
        [scope]: makeArrayUniqueByKey(
          this.takenDown[scope].concat([value]),
          'value',
        ),
      },
    });
  }

  removeTakedown(scope: string, value: Domain.TakedownStatus.TakedownStatus) {
    return new TakedownStatusValue({
      takenDown: {
        ...this.takenDown,
        [scope]: this.takenDown[scope].filter(
          (insight) =>
            insight.value !== value.value && insight.label !== value.label,
        ),
      },
    });
  }

  static get defaultValue(): TakedownStatusValue {
    return new TakedownStatusValue({
      takenDown: {
        posts: new Array<Domain.TakedownStatus.TakedownStatus>(),
        accounts: new Array<Domain.TakedownStatus.TakedownStatus>(),
        websites: new Array<Domain.TakedownStatus.TakedownStatus>(),
      },
    });
  }

  static readFilterFromQuery(
    props: readFilterFromQueryProps,
  ): TakedownStatusValue {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const takeDownPostsQuery = urlSearchParams.getAll('taken_down_posts');
    const takeDownAccountsQuery = urlSearchParams.getAll('taken_down_accounts');
    const takeDownWebsitesQuery = urlSearchParams.getAll('taken_down_websites');

    const getOptionsByValue = (
      query: string[],
      options: Domain.TakedownStatus.TakedownStatus[],
    ) =>
      (query
        ?.map((insight) =>
          GetTakeDownStatusFilterOptionByValue(options, insight),
        )
        .filter((v) => !!v) || []) as Domain.TakedownStatus.TakedownStatus[];

    const takeDownPosts = getOptionsByValue(
      takeDownPostsQuery,
      props.takenDown.posts,
    );
    const takeDownAccounts = getOptionsByValue(
      takeDownAccountsQuery,
      props.takenDown.accounts,
    );
    const takeDownWebsites = getOptionsByValue(
      takeDownWebsitesQuery,
      props.takenDown.websites,
    );

    if (
      takeDownPosts.length ||
      takeDownAccounts.length ||
      takeDownWebsites.length
    ) {
      return new TakedownStatusValue({
        takenDown: {
          posts: takeDownPosts,
          accounts: takeDownAccounts,
          websites: takeDownWebsites,
        },
      });
    }
    return TakedownStatusValue.defaultValue;
  }

  static readFromSavedFilter(
    props: Omit<
      SavedFilterModel,
      'toRawModel' | 'prepareForCompareWithLocation'
    > & { takedownOptions: TakedownStatus[] },
  ): TakedownStatusValue {
    const { takenDown: takenDownPreset, takedownOptions } = props;

    const takenDown = {
      posts: [],
      accounts: [],
      websites: [],
    };

    Object.values(TakedownScope).forEach((scope: TakedownScopeType) => {
      if (takenDown[scope]) {
        takenDown[scope] = (takenDownPreset[scope]
          ?.map((takedown: string) =>
            GetTakeDownStatusFilterOptionByValue(takedownOptions, takedown),
          )
          .filter((v) => !!v) || []) as Domain.TakedownStatus.TakedownStatus[];
      }
    });

    return new TakedownStatusValue({
      takenDown,
    });
  }
}
