import { v4 as uuid } from 'uuid';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { NewTakedownStatusFilterValue } from '../../../../components/Filters/NewTakedownStatusFilter/NewTakedownStatusFilterValue';
import { DisplayingFilterValue } from '../../AtomicFilters/DisplayingFilterValue';
import { Filter, FilterTypeEnum } from '../../AtomicFilters/Filter';
import { TaskAssigneeFilterValue } from './TaskAssigneeFilterValue';

export interface TaskStatusFilterQueryValue extends QueryValue {
  assignee_ids: string[];
}

export class TaskAssigneeFilter implements Filter {
  uuid: string;

  label: string;

  value: TaskAssigneeFilterValue;

  constructor(
    params?: Partial<Pick<TaskAssigneeFilter, 'uuid' | 'label' | 'value'>>,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || TaskAssigneeFilterValue.defaultValue;
  }

  component() {
    return null;
  }

  get displayingFilterValue(): DisplayingFilterValue[] {
    return this.value.assignee_ids.map(
      (id) =>
        ({
          name: FilterTypeEnum.taskStatus,
          value: id,
          uuid: this.uuid,
          key: this.uuid + id,
        }) as DisplayingFilterValue,
    );
  }

  removeFilterValue(removingFilter: DisplayingFilterValue) {
    if (removingFilter.name === FilterTypeEnum.taskStatus) {
      this.value = this.value.removeAssignee(removingFilter.value as string);
    }
    return new TaskAssigneeFilter(this);
  }

  get displayFilterComponent() {
    return NewTakedownStatusFilterValue;
  }

  get queryFilterValue(): TaskStatusFilterQueryValue {
    return {
      assignee_ids: this.value.assignee_ids,
    };
  }

  static readFilterFromQuery(): TaskAssigneeFilter {
    return new TaskAssigneeFilter({
      value: TaskAssigneeFilterValue.readFilterFromQuery(),
    });
  }
}
