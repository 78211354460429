import React, { useCallback } from 'react';
import { Flex } from 'antd';
import { ImageFeatureStatusFilter } from 'types/filters/AtomicFiltersImplementation/ImageFeatureStatus/ImageFeatureStatusFilter';
import {
  ImageFeatureStatus,
  ImageFeatureStatusOptions,
} from 'types/filters/AtomicFiltersImplementation/ImageFeatureStatus/ImageFeatureStatusFilterValue';
import {
  Filter,
  FilterParams,
} from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';
import { ImageFeatureStatusOption } from './ImageFeatureStatusOption';

export interface TakedownStatusFilterProps extends FilterParams {
  value: ImageFeatureStatusFilter;
  onChange: (v: Filter) => void;
}

export const ImageFeautureStatusFilter = (props: TakedownStatusFilterProps) => {
  const toggleChangeValue = (val: ImageFeatureStatus) => {
    if (props.value.value.hasStatus(val)) {
      props.onChange(
        new ImageFeatureStatusFilter({
          ...props.value,
          value: props.value.value.removeStatus(val),
        }),
      );
    } else {
      props.onChange(
        new ImageFeatureStatusFilter({
          ...props.value,
          value: props.value.value.addStatus(val),
        }),
      );
    }
  };

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem 0.25rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 244,
          minHeight: 60,
        }}
      >
        {ImageFeatureStatusOptions.map((option) => (
          <ImageFeatureStatusOption
            value={option}
            selected={props.value.value.hasStatus(option)}
            onClick={toggleChangeValue}
          />
        ))}
      </Flex>
    ),
    [props.value.value.statuses],
  );
  return (
    <FilterWithMenuWrapper
      text="Image Feature Status"
      renderer={renderer}
      badgeText={
        props.value.value.statuses.length
          ? props.value.value.statuses.length
          : undefined
      }
    />
  );
};
