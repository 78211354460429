import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import getIPAssets from './getIPAssets';
import createIPAsset from './createIPAsset';
import updateIPAsset from './updateIPAsset';
import uploadPicture from './uploadPicture';
import dropdowns from './dropdowns';
import deleteIPAsset from './deleteIPAsset';
import bulkDeleteIPAssets from './bulkDelete';

export interface IPAssetsEndpoints extends EndpointContainer {
  getIPAssets: typeof getIPAssets;
  createIPAsset: typeof createIPAsset;
  updateIPAsset: typeof updateIPAsset;
  deleteIPAsset: typeof deleteIPAsset;
  bulkDeleteIPAssets: typeof bulkDeleteIPAssets;
  uploadPicture: typeof uploadPicture;
  dropdowns: typeof dropdowns;
}

const ipAssets: IPAssetsEndpoints = {
  getIPAssets,
  createIPAsset,
  updateIPAsset,
  deleteIPAsset,
  bulkDeleteIPAssets,
  uploadPicture,
  dropdowns,
  injectHTTPClient(client: HttpClient) {
    getIPAssets.injectHTTPClient(client);
    createIPAsset.injectHTTPClient(client);
    updateIPAsset.injectHTTPClient(client);
    deleteIPAsset.injectHTTPClient(client);
    bulkDeleteIPAssets.injectHTTPClient(client);
    uploadPicture.injectHTTPClient(client);
    dropdowns.injectHTTPClient(client);
  },
};

export default ipAssets;
