import { IpDocumentRaw } from 'product-types/src/domain/ipCertificates/IpDocumentModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type IpDocumentsResponse = {
  offset: number;
  per_page: number;
  results: IpDocumentRaw[];
  total: number;
};

export interface IpDocumentsRequestParams {
  search: string;
  asset_ids: Array<number>;
  jurisdiction_ids: Array<number>;
  removal_category_reason_ids: Array<number>;
  offset: string;
  perpage: string;
  sort_by: string;
  reverse: boolean;
}

interface IpDocumentsRequest extends EndpointRequests {
  params?: IpDocumentsRequestParams;
}

const getIpDocuments = new Endpoint<IpDocumentsRequest, IpDocumentsResponse>({
  url: '/api/me/ip-certificates/',
  method: 'GET',
  headers: {},
});

export default getIpDocuments;
