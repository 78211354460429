import React, { useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, Flex } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Logo } from 'components/Logo/Logo';
import { ChipStatus } from 'product-ui/src/components/atoms/ChipStatus';
import { usePermissionManager } from 'providers/PermissionManager';
import {
  CAN_ACCESS_DASHBOARD,
  CAN_VIEW_FEATURE_DETECTION_TASKS,
} from 'providers/PermissionManager/knownPermissions';
import { ItemType, MenuDividerType } from 'antd/es/menu/interface';
import { updateSidebarNavOpen } from 'containers/SettingsPage/actions';
import useNavigation from '../../hooks/useNavigation';
import { AppState } from '../../store/storeAccess';
import { NaveeSidebar } from './styled';
import SettingsMenuItem from './Navigation/SettingsNavigation';
import NotificationMenuItem from './Navigation/NotificationNavigation';
import UserMenu from './Navigation/UserMenu/UserMenu';
import { UploadMenuItem } from './Navigation/UploadMenuItem';

export function Sidebar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { currentUser } = useSelector((state: AppState) => state.loginPage);
  const isSidebarNavOpen = useSelector((state: AppState) => {
    if (!state.loginPage.currentUser.data) return true;
    return state.settingsPage?.isSidebarNavOpen;
  });
  const { navigate, calcLink } = useNavigation();

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prevState) => !prevState);
  }, [collapsed]);

  const { hasPermission } = usePermissionManager();

  const handleChangeLocationWithKey = useCallback(
    ({ key }) => {
      navigate(calcLink(key));
    },
    [calcLink],
  );

  const handleSettingsChange = useCallback(() => {
    if (!pathname.includes('settings')) {
      dispatch(updateSidebarNavOpen(true));
      navigate(calcLink('/settings/personal/edit-info'));
    } else {
      dispatch(updateSidebarNavOpen(!isSidebarNavOpen));
    }
  }, [isSidebarNavOpen, pathname, calcLink]);

  const defaultSelectedKey = useMemo(
    () => [`/${pathname.split('/')[2] || ''}`],
    [pathname],
  );

  const demo = (style: React.CSSProperties) => (
    <div
      style={{
        display: 'inline-flex',
        ...style,
      }}
    >
      <ChipStatus
        label="DEMO"
        variant="active"
        style={{
          padding: '1px 8px',
        }}
      />
    </div>
  );

  const demoLabel = useMemo(() => {
    if (currentUser.data && !currentUser.data.organisation?.isProduction) {
      return collapsed
        ? demo({ marginLeft: '-9px' })
        : demo({ marginLeft: '2.4rem' });
    }
    return null;
  }, [currentUser, collapsed]);

  const menuItems: ItemType[] = [
    {
      key: '/',
      disabled: true,
      label: (
        <Flex vertical gap="4px">
          <Flex style={{ height: 40 }} align="center">
            <NaveeIcon.Menu
              className="navee_svg menu"
              onClick={toggleCollapsed}
            />
            {!collapsed && (
              <Logo
                style={{
                  backgroundPosition: 'left',
                  marginLeft: '1rem',
                }}
              />
            )}
          </Flex>
          {demoLabel}
        </Flex>
      ),
      className: 'logo-block',
    },
    {
      key: '/dashboard',
      onClick: handleChangeLocationWithKey,
      title: 'Dashboard',
      label: (
        <>
          <NaveeIcon.Dashboard className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('dashboard') ? 'bold' : 'medium'}
            color={
              pathname.includes('dashboard')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Dashboard
          </Typography>
        </>
      ),
    },
    {
      key: '/image',
      onClick: handleChangeLocationWithKey,
      title: 'Image View',
      label: (
        <>
          <NaveeIcon.ImageView className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('image') ? 'bold' : 'medium'}
            color={
              pathname.includes('image')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Image View
          </Typography>
        </>
      ),
    },
    {
      key: '/post',
      onClick: handleChangeLocationWithKey,
      title: 'Post View',
      label: (
        <>
          <NaveeIcon.Post className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('post') ? 'bold' : 'medium'}
            hidden={collapsed}
            color={
              pathname.includes('post')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Post View
          </Typography>
        </>
      ),
    },
    {
      key: '/account',
      onClick: handleChangeLocationWithKey,
      title: 'Account View',
      label: (
        <>
          <NaveeIcon.Account className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('account') ? 'bold' : 'medium'}
            color={
              pathname.includes('account')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Account View
          </Typography>
        </>
      ),
    },
    {
      key: '/website',
      onClick: handleChangeLocationWithKey,
      title: 'Website View',
      label: (
        <>
          <NaveeIcon.Website className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            hidden={collapsed}
            fontWeight={pathname.includes('website') ? 'bold' : 'medium'}
            color={
              pathname.includes('website')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Website View
          </Typography>
        </>
      ),
    },
    {
      key: '/cluster',
      onClick: handleChangeLocationWithKey,
      title: 'Cluster View',
      label: (
        <>
          <NaveeIcon.Vendor className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('cluster') ? 'bold' : 'medium'}
            hidden={collapsed}
            color={
              pathname.includes('cluster')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Cluster View
          </Typography>
        </>
      ),
    },
    {
      key: '/task',
      onClick: handleChangeLocationWithKey,
      title: 'Task View',
      label: (
        <>
          <NaveeIcon.Tasks className="navee_svg" />
          <Typography
            textTransform="uppercase"
            variant="xsmall"
            fontWeight={pathname.includes('task') ? 'bold' : 'medium'}
            hidden={collapsed}
            color={
              pathname.includes('task')
                ? 'var(--primary-black)'
                : 'var(--neutral-grey-500)'
            }
          >
            Task View
          </Typography>
        </>
      ),
    },
    {
      key: '/upload',
      title: 'Upload',
      disabled: true,
      style: { marginTop: 'auto' },
      label: (
        <UploadMenuItem
          collapsed={collapsed}
          updateLocation={handleChangeLocationWithKey}
        />
      ),
    },
    {
      type: 'divider',
      style: { margin: '12px 0' },
    } as MenuDividerType,
    {
      key: '/notifications',
      onClick: handleChangeLocationWithKey,
      label: <NotificationMenuItem collapsed={collapsed} />,
    },
    {
      key: '/settings',
      onClick: handleSettingsChange,
      className: 'settings-menu-item',
      label: <SettingsMenuItem collapsed={collapsed} />,
    },
    {
      key: 'user-menu',
      disabled: true,
      className: 'user-menu-block',
      label: <UserMenu collapsed={collapsed} />,
    },
  ].filter((i) => {
    if (i.key === '/dashboard') {
      return hasPermission(CAN_ACCESS_DASHBOARD);
    }
    if (i.key === '/task') {
      return hasPermission(CAN_VIEW_FEATURE_DETECTION_TASKS);
    }
    return true;
  });

  return (
    <NaveeSidebar trigger={null} collapsed={collapsed} collapsedWidth={58}>
      <Menu
        data-testid="sidenav"
        defaultSelectedKeys={defaultSelectedKey}
        selectedKeys={defaultSelectedKey}
        mode="inline"
        items={menuItems}
      />
    </NaveeSidebar>
  );
}
