import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../../Feed/Feed';

interface GetNextImageToModerateRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GetNextImageResponse {}

const getNextImageToModerate = new Endpoint<
  GetNextImageToModerateRequestModel,
  GetNextImageResponse
>({
  url: '/api/me/duplicated_groups_moderation/next_image_to_moderate',
  method: 'POST',
  headers: {},
});

export default getNextImageToModerate;
