import { SelectProps } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import {
  FilterProviderContext,
  NewFilterProviderContext,
} from 'providers/NewFilterProvider/NewFilterProvider';
import ProductMonitor from 'types/network/Http/productMonitor';
import { intersectionAandBByKey, unionBy } from 'product-utils/src/array';
import { ADD_LOADED_WEBSITES } from 'layout/FiltersBar/constants';
import { useDispatch } from 'react-redux';
import Button from 'product-ui/src/components/atoms/Button/Button';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { AsyncSelect } from 'product-ui/src/components/atoms/AsyncSelect';
import Tag from 'product-ui/src/components/atoms/Tag';
import { TagModel } from 'product-types/src/domain/tag/Tag';
import { StyledWrapper } from './styled';

interface WebsiteTabProps {
  onSelectWebsite: (operation, websites: Array<WebsiteSuggestion>) => void;
  onClearWebsite: (operation: 'include' | 'exclude') => void;
  onRemoveWebsite: (operation, website: WebsiteSuggestion) => void;
  applyFilters: () => void;
  websiteInclude: Array<WebsiteSuggestion>;
  websiteExclude: Array<WebsiteSuggestion>;
  maxTagCount: SelectProps['maxTagCount'];
  maxTagPlaceholder: SelectProps['maxTagPlaceholder'];
}
export const WebsiteTab = ({
  onSelectWebsite,
  onClearWebsite,
  onRemoveWebsite,
  websiteInclude,
  websiteExclude,
  applyFilters,
  maxTagCount,
  maxTagPlaceholder,
}: WebsiteTabProps) => {
  const [searchInclude, setSearchInclude] = useState('');
  const [searchExclude, setSearchExclude] = useState('');
  const context = useContext<NewFilterProviderContext>(FilterProviderContext);
  const dispatch = useDispatch();

  const onSearchInclude = (search: string) => {
    if (search.includes(',')) {
      const searchItems = search.split(',');
      const selectedWebsites = (context.websites ?? []).filter((w) =>
        searchItems.some(
          (s) => w.label.toLowerCase() === s.trim().toLowerCase(),
        ),
      );
      onSelectWebsite(
        'include',
        selectedWebsites.map((w) => ({
          id: w.id,
          label: w.label,
          count: w.count,
          faviconUrl: w.faviconUrl,
          medianProductLifetime: w.medianProductLifetime,
        })),
      );
      setSearchInclude('');
      return;
    }
    setSearchInclude(search);
  };

  const onSearchExclude = (search: string) => {
    if (search.includes(',')) {
      const searchItems = search.split(',');
      const selectedWebsites = (context.websites ?? []).filter((w) =>
        searchItems.some(
          (s) => w.label.toLowerCase() === s.trim().toLowerCase(),
        ),
      );
      onSelectWebsite(
        'exclude',
        selectedWebsites.map((w) => ({
          id: w.id,
          label: w.label,
          count: w.count,
          faviconUrl: w.faviconUrl,
          medianProductLifetime: w.medianProductLifetime,
        })),
      );
      setSearchExclude('');
      return;
    }
    setSearchExclude(search);
  };

  const loadOptions = useCallback(
    (search: string) => {
      if (!search || search.length < 3) {
        return Promise.resolve(context.websites ?? []);
      }
      return ProductMonitor.endpoints.me.websiteSearch
        .call({ params: { q: search } })
        .then((result) => {
          const websites = result.results.map((res) => ({
            label: res.label,
            count: null,
            id: Number.isNaN(parseInt(res.value, 10))
              ? res.value
              : parseInt(res.value, 10),
          }));
          const searchedWebsites = websites.map((website) => ({
            ...website,
            id: `${website.id}`,
          }));
          const websiteClean = unionBy(
            searchedWebsites,
            context.websites ?? [],
            (element) => element.id,
          );
          dispatch({
            type: ADD_LOADED_WEBSITES,
            websites: websiteClean,
          });
          if (!websiteClean.length) {
            return intersectionAandBByKey(context.websites, websites, 'id');
          }
          return websites;
        })
        .catch((e) => {
          console.error(e);
          return [];
        });
    },
    [context.websites],
  );

  const tagRender = useCallback(
    (type: 'include' | 'exclude') => (props: any) => (
      <Tag
        tag={
          new TagModel({
            id: props.value,
            name: props.label,
            tagType: 'website',
          })
        }
        style={{
          marginRight: '0.25rem',
          marginBottom: '0.25rem',
        }}
        disableLoading
        showRemoveIcon
        onClickRemove={() => {
          const website =
            type === 'include'
              ? websiteInclude.find((wb) => wb.label === props.label)
              : websiteExclude.find((wb) => wb.label === props.label);
          if (website) {
            onRemoveWebsite(type, website);
          }
        }}
      />
    ),
    [websiteInclude, onRemoveWebsite],
  );

  const handleKeydown = useCallback(
    (type: 'include' | 'exclude', e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Backspace' && !e.target.value) {
        const website = type === 'include' ? websiteInclude : websiteExclude;
        if (website.length) {
          onRemoveWebsite(type, website[website.length - 1]);
        }
      }
    },
    [websiteInclude, websiteExclude, onRemoveWebsite],
  );

  return (
    <StyledWrapper vertical gap="1rem" style={{}}>
      <AsyncSelect
        searchValue={searchInclude}
        onSearch={onSearchInclude}
        minAllowedTextLength={0}
        removeInsertedFromOptions
        debounceTime={500}
        optionLabelProp="label"
        tagRender={tagRender('include')}
        fieldNames={{ value: 'id', label: 'label' }}
        value={websiteInclude}
        onClear={() => onClearWebsite('include')}
        showSelectAll
        onSelectAll={(websites: Array<WebsiteSuggestion>) => {
          onSelectWebsite('include', websites);
          onSearchInclude('');
        }}
        onSelect={(_, selectedWebsitesValue: WebsiteSuggestion) => {
          onSelectWebsite('include', [
            {
              id: selectedWebsitesValue.id,
              label: selectedWebsitesValue.label,
              count: selectedWebsitesValue.count,
              faviconUrl: selectedWebsitesValue.faviconUrl,
              medianProductLifetime:
                selectedWebsitesValue.medianProductLifetime,
            },
          ]);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleKeydown('include', e)
        }
        notFoundContent={(search) => `No results found for "${search}"`}
        hint="Please enter at least 3 characters"
        placeholder="Included Websites..."
        loadOptions={loadOptions}
        mode="multiple"
        onDropdownVisibleChange={(visible) => !visible && setSearchInclude('')}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
      />
      <AsyncSelect
        searchValue={searchExclude}
        onSearch={onSearchExclude}
        minAllowedTextLength={0}
        removeInsertedFromOptions
        debounceTime={500}
        tagRender={tagRender('exclude')}
        onClear={() => onClearWebsite('exclude')}
        fieldNames={{ value: 'id', label: 'label' }}
        value={websiteExclude}
        showSelectAll
        onSelectAll={(websites: Array<WebsiteSuggestion>) => {
          onSelectWebsite('exclude', websites);
          onSearchExclude('');
        }}
        onSelect={(_, selectedWebsitesValue: WebsiteSuggestion) => {
          onSelectWebsite('exclude', [
            {
              id: selectedWebsitesValue.id,
              label: selectedWebsitesValue.label,
              count: selectedWebsitesValue.count,
              faviconUrl: selectedWebsitesValue.faviconUrl,
              medianProductLifetime:
                selectedWebsitesValue.medianProductLifetime,
            },
          ]);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          handleKeydown('exclude', e)
        }
        notFoundContent={(search) => `No results found for "${search}"`}
        hint="Please enter at least 3 characters"
        placeholder="Excluded Websites..."
        loadOptions={loadOptions}
        mode="multiple"
        onDropdownVisibleChange={(visible) => !visible && setSearchExclude('')}
        maxTagCount={maxTagCount}
        maxTagPlaceholder={maxTagPlaceholder}
      />
      {context.applyFilters && context.showApplyButton ? (
        <Button onClick={applyFilters} size="Small" label="Apply" />
      ) : null}
    </StyledWrapper>
  );
};
