import { RelatedWebsiteRaw } from 'product-types/src/domain/website/RelatedWebsite';
import { RelatedAccountsRequest } from 'types/network/Account/RelatedAccounts';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface RelatedAccoutnsRequest extends EndpointRequests {
  urlParams: { id: string };
  data: RelatedAccountsRequest;
}

export interface RelatedWebsitesResponse {
  websites: Array<RelatedWebsiteRaw>;
  total: number;
}

const relatedWebsites = new Endpoint<
  RelatedAccoutnsRequest,
  RelatedWebsitesResponse
>({
  url: '/api/me/accounts/:id/related_websites',
  method: 'POST',
  headers: {},
});

export default relatedWebsites;
