import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export type ImageFeatureStatus = {
  label: string;
  value: any;
};

export const ImageFeatureStatusOptions: Array<ImageFeatureStatus> = [
  {
    label: 'Configured',
    value: true,
  },
  {
    label: 'Not Configured',
    value: false,
  },
];

export class ImageFeatureStatusFilterValue implements FilterValue {
  statuses: Array<ImageFeatureStatus>;

  constructor(params: Pick<ImageFeatureStatusFilterValue, 'statuses'>) {
    this.statuses = params.statuses || [];
  }

  static get defaultValue(): ImageFeatureStatusFilterValue {
    return new ImageFeatureStatusFilterValue({
      statuses: [],
    });
  }

  hasStatus(status: ImageFeatureStatus) {
    return Boolean(this.statuses.find((s) => s.value === status.value));
  }

  addStatus(status: ImageFeatureStatus) {
    return new ImageFeatureStatusFilterValue({
      statuses: makeArrayUniqueByKey(this.statuses.concat([status]), 'value'),
    });
  }

  removeStatus(status: ImageFeatureStatus) {
    return new ImageFeatureStatusFilterValue({
      statuses: this.statuses.filter((st) => st.value !== status.value),
    });
  }
}
