import { DocumentLinkRawModel } from 'product-types/src/domain/files/DocumentLink';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadComplianceDocumentRequestModel extends EndpointRequests {
  urlParams: {
    organisationId: string;
  };
  data: FormData;
}

interface UploadComplianceDocumentResponseModel {
  message: string;
  object: DocumentLinkRawModel;
}

const uploadComplianceDocument = new Endpoint<
  UploadComplianceDocumentRequestModel,
  UploadComplianceDocumentResponseModel
>({
  url: '/api/organisation/:organisationId/compliance_documents',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadComplianceDocument;
