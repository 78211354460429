import { Tabs, TabsProps as AntdTabsProps } from 'antd';
import styled from 'styled-components';

export interface TabsProps extends AntdTabsProps {
  $fullWidth?: boolean;
}

export const StyledTabs = styled(Tabs)<TabsProps>`
  .ant-tabs-nav {
    width: 100% !important;
  }

  .ant-tabs-tab {
    display: block; // centers text inside tabs
    flex: 1;
    text-align: center;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    width: 100% !important;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    `
    .ant-tabs-nav-list {
    width: 100%;
  }
    `}

  .ant-tabs-tab-btn {
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1px;
  }
`;
