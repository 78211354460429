import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';

interface CreateRequestModel extends EndpointRequests {
  data: ModerationReasonRaw;
}

const createFeature = new Endpoint<CreateRequestModel, object>({
  url: 'api/me/image_features',
  method: 'POST',
  headers: {},
});

export default createFeature;
