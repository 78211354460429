import { makeArrayUniqueByKey } from 'product-utils/src/array';
import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { FilterValue } from '../../AtomicFilters/FilterValue';
import { SearchItemModel, SearchBarType, isSearchBarType } from './SearchItem';

export interface readFilterFromQueryProps {
  poster_names?: Array<string | number>;
  vendor_names?: Array<string | number>;
  post_url?: Array<string | number>;
  poster_urls?: Array<string | number>;
  comments_to_include?: Array<string | number>;
  comments_to_exclude?: Array<string | number>;
  keywords_to_include?: Array<string | number>;
  keywords_to_exclude?: Array<string | number>;
  ocr_text_to_include?: Array<string | number>;
  ocr_text_to_exclude?: Array<string | number>;
  account_website_id?: Array<string | number>;
  account_poster_id?: Array<string | number>;
  contact_info_to_include?: Array<string | number>;
  contact_info_to_exclude?: Array<string | number>;
  cluster_id?: Array<string | number>;
  duplicated_group_id?: Array<string | number>;
  post_id?: Array<string | number>;
  searchFilterUUID?: string;
}

export class SearchValue implements FilterValue {
  values: Array<SearchItemModel>;

  constructor(prop?: Pick<SearchValue, 'values'>) {
    this.values = makeArrayUniqueByKey(prop?.values || [], 'id');
  }

  add(item: SearchItemModel) {
    return new SearchValue({
      values: [...this.values, item],
    });
  }

  updateValue(item: SearchItemModel) {
    return new SearchValue({
      values: this.values.map((a) => {
        if (a.id === item.id) {
          return item;
        }
        return a;
      }),
    });
  }

  replace(items: Array<SearchItemModel>) {
    return new SearchValue({
      values: items,
    });
  }

  remove(item: SearchItemModel) {
    return new SearchValue({
      values: this.values.filter((a) => a.id !== item.id),
    });
  }

  get queryFilterValue() {
    return this.values.reduce(
      (acc, item) => {
        if (`${item.value}`.length === 0) return acc;
        if (!acc[item.option]) {
          acc[item.option] = [];
        }
        acc[item.option].push(item.value);
        return acc;
      },
      {} as Record<SearchBarType, Array<string | number>>,
    );
  }

  static get defaultValue(): SearchValue {
    return new SearchValue();
  }

  static get readFilterFromQuery(): SearchValue {
    const urlParams = new URLSearchParams(window.location.search);
    return Object.values(SearchBarType).reduce((acc: SearchValue, key) => {
      if (!isSearchBarType(key)) {
        return acc;
      }
      const value = (urlParams.getAll(key) ?? [])
        .map((val) => val.trim())
        .filter((val) => val.length > 0);
      if (value.length) {
        return acc.replace([
          ...acc.values,
          ...value.map((val) => {
            const searchItemModel = new SearchItemModel({
              id: `${val}|${key}`,
              option: key,
              value: val,
            });
            if (key === SearchBarType.duplicatedGroupId) {
              searchItemModel.additionalInfo = {
                image_found: false,
                status: FetchableDataState.LOADING,
                id: parseInt(val, 10) as number,
              };
            }
            return searchItemModel;
          }),
        ]);
      }
      return acc;
    }, SearchValue.defaultValue);
  }

  static readFromSavedFilter(savedFilter: SavedFilterModel): SearchValue {
    const tempValue = savedFilter.toRawModel();
    return Object.keys(tempValue).reduce((acc: SearchValue, key) => {
      if (!isSearchBarType(key)) {
        return acc;
      }
      const value = tempValue[key];
      if (value?.length) {
        return acc.replace([
          ...acc.values,
          ...value.map(
            (val) =>
              new SearchItemModel({
                id: `${val}|${key}`,
                option: key as SearchBarType,
                value: val,
              }),
          ),
        ]);
      }
      return acc;
    }, SearchValue.defaultValue);
  }
}
