import { EffectiveTakedownStatsResponseModel } from 'product-types/src/domain/dashboard/DashboardEffectiveTakedownStatsModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface EffectiveTakedownStatsRequestModel extends EndpointRequests {}

const getEffectiveTakedownStats = new Endpoint<
  EffectiveTakedownStatsRequestModel,
  EffectiveTakedownStatsResponseModel
>({
  url: '/api/me/stats/takedown/effective',
  method: 'GET',
  headers: {},
});

export default getEffectiveTakedownStats;
