import { v4 as uuid } from 'uuid';
import { Filter } from '../../AtomicFilters/Filter';
import { ImageFeautureStatusFilter as ImageFeautureStatusFilterComponent } from '../../../../components/Filters/ImageFeatureStatusFilter';
import { ImageFeatureStatusFilterValue } from './ImageFeatureStatusFilterValue';

export class ImageFeatureStatusFilter implements Filter {
  uuid: string;

  label: string;

  value: ImageFeatureStatusFilterValue;

  constructor(
    params?: Partial<
      Pick<ImageFeatureStatusFilter, 'uuid' | 'label' | 'value'>
    >,
  ) {
    this.uuid = params?.uuid || uuid();
    this.label = params?.label || '';
    this.value = params?.value || ImageFeatureStatusFilterValue.defaultValue;
  }

  removeFilterValue() {
    throw new Error('Method not implemented.');
  }

  get queryFilterValue() {
    return {};
  }

  get displayingFilterValue() {
    return [];
  }

  get displayFilterComponent() {
    return null;
  }

  get component() {
    return ImageFeautureStatusFilterComponent;
  }
}
