import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface CreateIPAssetResponse {}

export interface CreateIPAssetRequestParams {
  name: string;
  image_link: string;
  brand_id: number | null;
  scraped_keyword_ids: Array<number>;
  moderation_reason_ids: Array<number>;
}

interface CreateIPAssetRequest extends EndpointRequests {
  data: CreateIPAssetRequestParams;
}

const createIPAsset = new Endpoint<CreateIPAssetRequest, CreateIPAssetResponse>(
  {
    url: '/api/me/ip_assets',
    method: 'POST',
    headers: {},
  },
);

export default createIPAsset;
