/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SelectProps } from 'antd/es/select';
import { debounce } from 'product-utils/src/debounce';
import { Flex } from 'antd';
import { Select } from '../Select';
import { Testable } from '../Interfaces/Testable';
import NaveeIcon from '../NaveeIcon/NaveeIcon';
type Option = { label: string; value: string };

interface Props
  extends Omit<
    SelectProps & Testable,
    | 'showSearch'
    | 'allowClear'
    | 'options'
    | 'filterOption'
    | 'defaultActiveFirstOption'
    | 'notFoundContent'
  > {
  loadOptions: (search: string) => Promise<Array<Option>>;
  hint?: ReactNode;
  debounceTime?: number;
  showSelectAll?: boolean;
  minAllowedTextLength?: number;
  onSelectAll?: (options: Array<Option>) => void;
  notFoundContent?: ((search: string) => ReactNode) | ReactNode;
  removeInsertedFromOptions?: boolean;
}

export const AsyncSelect = (props: Props) => {
  const [search, setSearch] = useState('');
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [options, setOptions] = useState<Array<Option>>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [debounced, setDebounced] = useState<{ cancel?: () => void } | null>(
    null,
  );
  const {
    minAllowedTextLength = 4,
    loadOptions: searchPromise,
    hint = null,
    onSelectAll,
    showSelectAll = false,
    debounceTime = 700,
    removeInsertedFromOptions = false,
    ...rest
  } = props;

  const debounceSearch = useCallback(
    debounce(
      (q: string) =>
        searchPromise(q)
          .then(setOptions)
          .finally(() => setLoadingOptions(false)),
      debounceTime ?? 700,
    ),
    [rest.value],
  );

  useEffect(() => () => {
    if (debounced?.cancel) {
      debounced.cancel();
    }
  });

  useEffect(() => {
    const normalizedSearch = search.trim();
    if (normalizedSearch.length > 0) {
      setOptions([]);
    }
    if (normalizedSearch.length >= minAllowedTextLength) {
      setLoadingOptions(true);
      setDebounced(debounceSearch(normalizedSearch));
    }
  }, [search]);

  let notFoundContent: ReactNode | null = null;
  if (loadingOptions) {
    notFoundContent = <div data-testid="loading-content">Loading...</div>;
  } else {
    if (search.length > 0 && search.length < minAllowedTextLength) {
      notFoundContent = hint;
    }
    if (options.length === 0 && search.length >= minAllowedTextLength) {
      notFoundContent = props.notFoundContent as ReactNode;
      if (typeof props.notFoundContent === 'function') {
        notFoundContent = props.notFoundContent(search);
      }
    }
  }

  const filteredOptions = useMemo(
    () =>
      (removeInsertedFromOptions
        ? options.filter(
            (opt) =>
              !(rest.value ?? [])
                .map((o) => o[rest.fieldNames?.value ?? 'value'])
                .includes(opt[rest.fieldNames?.value ?? 'value']),
          )
        : options
      ).map((opt) => {
        if (rest.fieldNames?.value) {
          return { ...opt, value: opt[rest.fieldNames.value] };
        }
        return opt;
      }),
    [removeInsertedFromOptions, options, rest.value],
  );

  const handleChange = (value) => {
    if (rest.onChange) {
      rest.onChange(
        value,
        options.filter((o) =>
          value.includes(o[rest.fieldNames?.value ?? 'value']),
        ),
      );
    }
  };

  const suffixIcon = (
    <Flex gap="0.5rem">
      {showSelectAll && onSelectAll && !loadingOptions ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectAll?.(options);
          }}
          style={{
            position: 'absolute',
            width: '70px',
            visibility:
              isFocused && (props?.searchValue?.length ?? 0) >= 3
                ? 'visible'
                : 'hidden',
            transition: 'visibility 0.2s',
            top: 0,
            right: '1rem',
            color: 'var(--link-default)',
            cursor: 'pointer',
            zIndex: 10000,
          }}
        >
          Select all
        </div>
      ) : null}
      <NaveeIcon.ChevronDown width={20} height={20} />
    </Flex>
  );

  return (
    <Select
      allowClear
      {...rest}
      showSearch
      $showSelectAll={showSelectAll}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      defaultActiveFirstOption={false}
      filterOption={false}
      options={filteredOptions}
      notFoundContent={notFoundContent}
      onSearch={(query) => {
        setSearch(query);
        if (rest.onSearch) {
          rest.onSearch(query);
        }
      }}
      suffixIcon={suffixIcon}
      onChange={handleChange}
    />
  );
};
