import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { IpDocumentRaw } from 'types/src/domain/ipCertificates/IpDocumentModel';

interface UpdateIpDocumentRequest extends EndpointRequests {
  data: {
    name: string;
    removal_reason_category_id: number;
    ip_jurisdiction_id: number;
    registration_identifier: string;
    ip_document_type?: string;
    online_location: string;
    ip_asset_id?: number;
    ip_classes?: Array<number>;
    registration_end_date?: string;
    document_url?: string;
    document_filename?: string;
    document_file_size?: number;
    document_version?: string;
    document_hash?: string;
  };
  urlParams: { id: string };
}

interface UpdateIpDocumentResponse {
  message: string;
  object: IpDocumentRaw;
}

const updateIpDocument = new Endpoint<
  UpdateIpDocumentRequest,
  UpdateIpDocumentResponse
>({
  url: '/api/me/ip-certificates/:id',
  method: 'PATCH',
  headers: {},
});

export default updateIpDocument;
