import React, { CSSProperties, useMemo } from 'react';

import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import { Spin } from 'product-ui/src/components/atoms/Spin/Spin';
import { useKeyboardScrolling } from 'hooks/keyboardActions/useKeyboardScrolling';
import { usePrintPage } from 'hooks/usePrintPage';
import { currentPlatformFavicon } from 'utils/currentPlatformFavicon';
import { Sidebar } from '../Sidebar';
import { ErrorBoundary } from '../ErrorBoundary';

const { Content } = Layout;

interface BasicPageLayoutProps {
  content?: React.ReactNode;
  title: string;
  hideSidebar?: boolean;
  hasNoPadding?: boolean;
}

const tableContentStyles: CSSProperties = {
  height: '100%',
  position: 'relative',
  minHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
};
const LayoutStyles: CSSProperties = { height: '100%', flexDirection: 'row' };

export function BasicPageLayout({
  content,
  title,
  hideSidebar = false,
  hasNoPadding = false,
}: BasicPageLayoutProps) {
  usePrintPage();
  const styles = useMemo(
    () => ({ height: '100%', padding: hasNoPadding ? '0' : '70px 0 0' }),
    [hasNoPadding],
  );
  const scrollableRef = React.useRef<HTMLDivElement>(null);
  useKeyboardScrolling({
    scrollableNode: scrollableRef.current,
  });
  return (
    <Layout style={LayoutStyles}>
      <Helmet>
        <title itemProp="name" lang="en">
          {title}
        </title>
        <link rel="icon" href={currentPlatformFavicon()} type="image/x-icon" />
      </Helmet>
      {!hideSidebar && <Sidebar />}
      <Layout key="layout" style={styles}>
        <Content
          ref={scrollableRef}
          style={tableContentStyles}
          id="scrollableNode"
          className="scrollableNode"
          data-scroll-lock-scrollable
        >
          <ErrorBoundary>{content}</ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
}

interface BasicPageLayoutSpinnerProps {
  children: React.ReactNode;
  spinning: boolean;
  className?: string;
}

export const BasicPageLayoutSpinner = ({
  children,
  spinning,
  className = '',
}: BasicPageLayoutSpinnerProps) => (
  <ErrorBoundary style={{ paddingBottom: 94 }}>
    <Spin
      spinning={spinning}
      size="large"
      wrapperClassName={`base-content-wrapper ${className}`}
    >
      {children}
    </Spin>
  </ErrorBoundary>
);

export default BasicPageLayout;
