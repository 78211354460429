import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UpdateAccountLabelRequestModel extends EndpointRequests {
  urlParams: {
    id: string;
  };
  data: LabelSaveModel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UpdateAccountLabelResponse {}

const updateAccountLabel = new Endpoint<
  UpdateAccountLabelRequestModel,
  UpdateAccountLabelResponse
>({
  url: '/api/me/organisation/labels/account/:id',
  method: 'PUT',
  headers: {},
});

export default updateAccountLabel;
