import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadAccountFileRequest extends EndpointRequests {
  data: FormData;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UploadAccountFileResponse {}

const uploadPostFile = new Endpoint<
  UploadAccountFileRequest,
  UploadAccountFileResponse
>({
  url: '/api/me/uploads/post/file',
  method: 'POST',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export default uploadPostFile;
