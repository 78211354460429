/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/ban-ts-comment */
import { SelectProps } from 'antd/lib/select';
import React, { ComponentType, useContext, useEffect, useState } from 'react';
import { Select as AntdSelect } from 'antd';
import { v4 as uuidFunc } from 'uuid';
import { GetProps } from 'antd/lib';
import { ScrollContext } from '../../../providers/ScrollProvider';
import NaveeIcon from '../NaveeIcon/NaveeIcon';
import { StyledSelect } from './styled';

const { Option: AntdOption } = AntdSelect;

interface ScrollBehaviorProps {
  onDropdownVisibleChange?: (open: boolean) => void;
  shouldLockScroll?: boolean;
  dataTestId?: string;
  open?: boolean;
}

export type Props = SelectProps &
  ScrollBehaviorProps &
  GetProps<typeof StyledSelect>;

type SelectWithScrollLock = ((props: Props) => JSX.Element) & {
  Option?: typeof AntdOption;
};

const withScrollLock =
  <P extends Props>(Component: ComponentType<P>) =>
  ({ dataTestId, ...props }: P) => {
    const { shouldLockScroll = true } = props;
    const [uuid] = useState(uuidFunc());
    const {
      addReference,
      removeReference,
      unLockScroll,
      lockScroll,
      hasReference,
    } = useContext(ScrollContext);

    useEffect(() => {
      if (shouldLockScroll) {
        addReference(uuid);
        return () => removeReference(uuid);
      }
      return () => {};
    }, []);

    useEffect(() => {
      if (props.disabled && hasReference(uuid)) {
        unLockScroll(uuid);
      }
    }, [props.disabled]);

    const onVisibilityChange = (open: boolean) => {
      if (props.open === false) {
        return;
      }
      if (!shouldLockScroll) {
        return;
      }
      if (open) {
        lockScroll(uuid);
      } else {
        unLockScroll(uuid);
      }
    };

    return (
      // @ts-ignore
      <Component
        onDropdownVisibleChange={onVisibilityChange}
        data-testid={dataTestId}
        suffixIcon={<NaveeIcon.ArrowDownTwo height={20} width={20} />}
        {...props}
      />
    );
  };

export const Select: SelectWithScrollLock = withScrollLock((props) => (
  <StyledSelect {...props} />
));

Select.Option = AntdOption;
