import { UploadHistoryRaw } from 'product-types/src/domain/uploadHistory/UploadHistory';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UploadHistoryRequestModel extends EndpointRequests {}

interface UploadHistoryResponse {
  total: number;
  uploads: Array<UploadHistoryRaw>;
}

const uploadHistory = new Endpoint<
  UploadHistoryRequestModel,
  UploadHistoryResponse
>({
  url: '/api/me/upload_history',
  method: 'GET',
  headers: {},
});

export default uploadHistory;
