import { Endpoint } from 'types/network/Http/endpoint';

export type MissingIpDocumentsResponse = {
  missing_certificates: Array<{
    ip_asset_name: string;
    missing_jurisdictions: string[];
  }>;
};

const getMissingIpDocuments = new Endpoint<object, MissingIpDocumentsResponse>({
  url: '/api/me/ip-certificates/missing',
  method: 'GET',
  headers: {},
});

export default getMissingIpDocuments;
