import { BrandRaw } from 'product-types/src/domain/ipAsset/Brand';
import { ModerationReasonRaw } from 'product-types/src/domain/ipAsset/ModerationReason';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ScrapedKeywordRaw } from 'types/src/domain/ipAsset/ScrapedKeywords';

export interface GetIPAssetsDropdownOptionsResponse {
  brands: Array<BrandRaw>;
  scraped_keywords: Array<ScrapedKeywordRaw>;
  moderation_reasons: Array<ModerationReasonRaw>;
}

const getIPAssetsDropdownOptions = new Endpoint<
  EndpointRequests,
  GetIPAssetsDropdownOptionsResponse
>({
  url: '/api/me/ip_portfolio_info',
  method: 'GET',
  headers: {},
});

export default getIPAssetsDropdownOptions;
