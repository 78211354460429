import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadPictureRequestModel extends EndpointRequests {
  data: FormData;
}

export interface UploadPictureResponse {
  message: string;
  object: {
    image_link: string;
  };
}

const uploadPicture = new Endpoint<
  UploadPictureRequestModel,
  UploadPictureResponse
>({
  url: '/api/me/ip_assets/image',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default uploadPicture;
