import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface UploadIpDocumentRequest extends EndpointRequests {
  data: FormData;
}

export interface UploadIpDocumentResponse {
  message: string;
  object: {
    document_url: string;
    document_filename: string;
    document_file_size: number;
    document_version: string;
    document_hash: string;
  };
}

const uploadIpDocument = new Endpoint<
  UploadIpDocumentRequest,
  UploadIpDocumentResponse
>({
  url: '/api/me/ip-certificates/document',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default uploadIpDocument;
