import { LabelRaw } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface OrganisationImageLabelsRequestModel extends EndpointRequests {}

export type OrganisationImageLabelsResponse = Array<LabelRaw>;

const image = new Endpoint<
  OrganisationImageLabelsRequestModel,
  OrganisationImageLabelsResponse
>({
  url: '/api/me/organisation/labels/image',
  method: 'GET',
  headers: {},
});

export default image;
