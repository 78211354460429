import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

type UpdateFilterData = { id: number; name?: string; is_default?: boolean };

type UpdateFilterRequestModel = {
  data: UpdateFilterData;
} & EndpointRequests;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface UpdateFilterResponse {}

const updateFilter = new Endpoint<
  UpdateFilterRequestModel,
  UpdateFilterResponse
>({
  url: '/api/me/filter_presets',
  method: 'PATCH',
  headers: {},
});

export default updateFilter;
