import * as Domain from 'product-types/src/domain/Domain';
import { FilterValue } from '../../AtomicFilters/FilterValue';

export interface readFilterFromQueryProps {
  assignee_ids: Domain.Task.TaskStatus[];
}

export class TaskAssigneeFilterValue implements FilterValue {
  assignee_ids: string[];

  constructor(params: Pick<TaskAssigneeFilterValue, 'assignee_ids'>) {
    this.assignee_ids = params.assignee_ids;
  }

  setAssigneeIds(assigneeIds: string[]) {
    return new TaskAssigneeFilterValue({ assignee_ids: assigneeIds });
  }

  addAssignee(assignee: string) {
    return new TaskAssigneeFilterValue({
      assignee_ids: [...this.assignee_ids, assignee],
    });
  }

  removeAssignee(assignee: string) {
    return new TaskAssigneeFilterValue({
      assignee_ids: this.assignee_ids.filter((status) => status !== assignee),
    });
  }

  static get defaultValue(): TaskAssigneeFilterValue {
    return new TaskAssigneeFilterValue({ assignee_ids: [] });
  }

  static readFilterFromQuery(): TaskAssigneeFilterValue {
    const assigneeIdsQuery = new URLSearchParams(window.location.search).getAll(
      'assignee_ids',
    );

    const assigneeIds = (assigneeIdsQuery
      ?.map((insight) => Domain.Task.GetTaskStatusFilterOptionByValue(insight))
      .filter((v) => !!v) || []) as [];

    if (assigneeIds.length) {
      return new TaskAssigneeFilterValue({
        assignee_ids: assigneeIds,
      });
    }
    return TaskAssigneeFilterValue.defaultValue;
  }
}
