import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteIpDocumentRequest extends EndpointRequests {
  urlParams: { id: string };
}

const deleteIpDocument = new Endpoint<DeleteIpDocumentRequest, object>({
  url: '/api/me/ip-certificates/:id',
  method: 'DELETE',
  headers: {},
});

export default deleteIpDocument;
