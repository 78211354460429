/* eslint-disable-next-line import/no-unresolved */
import NaveeIcon from '!file-loader?name=[name].[ext]!../assets/images/favicon.ico';
/* eslint-disable-next-line import/no-unresolved */
import CorsearchIcon from '!file-loader?name=[name].[ext]!../assets/images/cropped-Corsearch_Profile_Image_Square_Grey-V1-32x32.png';

export const currentPlatformFavicon = () => {
  if (window.location.href.includes('navee.co')) {
    return NaveeIcon;
  }
  return CorsearchIcon;
};
