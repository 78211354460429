import { TableParams } from 'product-types/src/common/TableParams/TableParams';
import { CommentModel } from 'product-types/src/domain/comment/Comment';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import {
  AccountFeedDataModel,
  AssociatedImagesAccount,
} from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { AccountModerationModel } from 'product-types/src/domain/account/AccountModerationModel';
import { RelatedAccount } from 'product-types/src/domain/account/RelatedAccount';
import { Contact } from 'product-types/src/domain/contact/Contact';
import { Pagination } from 'product-types/src/common/Pagination/Pagination';
import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { RelatedAccountsFiltersModel } from 'types/filters/MoleculesFilterImplementation/RelatedAccountsFilterModel';
import {
  LOAD_ACCOUNTS_DATA,
  LOAD_ACCOUNTS_SUCCESS,
  PERFORM_ACCOUNT_MODERATION,
  UPDATE_ACCOUNT,
  LOAD_IMAGES_ACCOUNT,
  LOAD_IMAGES_ACCOUNT_SUCCESS,
  REFRESH_ACCOUNT,
  LOAD_PREV_ACCOUNT_TO_MODERATE,
  LOAD_NEXT_ACCOUNT_TO_MODERATE,
  DEL_GEO_ACCOUNT,
  SET_GEO_ACCOUNT,
  RESET_STATE,
  UPDATE_FILTERS_RELATED_POSTS,
  LOAD_COMMENTS_ACCOUNT,
  LOAD_COMMENTS_ACCOUNT_SUCCESS,
  UPDATE_RELATED_ACCOUNTS,
  LOAD_RELATED_ACCOUNTS,
  UPDATE_RELATED_ACCOUNTS_FILTERS,
  UPDATE_MODERATION_QUEUE_INFORMATION,
  UPDATE_RELATED_ACCOUNTS_TOTAL,
  MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
  LOAD_ACCOUNT_RESOURCES,
  ADD_COMMENT_ACCOUNT,
  DELETE_COMMENT_ACCOUNT,
  RESET_FILTERS_RELATED_POSTS,
  RECRAWL_ACCOUNT,
  LOAD_ACCOUNT_RELATED_POSTS,
  UPDATE_CONTACT_INFORMATION_ACCOUNT,
  UPDATE_IMAGES_ACCOUNT_PAGINATION,
  UPDATE_ACCOUNT_TAKEN_DOWN_STATUS,
  LOAD_ALL_RELATED_ACCOUNT_IDS,
  UPDATE_ALL_RELATED_ACCOUNT_IDS,
} from './constants';

// May be any actions in this reducer can be removed after deep investigation of bussines logic.
// In this time i keep actions for backward compatiblity. We can refactor this part only after deep investigations and discussions with backend

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function loadRelatedAccountIdsAction(filters: FeedRequestParameter) {
  return {
    type: LOAD_ALL_RELATED_ACCOUNT_IDS,
    filters,
  };
}

export function updateRelatedAccountIdsAction(
  accountIds: FetchableData<Array<string>>,
) {
  return {
    type: UPDATE_ALL_RELATED_ACCOUNT_IDS,
    accountIds,
  };
}

export const loadAccountsDataAction = (pageState) => ({
  type: LOAD_ACCOUNTS_DATA,
  payload: pageState,
});

export const loadAccountsDataSuccessAction = (
  accountsData: FetchableData<AccountFeedDataModel>,
) => ({
  type: LOAD_ACCOUNTS_SUCCESS,
  accountsData,
});

export const updateContactInformationAccount = (
  contactInformation: Array<Contact>,
  accountId: string,
  onSuccess: () => void,
) => ({
  type: UPDATE_CONTACT_INFORMATION_ACCOUNT,
  contactInformation,
  accountId,
  onSuccess,
});

export const updateAccountModerationQueue = ({
  number_of_accounts_to_moderate,
  account_moderation_index,
}: {
  number_of_accounts_to_moderate: number;
  account_moderation_index: number;
}) => ({
  type: UPDATE_MODERATION_QUEUE_INFORMATION,
  number_of_accounts_to_moderate,
  account_moderation_index,
});

export function refreshAccountAction({ account_id }) {
  return {
    type: REFRESH_ACCOUNT,
    account_id,
  };
}

export function loadAccountResourcesAction({
  accountId,
}: {
  accountId: string;
}) {
  return {
    type: LOAD_ACCOUNT_RESOURCES,
    accountId,
  };
}

export function loadAccountRelatedPostsAction({
  accountId,
}: {
  accountId: string;
}) {
  return {
    type: LOAD_ACCOUNT_RELATED_POSTS,
    accountId,
  };
}

export function updateRelatedPostFilters(payload: TableParams) {
  return {
    type: UPDATE_FILTERS_RELATED_POSTS,
    payload,
  };
}

export function updateAssociatedImagesPagination(payload: Pagination) {
  return {
    type: UPDATE_IMAGES_ACCOUNT_PAGINATION,
    payload,
  };
}

export function updateAccount(account: FetchableData<AccountModerationModel>) {
  return {
    type: UPDATE_ACCOUNT,
    account,
  };
}

export const loadRelatedAccounts = (
  filters: RelatedAccountsFiltersModel,
  account_id: string,
) => ({
  type: LOAD_RELATED_ACCOUNTS,
  filters,
  account_id,
});

export const updateRelatedAccountsFilters = (
  filters: RelatedAccountsFiltersModel,
) => ({
  type: UPDATE_RELATED_ACCOUNTS_FILTERS,
  filters,
});

export const updateRelatedAccountsTotal = (total: number) => ({
  type: UPDATE_RELATED_ACCOUNTS_TOTAL,
  total,
});

export const updateRelatedAccounts = (
  relatedAccounts: FetchableData<Array<RelatedAccount>>,
) => ({
  type: UPDATE_RELATED_ACCOUNTS,
  relatedAccounts,
});

export function performAccountModerationAction(
  accounts_array,
  status,
  refresh_action,
  success_message = '',
) {
  const account_statuses = accounts_array.map((account) => ({
    account_id: account._id,
    status,
  }));
  return {
    type: PERFORM_ACCOUNT_MODERATION,
    data: { account_statuses },
    refresh_action,
    success_message,
  };
}

export interface UpdateAccountTakedownStatusProps {
  account_ids: Array<number>;
  taken_down: boolean;
  successAction: () => any;
}

export function updateAccountTakedownStatus(
  props: UpdateAccountTakedownStatusProps,
) {
  return {
    type: UPDATE_ACCOUNT_TAKEN_DOWN_STATUS,
    ...props,
  };
}

export interface AccountsForRecrawl {
  accounts: Array<{
    url: string;
    label: string;
  }>;
  success_action: () => void;
}
export function recrawlAccounts(params: AccountsForRecrawl) {
  return {
    type: RECRAWL_ACCOUNT,
    ...params,
  };
}

export function moderateAccountAndUpdateInPlaceAction(
  account: AccountModerationModel,
  status,
  success_message = '',
) {
  const data = { account_id: account._id, status };
  return {
    type: MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function loadNextAccountToModerateAction(state) {
  return {
    type: LOAD_NEXT_ACCOUNT_TO_MODERATE,
    state,
  };
}

export function addCommentAccount({ comment, objectId, fileIds }) {
  return {
    type: ADD_COMMENT_ACCOUNT,
    data: { comment, file_ids: fileIds },
    objectId,
  };
}

export function deleteCommentAccount({ commentId, objectId }) {
  return {
    type: DELETE_COMMENT_ACCOUNT,
    commentId,
    objectId,
  };
}

export function loadAccountComments(accountId) {
  return {
    type: LOAD_COMMENTS_ACCOUNT,
    accountId,
  };
}

export function updateAccountCommentAction(
  commentsData: FetchableData<Array<CommentModel>>,
) {
  return {
    type: LOAD_COMMENTS_ACCOUNT_SUCCESS,
    commentsData,
  };
}

export function resetFiltersRelatedPosts() {
  return {
    type: RESET_FILTERS_RELATED_POSTS,
  };
}

export function loadPrevAccountToModerateAction(state) {
  return {
    type: LOAD_PREV_ACCOUNT_TO_MODERATE,
    state,
  };
}

export function loadAccountImagesAction({
  object_id,
  offset = 0,
  perpage = 10,
}) {
  return {
    type: LOAD_IMAGES_ACCOUNT,
    object_id,
    offset,
    perpage,
  };
}

export function updateAccountImagesAction({
  accountImages,
}: {
  accountImages: FetchableData<AssociatedImagesAccount>;
}) {
  return {
    type: LOAD_IMAGES_ACCOUNT_SUCCESS,
    accountImages,
  };
}

export function setGeoAccountAction({ value, account_id }, refresh_action) {
  return {
    type: SET_GEO_ACCOUNT,
    value,
    account_id,
    refresh_action,
  };
}

export function delGeoAccountAction({ account_id }, refresh_action) {
  return {
    type: DEL_GEO_ACCOUNT,
    account_id,
    refresh_action,
  };
}

export function performAccountQACheckAction(
  accounts_array,
  qa_checked,
  refresh_action,
  success_message = '',
) {
  const account_statuses = accounts_array.map((account) => ({
    account_id: account._id,
    qa_checked,
  }));
  return {
    type: PERFORM_ACCOUNT_MODERATION,
    data: { account_statuses },
    refresh_action,
    success_message,
  };
}

export function qaCheckAccountAndUpdateInPlaceAction(
  account: AccountModerationModel,
  qa_checked,
  success_message = '',
) {
  const data = { account_id: account._id, qa_checked };
  return {
    type: MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}

export function performAccountValidationAction(
  accounts_array,
  validated,
  refresh_action,
  success_message = '',
) {
  const account_statuses = accounts_array.map((account) => ({
    account_id: account._id,
    validated,
  }));
  return {
    type: PERFORM_ACCOUNT_MODERATION,
    data: { account_statuses },
    refresh_action,
    success_message,
  };
}

export function validateAccountAndUpdateInPlaceAction(
  account: AccountModerationModel,
  validated,
  success_message = '',
) {
  const data = { account_id: account._id, validated };
  return {
    type: MODERATE_ACCOUNT_AND_UPDATE_IN_PLACE,
    data,
    success_message,
  };
}
