import {
  Endpoint,
  EndpointRequestsWithHeaders,
  withHeaders,
} from 'types/network/Http/endpoint';

interface ImportIpDocumentsRequest extends EndpointRequestsWithHeaders {
  data: FormData;
}

export type ImportIpDocumentsResponse = withHeaders<{
  message: string;
}>;

const importIpDocuments = new Endpoint<
  ImportIpDocumentsRequest,
  ImportIpDocumentsResponse
>({
  url: '/api/me/ip-certificates/import',
  method: 'POST',
  headers: { 'Content-Type': 'multipart/form-data' },
});

export default importIpDocuments;
