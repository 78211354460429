import { Endpoint, EndpointRequests } from '../../../../endpoint';

interface SwitchOrganisationModel extends EndpointRequests {
  params: {
    organisation_uid: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SwitchOrganisationModel {}

const switchOrganisation = new Endpoint<
  SwitchOrganisationModel,
  SwitchOrganisationModel
>({
  url: '/api/user/switch_organisation',
  method: 'GET',
  headers: {},
});

export default switchOrganisation;
