import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ConfirmSelectionOrganisation from '../ConfirmSelectionOrganisation/loadable';
import SelectOrganisation from '../SelectOrganisation/loadable';
import LoginPage from '../LoginPage/loadable';
import SetPasswordPage from '../SetPassword/loadable';
import ResetPasswordPage from '../ResetPassword/loadable';

import DashboardPage from '../DashboardPage/loadable';
import NotFoundPage from '../NotFoundPage/loadable';

import UploadImage from '../Uploads/Image/loadable';
import UploadPost from '../Uploads/Post/loadable';
import UploadAccount from '../Uploads/Account/loadable';
import UploadHistory from '../UploadHistory/loadable';

import PostViewContainer from '../PostViewContainer/loadable';
import ImageViewContainer from '../ImageView/loadable';
import AccountViewContainer from '../AccountViewContainer/loadable';
import WebsiteViewContainer from '../WebsiteViewContainer/loadable';
import TaskViewContainer from '../TaskViewContainer/loadable';
import ClusterViewContainer from '../ClusterViewContainer/loadable';

import PostModerationContainer from '../PostModerationContainer/loadable';
import ImageModerationContainer from '../ImageModerationContainer/loadable';
import AccountModerationContainer from '../AccountModerationContainer/loadable';
import WebsiteModerationContainer from '../WebsiteModerationContainer/loadable';
import ClusterModerationContainer from '../ClusterModerationContainer/loadable';
import TasksModerationContainer from '../TasksModerationContainer/loadable';
import NotificationsPage from '../NotificationsPage/loadable';
import SettingsPage from '../SettingsPage/loadable';

import { AppState } from '../../store/storeAccess';
import { UserLoadingSkeleton } from './components/UserLoadingSkeleton';
import { PrivateRoutes } from './components/PrivateRoute';
import { RouteForUnauthorized } from './components/RouteForUnauthorized';

export function Router() {
  const organisationUid = useSelector(
    (state: AppState) => state.loginPage?.currentUser?.data?.organisation?.uid,
  );

  return (
    <Routes>
      <Route element={<UserLoadingSkeleton />}>
        <Route element={<RouteForUnauthorized />}>
          <Route path="/login/*" element={<LoginPage />} />
        </Route>

        <Route path="/set-password" element={<SetPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route
          path="/"
          element={<Navigate to={`/${organisationUid}/post`} />}
        />
        <Route element={<PrivateRoutes />}>
          <Route
            path="/confirm-select-organisation"
            element={<ConfirmSelectionOrganisation />}
          />
          <Route path="/select-organisation" element={<SelectOrganisation />} />
          <Route path="/:orgId/dashboard" element={<DashboardPage />} />
          <Route path="/:orgId/settings/*" element={<SettingsPage />} />
          <Route
            path="/:orgId/image/:id/:moderation?"
            element={<ImageModerationContainer />}
          />
          <Route
            path="/:orgId/post/:id/:moderation?"
            element={<PostModerationContainer />}
          />
          <Route
            path="/:orgId/account/:id/:moderation?"
            element={<AccountModerationContainer />}
          />
          <Route
            path="/:orgId/website/:id/:moderation?"
            element={<WebsiteModerationContainer />}
          />
          <Route
            path="/:orgId/cluster/:id"
            element={<ClusterModerationContainer />}
          />
          <Route
            path="/:orgId/task/:id"
            element={<TasksModerationContainer />}
          />
          <Route path="/:orgId/upload/add/image" element={<UploadImage />} />
          <Route path="/:orgId/upload/add/post" element={<UploadPost />} />
          <Route
            path="/:orgId/upload/add/account"
            element={<UploadAccount />}
          />
          <Route path="/:orgId/notifications" element={<NotificationsPage />} />
          <Route path="/:orgId/upload/history" element={<UploadHistory />} />
          <Route path="/:orgId/account" element={<AccountViewContainer />} />
          <Route path="/:orgId/website" element={<WebsiteViewContainer />} />
          <Route path="/:orgId/task" element={<TaskViewContainer />} />
          <Route path="/:orgId/cluster" element={<ClusterViewContainer />} />
          <Route path="/:orgId/image" element={<ImageViewContainer />} />
          <Route path="/:orgId/post" element={<PostViewContainer />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
