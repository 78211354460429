import React from 'react';
import { Badge } from 'antd';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useLocation } from 'react-router-dom';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';

interface SettingsMenuItemProps {
  collapsed: boolean;
}

const SettingsMenuItem = ({ collapsed }: SettingsMenuItemProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <Badge offset={[0, 2]}>
        <NaveeIcon.Setting className="navee_svg" />
      </Badge>
      {!collapsed && (
        <Typography
          textTransform="uppercase"
          variant="xsmall"
          fontWeight={pathname.includes('settings') ? 'bold' : 'medium'}
          color={
            pathname.includes('settings')
              ? 'var(--primary-black)'
              : 'var(--neutral-grey-500)'
          }
        >
          Settings
        </Typography>
      )}
    </>
  );
};

export default SettingsMenuItem;
