import { KeywordRaw } from 'product-types/src/domain/keyword/Keyword';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface GetKeywordResponseModel {
  keywords: Array<KeywordRaw>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GetKeywordRequestModel extends EndpointRequests {}

const getKeywords = new Endpoint<
  GetKeywordRequestModel,
  GetKeywordResponseModel
>({
  url: '/api/me/crawling_config/keywords',
  method: 'GET',
  headers: {},
});

export default getKeywords;
