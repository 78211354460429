import { FetchableDataState } from 'product-types/src/common/FetchableData/FetchableData';
import { ReactElement } from 'react';

export enum SearchBarType {
  posterNames = 'poster_names',
  vendorNames = 'vendor_names',
  domainNames = 'domain_names',
  postUrl = 'post_url',
  posterUrls = 'poster_urls',
  commentsToInclude = 'comments_to_include',
  commentsToExclude = 'comments_to_exclude',
  keywordsToInclude = 'keywords_to_include',
  keywordsToExclude = 'keywords_to_exclude',
  ocrTextToInclude = 'ocr_text_to_include',
  ocrTextToExclude = 'ocr_text_to_exclude',
  relatedToAccountId = 'related_to_account_id',
  accountWebsiteId = 'account_website_id',
  accountPosterId = 'account_poster_id',
  contactInfoToInclude = 'contact_info_to_include',
  contactInfoToExclude = 'contact_info_to_exclude',
  clusterId = 'cluster_id',
  duplicatedGroupId = 'duplicated_group_id',
  duplicatedGroupUploaded = 'duplicated_group_id_uploaded',
  postId = 'post_id',
}

export const isSearchBarType = (value: any): boolean =>
  Object.values(SearchBarType).includes(value);

export interface SearchItemOption {
  label: string;
  values_to_update: SearchBarType;
  icon: ReactElement;
  prefix: string;
  exclude?: boolean;
}

export type SearchItemModelAdditionalInfo = Record<string, unknown>;

export interface DuplicatedGroupSearchItemModelAdditionalInfo
  extends SearchItemModelAdditionalInfo {
  id: string | number;
  url: string;
  image_found: boolean;
  fuid: any;
  status: FetchableDataState;
}

export class SearchItemModel {
  id: string;

  option: SearchBarType;

  value: string | number;

  additionalInfo?: SearchItemModelAdditionalInfo;

  constructor(
    prop: Pick<SearchItemModel, 'id' | 'option' | 'value' | 'additionalInfo'>,
  ) {
    this.id = prop.id;
    this.option = prop.option;
    this.value = prop.value;
    this.additionalInfo = prop.additionalInfo;
  }
}
