import { UserFilterRoleEnum } from 'product-types/src/domain/user/UserFilterRole';
import { ModerationStatusEnum } from '../ModrationStatus/ModrationStatus';

export enum CrawlingDateValueEnum {
  crawlingDate = 'crawling_date',
  postCrawlingDate = 'all_views_post_crawling_date',
  moderationDate = 'moderation_date',
  checkDate = 'check_date',
  validationDate = 'validation_date',
  lastActivity = 'last_activity_date',
  takedownSent = 'take_down_sent_date',
  takedownDate = 'take_down_date',
  vendorCreationDate = 'vendor_creation_date',
  dateOfAdding = 'vendor_addition_date',
  dateOfTagging = 'tag_date',
  vendorDateOfTagging = 'vendor_tag_date',
  taskCreationDate = 'task_creation_date',
  commentingDate = 'comment_date',
  imageExpiryDate = 'image_expiry_date',
}

export enum CrawlingDateLabelEnum {
  crawlingDate = 'Crawling Date',
  postCrawlingDate = 'Post Crawling Date',
  moderationDate = 'Moderation Date',
  checkDate = 'Check Date',
  validationDate = 'Validation Date',
  lastActivity = 'Last Activity',
  takedownSent = 'Takedown Sent',
  takedownDate = 'Takedown Date',
  vendorCreationDate = 'Cluster Creation Date',
  dateOfAdding = 'Date of Addition',
  dateOfTagging = 'Date of Tagging',
  vendorDateOfTagging = 'Date of Tagging Vendor',
  commentingDate = 'Comment Date',
  imageExpiryDate = 'Image Expiry Date',
  taskCreationDate = 'Task Creation Date',
}

export const CrawlingDateByModerationStatus = {
  [ModerationStatusEnum.moderated]: CrawlingDateValueEnum.moderationDate,
  [ModerationStatusEnum.checked]: CrawlingDateValueEnum.checkDate,
  [ModerationStatusEnum.validated]: CrawlingDateValueEnum.validationDate,
};

export const CrawlingDateByUserRole = {
  [UserFilterRoleEnum.moderator]: CrawlingDateValueEnum.moderationDate,
  [UserFilterRoleEnum.qa]: CrawlingDateValueEnum.checkDate,
  [UserFilterRoleEnum.validator]: CrawlingDateValueEnum.validationDate,
};

export const getSmallestCrawlingDate = (
  crawlingDates: CrawlingDateValueEnum[],
) => {
  if (crawlingDates.some((e) => e === CrawlingDateValueEnum.moderationDate)) {
    return CrawlingDateValueEnum.moderationDate;
  }
  if (crawlingDates.some((e) => e === CrawlingDateValueEnum.checkDate)) {
    return CrawlingDateValueEnum.checkDate;
  }
  if (crawlingDates.some((e) => e === CrawlingDateValueEnum.validationDate)) {
    return CrawlingDateValueEnum.validationDate;
  }
  return null;
};
