import { useEffect } from 'react';

export const useWindowResize = (callback: () => void) => {
  useEffect(() => {
    const handleResize = () => {
      if (callback && typeof callback === 'function') {
        callback();
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [callback]);
};
