import React from 'react';
import { CanBeQueriable } from 'product-types/src/network/Query/Query';

import { DisplayingFilterValue } from './DisplayingFilterValue';
import { FilterValue } from './FilterValue';

export interface DisplayFilterComponentProps {
  filterValue: DisplayingFilterValue;
  onClickRemove: () => void;
}

export type DisplayFilterComponent =
  React.FC<DisplayFilterComponentProps> | null;

export interface FilterParams {
  value: FilterValue;
  onChange: (v: Filter) => void;
}

export interface Filter extends CanBeQueriable {
  uuid: string;
  label: string;
  value: FilterValue;
  displayingFilterValue: DisplayingFilterValue[];
  displayFilterComponent: DisplayFilterComponent;
  component?: (p: FilterParams) => React.ReactElement | null;
  removeFilterValue: (DisplayingFilterValue) => void;
}

export enum FilterTypeEnum {
  geographyToInclude = 'geography_to_include',
  geographyToExclude = 'geography_to_exclude',
  searchKeyword = 'search_keyword',
  uploadTagsToInclude = 'upload_tags_to_include',
  interval = 'interval',
  intervalWithIcon = 'intervalWithIcon',
  website = 'website_id',
  websiteExclude = 'website_id_to_exclude',
  category = 'website_category_id',
  categoryExclude = 'website_category_id_to_exclude',
  postModerationStatus = 'post_moderation_status',
  imageModerationStatus = 'image_moderation_status',
  accountModerationStatus = 'account_moderation_status',
  uploadStatus = 'upload_status',
  uploadedBy = 'uploader_ids',
  uploadType = 'upload_type',
  moderationMethod = 'moderation_method',
  takenDown = 'taken_down',
  taskStatus = 'task_status',
  postLabel = 'status_type',
  imageLabel = 'hise',
  accountLabel = 'account_status_type',
  selectedAccountLabels = 'selected_account_labels',
  categoryId = 'category_id',
  labelTypeInclude = 'label_type_to_include',
  labelTypeExclude = 'label_type_to_exclude',
  postTagsInclude = 'post_tags_to_include',
  postTagsExclude = 'post_tags_to_exclude',
  postTagsIncludeSearch = 'post_tags_to_include_search',
  postTagsExcludeSearch = 'post_tags_to_exclude_search',
  accountTagsInclude = 'account_tags_to_include',
  accountTagsExclude = 'account_tags_to_exclude',
  accountTagsIncludeSearch = 'account_tags_to_include_search',
  accountTagsExcludeSearch = 'account_tags_to_exclude_search',
  duplicatedGroupTagsInclude = 'duplicated_group_tags_to_include',
  duplicatedGroupTagsExclude = 'duplicated_group_tags_to_exclude',
  duplicatedGroupTagsIncludeSearch = 'duplicated_group_tags_to_include_search',
  duplicatedGroupTagsExcludeSearch = 'duplicated_group_tags_to_exclude_search',
  vendorrTagsInclude = 'cluster_tags_to_include',
  vendorTagsExclude = 'cluster_tags_to_exclude',
  vendorrTagsIncludeSearch = 'cluster_tags_to_include_search',
  vendorTagsExcludeSearch = 'cluster_tags_to_exclude_search',
  featureLabelImage = 'featureLabelImage',
  featureLabelPost = 'featureLabelPost',
  minimumPrice = 'minimum_price',
  maximumPrice = 'maximum_price',
  minimumFollowersCount = 'minimum_followers_count',
  maximumFollowersCount = 'maximum_followers_count',
  maximumStock = 'minimum_stock',
  contactTypes = 'contact_types',
  minimumStock = 'maximum_stock',
  commetedBy = 'commented_by',
  moderatedBy = 'moderated_by',
  checkedBy = 'checked_by',
  validatedBy = 'validated_by',
}
