import styled from 'styled-components';

const padding = {
  extraSmall: { v: 4, h: 16 },
  small: { v: 8, h: 16 },
  medium: { v: 16, h: 24 },
  large: { v: 24, h: 48 },
};

const toPx = ({ v, h }: { v: number; h: number }) => `${v}px ${h}px`;

const StyledButton: any = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0.25em;
  text-transform: uppercase;
  border: 1px solid transparent;
  font-weight: 700;
  &.-extraSmall {
    padding: ${toPx(padding.extraSmall)};
    line-height: 2em;
    font-size: 12px;
  }
  &.-small {
    padding: ${toPx(padding.small)};
    line-height: 2em;
    font-size: 12px;
    height: 40px;
    &:not(.-radio) {
      letter-spacing: 2px;
    }
  }
  &.-medium {
    padding: ${toPx(padding.medium)};
    font-size: 14px;
    height: 48px;
    line-height: 24px;
    letter-spacing: 2px;
  }
  &.-large {
    padding: ${toPx(padding.large)};
    font-size: 16px;
  }
  &.-primary {
    color: var(--primary-black);
    background-color: var(--green-tints-green-5);
    &:hover:not(.-disabled),
    &:focus {
      background-color: var(--green-tints-green-4);
    }
    &:active {
      background-color: var(--green-tints-green-3);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--neutral-grey-300);
    }
  }
  &.-secondary {
    color: var(--primary-black);
    background-color: var(--secondary);
    &:hover:not(.-disabled),
    &:focus:not(.-disabled) {
      background-color: var(--primary-black);
      color: var(--primary-green);
    }
    &:active:not(.-disabled) {
      color: var(--primary-pressed);
      background-color: var(--green-tints-green-3);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--neutral-grey-300);
    }
  }
  &.-blacked {
    color: var(--white);
    background-color: var(--primary-black);
    &:hover:not(.-disabled) {
      color: var(--primary-green);
    }
    &:focus:not(.-disabled) {
      color: var(--primary-green);
      outline: 3px solid var(--neutral-grey-300);
    }
    &:active {
      background-color: var(--primary-pressed);
      color: var(--primary-black);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--neutral-grey-300);
      color: var(--neutral-grey-400);
    }
  }
  &.-outline {
    color: var(--primary-black);
    border: 1px solid var(--primary-black);
    background-color: transparent;
    &:hover:not(.-disabled),
    &:focus {
      background-color: var(--primary-light);
    }
    &:active {
      background-color: var(--green-tints-green-3);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--neutral-grey-300);
    }
  }
  &.-white {
    color: var(--primary-black);
    border: 1px solid var(--white);
    background-color: transparent;
    &:hover:not(.-disabled),
    &:focus {
      background-color: var(--primary-light);
      border: 1px solid var(--primary-light);
    }
    &:active {
      background-color: var(--green-tints-green-3);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--white);
    }
  }
  &.-white-secondary {
    color: var(--primary-black);
    border: 1px solid var(--white);
    background-color: transparent;
    &:hover:not(.-disabled),
    &:focus {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--neutral-grey-100);
    }
    &:active {
      background-color: var(--green-tints-green-3);
    }
    &.-disabled {
      background-color: var(--neutral-grey-100);
      border: 1px solid var(--white);
    }
  }
  &.-text {
    background-color: transparent;
    color: var(--dodger-blue);
  }
  &.-greyed {
    color: var(--primary-black);
    background-color: var(--neutral-grey-400);

    &:hover:not(.-disabled),
    &:focus {
      background-color: var(--neutral-grey-300);
    }
    &:focus {
      border: 1px solid var(--primary-focus);
    }
    &:active {
      background-color: var(--neutral-grey-200);
    }
    &.-disabled {
      border: 1px solid var(--neutral-grey-300);
      background-color: var(--neutral-grey-100);
      cursor: not-allowed;
    }
  }
  &.-red {
    background-color: var(--carnation);
    color: var(--white);
    &:hover:not(.-disabled) {
      color: var(--primary-black);
    }
    &:active {
      outline: 3px solid var(--primary-focus);
      color: var(--primary-black);
    }
  }
  &.-rounded-selected,
  &.-rounded {
    border-radius: 30px;
    border: 1.5px solid var(--primary-border);
    background: var(--primary-white);
    padding: 8px 16px;
    height: 36px;
    max-height: 36px;
    font-weight: 500;
    &:hover:not(.-disabled),
    &:focus {
      background: var(--green-tints-green-1);
    }
    &:active {
      background: var(--green-tints-green-3);
      border: 1px solid var(--green-tints-green-8);
    }
  }
  &.-rounded-selected {
    color: var(--primary-black);
    font-weight: 700;
    background-color: var(--green-tints-green-1);
  }
  &.-dashboard {
    height: 40px;
    padding: 8px 12px;
    border: 1px solid var(--primary-black);
    background: var(--primary-white);
  }
  &.-icon-only {
    &.-medium {
      padding: 16px;
    }
    &.-extraSmall {
      svg {
        width: 1.25em;
        height: 1.25em;
      }
    }
  }
  &.-radio {
    padding: 0.25rem 1rem;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-black);
    background-color: var(--primary-white);
    font-weight: 400;
    text-transform: none;
    white-space: nowrap;
    letter-spacing: 0;
    &:hover:not(.-disabled) {
      cursor: pointer;
      border-radius: 26px;
      background-color: var(--primary-hover);
    }
    &.-icon-text {
      div {
        margin-right: 0.25rem;
      }
    }
    &.-active {
      font-weight: 500;
      border-radius: 26px;
      border: 1px solid var(--black);
      background-color: transparent;
    }
  }
  &.-disabled {
    background-color: var(--neutral-grey-100);
    border: 1px solid var(--neutral-grey-300);
    cursor: not-allowed;
  }
`;

StyledButton.IconContainer = styled.div<{
  $hasLabel?: string;
  $hasIcon?: React.ReactNode;
}>`
  .-icon-text & {
    margin-right: ${({ $hasLabel, $hasIcon }) =>
      !$hasLabel && $hasIcon ? '0' : '1em'};
    height: 20px;
    display: flex;
    align-items: center;
  }
  .-icon-text & {
    margin-right: ${({ $hasLabel, $hasIcon }) =>
      !$hasLabel && $hasIcon ? '0' : '0.5em'};
  }
`;

// tslint:disable-next-line
StyledButton.IconAfterContainer = styled.div`
  margin-left: 0.5em;
`;

export { StyledButton };
