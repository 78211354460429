import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface DeleteIPAssetResponse {}
interface DeleteIPAssetRequest extends EndpointRequests {
  urlParams: {
    id: string;
  };
}

const deleteIPAsset = new Endpoint<DeleteIPAssetRequest, DeleteIPAssetResponse>(
  {
    url: '/api/me/ip_assets/:id',
    method: 'DELETE',
    headers: {},
  },
);

export default deleteIPAsset;
