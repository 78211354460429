import styled from 'styled-components';

export const PictureDiv = styled.div<{
  $loading?: boolean;
  $hasPhoto: boolean;
  $height?: string;
  $width?: string;
}>`
  text-align: ${({ $loading }) => ($loading ? 'left' : 'center')};
  position: relative;

  button,
  button:hover,
  button:focus {
    width: 100%;
    height: 40px;
    border-radius: 0;
    background-color: var(--mint-green-lighter10) !important;
    align-items: center;
    color: var(--black-mate);
    display: flex;
    justify-content: center;
    font-weight: 400;
    text-transform: capitalize;
    padding: 0.25rem 0 !important;
    transform: ${(props) =>
      props.$hasPhoto ? 'translate3d(0, 100%, 0)' : 'translate3d(0, 0, 0)'};
    transition: transform 0.3s;
    ${(props) => (props.$loading ? 'display: none;' : '')};
  }

  .pic-container {
    height: ${({ $height }) => $height || '120px'};
    width: ${({ $width }) => $width || '120px'};
    overflow: hidden;
    border-radius: 8px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: white;
    border: 1px solid var(--primary-border);
    ${(props) => (!props.$loading ? 'align-items: flex-end;' : '')};

    &:hover {
      button {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .ant-upload-wrapper {
    width: 100%;
  }

  .ant-upload.ant-upload-select {
    width: 100%;
  }
  .ant-form-item {
    width: 100%;
  }
  .ant-upload-list {
    display: none;
  }
  .ant-btn.ant-btn-loading {
    position: absolute;
  }
`;

export const SettingsPanelContainer = styled.div`
  display: flex;
  background: var(--primary-white);
  width: 100%;
  border-radius: 16px;
  padding: 2rem;
  & > div {
    flex-grow: 1;
  }
`;

export const InputWrapper = styled.div<{
  $width?: string;
  $showAsterisk?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: ${({ $width = '480px' }) => $width};
  max-width: 480px;
  min-width: 340px;
  & input {
    font-size: 16px;
    line-height: 24px;
    &:not(:placeholder-shown) {
      font-weight: 500;
    }
  }
  & .ant-select-disabled .ant-select-selection-item {
    font-weight: 400;
  }
  & label {
    font-size: 12px !important;
    line-height: 18px;
    font-weight: 700;
    height: auto !important;
    color: var(--neutral-grey-70) !important;
    letter-spacing: 0.24px;
  }
  & .ant-form-item-label {
    padding-bottom: 4px;
    font-size: 12px !important;
    line-height: 18px;
  }
  .form-fields-wrapper {
    padding: 36px;
    .ant-form-item-label {
      line-height: 30px;
    }
  }
  .has-error .ant-form-explain,
  .has-error .ant-form-split {
    height: 28px;
    border: solid 1px var(--carnation);
    background-color: var(--white);
    text-align: left;
    border-radius: 7px;
    margin-top: 12px;
    font-size: 12px;
    font-weight: 300;
    color: var(--salmon);
    display: inline-block;
    padding: 6px;
  }
  .has-error .ant-input {
    border: solid 2px var(--salmon);
    background-color: rgba(255, 114, 133, 0.16);
  }
  label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    ${({ $showAsterisk = true }) =>
      !$showAsterisk ? 'content: none !important;' : ''};
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-input-number-suffix {
    font-size: 16px;
    line-height: 24px;
    color: var(--neutral-grey-600);
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;
