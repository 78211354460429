import { EndpointContainer } from 'types/network/Http/endpoint';
import { HttpClient } from 'types/network/Http/client/client';
import add from './add';
import get from './get';
import update from './update';
import remove from './remove';

export interface OrganizationAccountLabelsEndpoints extends EndpointContainer {
  get: typeof get;
  add: typeof add;
  update: typeof update;
  remove: typeof remove;
}

const accountLabels: OrganizationAccountLabelsEndpoints = {
  get,
  add,
  update,
  remove,
  injectHTTPClient(client: HttpClient) {
    get.injectHTTPClient(client);
    add.injectHTTPClient(client);
    update.injectHTTPClient(client);
    remove.injectHTTPClient(client);
  },
};

export default accountLabels;
