import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { BrandModel } from 'product-types/src/domain/ipCertificates/BrandModel';

interface CreateOrganisationRequestModel extends EndpointRequests {
  data: {
    name: string;
    legal_name: string;
    logo_url: string;
    currency: string;
    organisation_status: string;
    brands: Array<BrandModel>;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface CreateOrganisationResponse {}

const createOrganisation = new Endpoint<
  CreateOrganisationRequestModel,
  CreateOrganisationResponse
>({
  url: '/api/organisation/',
  method: 'POST',
  headers: {},
});

export default createOrganisation;
