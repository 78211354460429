/*
 *
 * SettingsPage constants
 *
 */

export const CHANGE_EMAIL = 'app/SettingsPage/CHANGE_EMAIL';
export const CHANGE_NAME = 'app/SettingsPage/CHANGE_NAME';
export const CHANGE_NEW_PASSWORD = 'app/SettingsPage/CHANGE_NEW_PASSWORD';
export const CHANGE_NEW_PASSWORD_CONFIRM =
  'app/SettingsPage/CHANGE_NEW_PASSWORD_CONFIRM';
export const CHANGE_EMAIL_FREQUENCY = 'app/SettingsPage/CHANGE_EMAIL_FREQUENCY';
export const CHANGE_EMAIL_WHEN_NEW_COUNTERFEIT =
  'app/SettingsPage/CHANGE_EMAIL_WHEN_NEW_COUNTERFEIT';
export const PROFILE_UPDATE = 'app/SettingsPage/PROFILE_UPDATE';
export const UPDATE_DEFAULT_DATE_RANGE =
  'app/SettingsPage/UPDATE_DEFAULT_DATE_RANGE';
export const PROFILE_PICTURE_UPDATE = 'app/SettingsPage/PROFILE_PICTURE_UPDATE';
export const UPDATE_ORGANIZATION = 'app/SettingsPage/UPDATE_ORGANIZATION';
export const UPDATE_TUTORIAL_MODE = 'app/SettingsPage/UPDATE_TUTORIAL_MODE';
export const UPDATE_EMAIL_IF_NOTIFIED_IN_COMMENTS =
  'app/SettingsPage/UPDATE_EMAIL_IF_NOTIFIED_IN_COMMENTS';
export const LOAD_PRODUCT_CATEGORIES =
  'app/SettingsPage/LOAD_PRODUCT_CATEGORIES';
export const UPDATE_PRODUCT_CATEGORIES_DATA =
  'app/SettingsPage/UPDATE_PRODUCT_CATEGORIES_DATA';
export const UPDATE_PRODUCT_CATEGORIES =
  'app/SettingsPage/UPDATE_PRODUCT_CATEGORIES';
export const UPDATE_SELECTED_USER_PROFILE =
  'app/SettingsPage/UPDATE_SELECTED_USER_PROFILE';
export const LOAD_USER_PROFILE = 'app/SettingsPage/LOAD_USER_PROFILE';
export const LOAD_USER_STATS = 'app/SettingsPage/LOAD_USER_STATS';
export const UPDATE_USER_STATS = 'app/SettingsPage/UPDATE_USER_STATS';
export const LOAD_NAVEE_USERS = 'app/SettingsPage/LOAD_NAVEE_USERS';
export const UPDATE_NAVEE_USERS = 'app/SettingsPage/UPDATE_NAVEE_USERS';
export const INVITE_USER = 'app/SettingsPage/INVITE_USER';
export const UPDATE_INVITATION = 'app/SettingsPage/UPDATE_INVITATION';
export const UPDATE_USER = 'app/SettingsPage/UPDATE_USER';
export const REMOVE_USER = 'app/SettingsPage/REMOVE_USER';
export const UPDATE_USER_REMOVING = 'app/SettingsPage/UPDATE_USER_REMOVING';
export const UPDATE_UPLOADING_DOCUMENT =
  'app/SettingsPage/UPDATE_UPLOADING_DOCUMENT';
export const UPLOAD_COMPLIANCE_DOCUMENT =
  'app/SettingsPage/UPLOAD_COMPLIANCE_DOCUMENT';
export const UPDATE_COMPLIANCE_DOCUMENT =
  'app/SettingsPage/UPDATE_COMPLIANCE_DOCUMENT';
export const UPDATE_COMPLIANCE_DOCUMENTS =
  'app/SettingsPage/UPDATE_COMPLIANCE_DOCUMENTS';
export const LOAD_COMPLIANCE_DOCUMENTS =
  'app/SettingsPage/LOAD_COMPLIANCE_DOCUMENTS';
export const UPDATE_SIDEBARNAV_OPEN = 'app/SettingsPage/UPDATE_SIDEBARNAV_OPEN';
