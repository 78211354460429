import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteGeographyRequest extends EndpointRequests {
  urlParams: { id: string };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface DeleteGeographyResponse {}

const deleteGeography = new Endpoint<
  DeleteGeographyRequest,
  DeleteGeographyResponse
>({
  url: '/api/me/geography/account/:id',
  method: 'DELETE',
  headers: {},
});

export default deleteGeography;
