import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountModerationRawModel } from 'types/src/domain/account/AccountModerationModel';

interface PrevWebsiteToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface PrevWebsiteToModerateResponse {
  account_id: string;
  account_moderation_index: number;
  number_of_accounts_to_moderate: number;
  account: AccountModerationRawModel;
}

const prevWebsiteToModerate = new Endpoint<
  PrevWebsiteToModerateRequest,
  PrevWebsiteToModerateResponse
>({
  url: '/api/me/accounts_moderation/previous_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default prevWebsiteToModerate;
