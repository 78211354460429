import { Endpoint } from 'types/network/Http/endpoint';
import { ModerationReasonRaw } from 'product-types/src/domain/moderationReason/ModerationReason';

export interface GetAllResponseModel {
  image_features: Array<ModerationReasonRaw>;
}

const getAll = new Endpoint<object, GetAllResponseModel>({
  url: 'api/me/image_features',
  method: 'get',
  headers: {},
});

export default getAll;
