import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface BulkDeleteIPAssetResponse {}

interface BulkDeleteIPAssetRequest extends EndpointRequests {
  data: {
    ids: Array<number>;
  };
}

const bulkDeleteIPAssets = new Endpoint<
  BulkDeleteIPAssetRequest,
  BulkDeleteIPAssetResponse
>({
  url: '/api/me/ip_assets/bulk-delete',
  method: 'DELETE',
  headers: {},
});

export default bulkDeleteIPAssets;
