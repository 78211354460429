import { push, replace } from 'redux-first-history';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/storeAccess';

export const buildALinkWithOrgId = (orgId: string, path: string) =>
  `/${orgId}${path.startsWith('/') ? '' : '/'}${path}`;

export default function useNavigation() {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (st: AppState) => st.loginPage?.currentUser?.data,
  );
  const state = useSelector((st: AppState) => st.loginPage?.currentUser?.state);
  const navigate = useCallback(
    (path: string, options: { replace: boolean } = { replace: false }) => {
      if (!currentUser?.organisation?.uid)
        throw new Error('No organisation id');
      const organisationIdFromLink = path.split('/')[1];

      const { replace: shouldReplace } = options;
      const navState = { navigation: true };
      if (organisationIdFromLink.length !== 3) {
        if (path === '/') {
          const url = buildALinkWithOrgId(
            currentUser?.organisation?.uid,
            '/post',
          );
          if (shouldReplace) {
            dispatch(replace(url, navState));
          } else {
            dispatch(push(url, navState));
          }
          return;
        }

        const url = buildALinkWithOrgId(currentUser?.organisation?.uid, path);
        if (shouldReplace) {
          dispatch(replace(url, navState));
        } else {
          dispatch(push(url, navState));
        }
      } else {
        if (shouldReplace) {
          dispatch(replace(path, navState));
          return;
        }
        dispatch(push(path, navState));
      }
    },
    [dispatch, currentUser],
  );

  const calcLink = useCallback(
    (path: string) => {
      if (!currentUser?.organisation?.uid) return '';
      return buildALinkWithOrgId(currentUser?.organisation?.uid, path);
    },
    [state, currentUser],
  );

  return {
    navigate,
    calcLink,
    canCalcLink: !!currentUser?.organisation?.uid,
  };
}
