export type TaskCategory = {
  label: string;
  value: number;
};

export type TaskType = {
  label: string;
  value: number;
};

export type TaskStatusOption = {
  label: string;
  value: TaskStatus;
};

export enum TaskStatus {
  IN_PROGRESS = 'in_progress',
  NOT_CONFIGURED = 'not_configured',
  COMPLETED = 'completed',
}

export type FeatureDetectionTaskListModel = {
  feature_detection_tasks: Array<FeatureDetectionTaskRaw>;
};

export type FeatureDetectionTaskRaw = {
  id: number;
  name: string;
  image_feature_category_id: TaskCategory;
  // task_size: TaskType;
  due_date: string;
  assignee_ids: Array<number>;
  status: TaskStatus;
};

export type TaskCompletion = {
  completed: number;
  total: number;
};

export const TaskStatusDictionary: TaskStatusOption[] = [
  {
    label: 'Completed',
    value: TaskStatus.COMPLETED,
  },
  {
    label: 'In Progress',
    value: TaskStatus.IN_PROGRESS,
  },
  {
    label: 'Not Configured',
    value: TaskStatus.NOT_CONFIGURED,
  },
];

export const GetTaskStatusFilterOptionByValue = (value: string) =>
  TaskStatusDictionary.find((status) => `${status.value}` === value);
