import React, { useCallback } from 'react';
import { Flex } from 'antd';
import { TaskStatusFilter } from 'types/filters/AtomicFiltersImplementation/TaskStatus/TaskStatusFilter';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { Task } from 'product-types/src/domain/Domain';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import {
  TaskStatusDictionary,
  TaskStatusOption,
} from 'product-types/src/domain/task';
import { Filter } from '../../../types/filters/AtomicFilters/Filter';
import FilterWithMenuWrapper from '../FilterWithMenuWrapper';

export interface NewTaskStatusFilterProps {
  value: TaskStatusFilter;
  onChange: (v: Filter) => void;
}

const NewTaskStatusFilter = (props: NewTaskStatusFilterProps) => {
  const onSelectFilter = useCallback(
    (checked: boolean, value: TaskStatusOption) => {
      props.onChange(
        new TaskStatusFilter({
          ...props.value,
          value: checked
            ? props.value.value.addTaskStatus(value)
            : props.value.value.removeTaskStatus(value),
        }),
      );
    },
    [props.value.value],
  );

  const renderer = useCallback(
    () => (
      <Flex
        vertical
        style={{
          padding: '1rem 0.25rem',
          backgroundColor: 'white',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.039)',
          width: 244,
          minHeight: 60,
        }}
      >
        {TaskStatusDictionary.map((option: Task.TaskStatusOption) => (
          <Flex
            key={option.value}
            style={{
              padding: '0.25rem 0.75rem',
            }}
          >
            <StyledCheckbox>
              <Checkbox
                onChange={(checked) => onSelectFilter(checked, option)}
                id={option.value}
                checked={props.value.value.status.some(
                  (status) => status.value === option.value,
                )}
              >
                {option.label}
              </Checkbox>
            </StyledCheckbox>
          </Flex>
        ))}
      </Flex>
    ),
    [props.value.value, onSelectFilter],
  );

  return (
    <FilterWithMenuWrapper
      text="Status"
      badgeText={
        props.value.displayingFilterValue.length
          ? props.value.displayingFilterValue.length
          : undefined
      }
      renderer={renderer}
    />
  );
};

export default NewTaskStatusFilter;
