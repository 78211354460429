import React from 'react';
import { Divider, Flex } from 'antd';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Typography from 'product-ui/src/components/atoms/Typography';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../containers/LoginPage/actions';
import UserMenuContent from './UserMenuContent';
import Organisation from './Organisation';

export default function UserMenuPopover({ visible }: { visible: boolean }) {
  const dispatch = useDispatch();

  return (
    <Flex
      vertical
      style={{
        width: '327px',
      }}
      gap="2px"
    >
      {visible && (
        <>
          <Flex
            style={{
              padding: '1rem 1.5rem',
            }}
          >
            <UserMenuContent collapsed={false} />
          </Flex>
          <Divider style={{ margin: 0 }} />
          <Organisation />
          <Divider style={{ margin: 0 }} />
          <Flex
            style={{
              padding: '1rem 1.5rem',
              color: 'var(--custom-red)',
              cursor: 'pointer',
            }}
            onClick={() => dispatch(logout())}
            align="center"
            gap="0.5rem"
          >
            <NaveeIcon.Logout fill="var(--custom-red)" />
            <Typography
              textTransform="uppercase"
              variant="xsmall"
              fontWeight="medium"
            >
              Logout
            </Typography>
          </Flex>
        </>
      )}
    </Flex>
  );
}
