import { IPAssetRaw } from 'product-types/src/domain/ipAsset/IPAssetModel';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface GetIPAssetsResponse {
  ip_assets: Array<IPAssetRaw>;
}

const getIPAssets = new Endpoint<EndpointRequests, GetIPAssetsResponse>({
  url: '/api/me/ip_assets',
  method: 'GET',
  headers: {},
});

export default getIPAssets;
