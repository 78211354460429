import dayjs from 'dayjs';
import { TakedownStatus } from './takedownStatus';

export interface WebsiteStatusRawModel {
  status_observations_consecutive_count: number;
  status_observed_last_at: string | null;
  status: TakedownStatus['value'] | null;
}

export class WebsiteStatusModel {
  statusObservedLastAt: null | dayjs.Dayjs;

  statusObservationsConsecutiveCount: number;

  status: TakedownStatus['value'] | null;

  constructor(
    props: Pick<
      WebsiteStatusModel,
      'statusObservedLastAt' | 'statusObservationsConsecutiveCount' | 'status'
    >,
  ) {
    this.statusObservedLastAt = props.statusObservedLastAt;
    this.statusObservationsConsecutiveCount =
      props.statusObservationsConsecutiveCount;
    this.status = props.status;
  }

  static createFromTakedownRawModel(props: WebsiteStatusRawModel) {
    return new WebsiteStatusModel({
      statusObservationsConsecutiveCount:
        props.status_observations_consecutive_count,
      statusObservedLastAt: dayjs(props.status_observed_last_at).isValid()
        ? dayjs(props.status_observed_last_at)
        : null,
      status: props.status,
    });
  }

  static createEmpty(): WebsiteStatusModel {
    return new WebsiteStatusModel({
      statusObservationsConsecutiveCount: 0,
      statusObservedLastAt: null,
      status: 'UNKNOWN',
    });
  }
}
