import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export interface CountriesResponseModel {
  arcs: Array<NonNullable<unknown>>;
  bbox: Array<number>;
  objects: {
    countries: {
      geometries: Array<NonNullable<unknown>>;
      type: string;
    };
    land: {
      geometries: Array<NonNullable<unknown>>;
      type: string;
    };
  };
  transform: {
    scale: Array<NonNullable<unknown>>;
    translate: Array<NonNullable<unknown>>;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface CountriesRequestModel extends EndpointRequests {}

const countries = new Endpoint<CountriesRequestModel, CountriesResponseModel>({
  url: '/chart/countries',
  method: 'GET',
  headers: {},
});

export default countries;
