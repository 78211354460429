import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountAllRelatedIdsResponseModel } from 'product-types/src/common/FeedGeneric/FeedGeneric';
import { FeedRequestParameter } from 'types/network/Feed/Feed';

export interface AccountFeedRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

const getAllRelatedAccountIds = new Endpoint<
  AccountFeedRequestModel,
  AccountAllRelatedIdsResponseModel
>({
  url: '/api/me/accounts/first_thousand_account_ids',
  method: 'POST',
  headers: {},
});

export default getAllRelatedAccountIds;
