import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)<{
  $showSelectAll?: boolean;
}>`
  .ant-select-selection-item {
    margin-right: 0.5rem !important;
  }

  .ant-select-selection-overflow {
    max-width: ${(props) => (props.$showSelectAll ? '90%' : '100%')} !important;
  }

  .ant-select-arrow {
    pointer-events: initial !important;
  }
  &.ant-select-single .ant-select-selection-item {
    text-align: left;
    font-weight: 500;
  }
  &.ant-select {
    .ant-select-arrow svg {
      transition: transform 0.3s;
    }

    &.ant-select-open {
      .ant-select-selection-placeholder {
        color: var(--primary-black);
      }
      .ant-select-arrow svg {
        fill: var(--primary-black);
        transform: rotate(180deg);
      }
    }

    .ant-select-selection-item {
      font-weight: 500;
    }
  }

  .ant-select-selection-placeholder {
    text-align: left;
    font-weight: 500;
    svg {
      transition: fill 0.3s;
      fill: rgba(0, 0, 0, 0.25);
    }
  }

  &.ant-select-multiple .ant-select-selector {
    padding-inline: 0.5rem;
  }

  &.ant-select-single.ant-select-open .ant-select-selection-item {
    color: var(--primary-black);
  }

  &.ant-select-multiple .ant-select-selection-item {
    height: 28px;
    line-height: 25px;
  }

  &:hover:not(.ant-select-disabled) {
    .ant-select-selection-placeholder {
      color: var(--primary-black);
    }
  }
`;
