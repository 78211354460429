import dayjs from 'dayjs';
import { ModerationReasonLocation } from './ModerationReasonLocation';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';

export enum SuspiciousPriority {
  info = 'INFO',
  logo = 'LOGO',
  suspicious = 'SUSPICIOUS',
  obviousCounterfeit = 'OBVIOUS_COUNTERFEIT',
  highlySuspicious = 'HIGHLY_SUSPICIOUS',
}

export enum ModerationReasonLevel {
  L2 = 'L2',
  L3 = 'L3',
}

export type GroupedReasons = {
  [SuspiciousPriority.info]: Array<ModerationReason>;
  [SuspiciousPriority.logo]: Array<ModerationReason>;
  [SuspiciousPriority.suspicious]: Array<ModerationReason>;
  [SuspiciousPriority.obviousCounterfeit]: Array<ModerationReason>;
  [SuspiciousPriority.highlySuspicious]: Array<ModerationReason>;
};

export interface ModerationReasonRaw {
  id: number | null;
  moderation_reason_id: number;
  path: string;
  name: string;
  type: string;
  value: boolean | null;
  comment: null | string;
  color: string;
  original_image_url: string;
  original_image_id: number | null;
  priority: SuspiciousPriority;
  location: string;
  automatically_generated: boolean;
  check_date: null | string;
  checked: null | boolean;
  checked_by_user_id: null | number;
  found_in_image_id: null | number;
  found_in_post_id: null | number;
  task_id: null | number;
  image_feature_level: ModerationReasonLevel | null;
  image_feature_description: string;
  image_url: string;
  linked_children_image_feature_ids?: Array<number>;
  linked_parent_image_feature_ids?: Array<number>;
  polygon_points: string | null;
}

export class ModerationReason implements Magnifiable {
  id: number | null;

  moderationReasonId: number;

  path: string;

  name: string;

  type: string;

  value: boolean | null;

  comment: string | null;

  color: string;

  originalImageUrl: string | null;

  originalImageId: number | null;

  originalPostId: number | null;

  location: ModerationReasonLocation | null;

  checked: boolean | null;

  checkedUserId: number | null;

  checkDate: dayjs.Dayjs | null;

  priority: SuspiciousPriority;

  automaticallyGenerated: boolean;

  taskId: number | null;

  imageFeatureLevel: ModerationReasonLevel | null;

  imageFeatureDescription: string;

  imageUrl: string;

  linkedChildrenImageFeaturesIds: Array<number>;

  linkedParentImageFeaturesIds: Array<number>;

  lastPathPart: string;

  constructor(
    prop: Pick<
      ModerationReason,
      | 'id'
      | 'path'
      | 'name'
      | 'color'
      | 'comment'
      | 'originalImageId'
      | 'originalImageUrl'
      | 'location'
      | 'type'
      | 'value'
      | 'checked'
      | 'checkedUserId'
      | 'checkDate'
      | 'automaticallyGenerated'
      | 'originalPostId'
      | 'priority'
      | 'moderationReasonId'
      | 'lastPathPart'
      | 'imageFeatureDescription'
      | 'imageFeatureLevel'
      | 'imageUrl'
      | 'linkedChildrenImageFeaturesIds'
      | 'linkedParentImageFeaturesIds'
      | 'taskId'
    >,
  ) {
    this.id = prop.id;
    this.path = prop.path;
    this.name = prop.name;
    this.value = prop.value;
    this.type = prop.type;
    this.comment = prop.comment;
    this.color = prop.color;
    this.originalImageUrl = prop.originalImageUrl;
    this.originalImageId = prop.originalImageId;
    this.originalPostId = prop.originalPostId;
    this.location = prop.location;
    this.checked = prop.checked;
    this.checkedUserId = prop.checkedUserId;
    this.checkDate = prop.checkDate;
    this.automaticallyGenerated = prop.automaticallyGenerated;
    this.priority = prop.priority;
    this.moderationReasonId = prop.moderationReasonId;
    this.lastPathPart = prop.lastPathPart;
    this.automaticallyGenerated = prop.automaticallyGenerated;
    this.taskId = prop.taskId;
    this.imageFeatureLevel = prop.imageFeatureLevel;
    this.imageFeatureDescription = prop.imageFeatureDescription;
    this.linkedParentImageFeaturesIds = prop.linkedParentImageFeaturesIds;
    this.imageUrl =
      prop.imageUrl ||
      'https://s3-eu-west-1.amazonaws.com/counterfeit-platform/staging/images/37bc217d-245f-46aa-929d-aed76f39f873.jpg';
    this.linkedChildrenImageFeaturesIds = prop.linkedChildrenImageFeaturesIds;
  }

  get suggestedByAI() {
    return this.automaticallyGenerated && !this.checked && !this.originalPostId;
  }

  get midSizeImage() {
    return this.imageUrl;
  }

  static createReasonFromRaw(prop: ModerationReasonRaw) {
    return new ModerationReason({
      id: prop.id,
      path: prop.path,
      color: prop.color,
      comment: prop.comment,
      location:
        (prop.location &&
          ModerationReasonLocation.staticCreateFromString(prop.location)) ||
        null,
      name: prop.name,
      originalImageId: prop.found_in_image_id,
      originalImageUrl: prop.original_image_url,
      originalPostId: prop.found_in_post_id,
      type: prop.type,
      value: prop.value,
      checked: prop.checked,
      checkedUserId: prop.checked_by_user_id,
      checkDate: prop.check_date ? dayjs(prop.check_date) : null,
      automaticallyGenerated: prop.automatically_generated,
      priority: prop.priority,
      moderationReasonId: prop.moderation_reason_id,
      lastPathPart: prop.path.split('/').pop() ?? '',
      imageFeatureDescription: prop.image_feature_description,
      imageFeatureLevel: prop.image_feature_level,
      imageUrl: prop.image_url,
      linkedChildrenImageFeaturesIds:
        prop.linked_children_image_feature_ids ?? [],
      linkedParentImageFeaturesIds: prop.linked_parent_image_feature_ids ?? [],
      taskId: prop.task_id,
    });
  }

  get isConfigured() {
    return (
      this.imageFeatureLevel === ModerationReasonLevel.L2 ||
      this.imageFeatureLevel === ModerationReasonLevel.L3
    );
  }

  static createDefaultModerationReason() {
    return new ModerationReason({
      automaticallyGenerated: false,
      checkDate: null,
      checked: false,
      checkedUserId: null,
      color: '#000000',
      comment: '',
      id: null,
      imageFeatureDescription: '',
      imageFeatureLevel: ModerationReasonLevel.L3,
      imageUrl: '',
      lastPathPart: '',
      linkedChildrenImageFeaturesIds: [],
      linkedParentImageFeaturesIds: [],
      location: null,
      moderationReasonId: 0,
      name: '',
      originalImageId: null,
      originalImageUrl: '',
      originalPostId: null,
      path: '',
      priority: SuspiciousPriority.info,
      taskId: null,
      type: '',
      value: null,
    });
  }

  static groupReasonsByPriority(reasons: Array<ModerationReason>) {
    const priorityOrder = [
      'OBVIOUS_COUNTERFEIT',
      'COUNTERFEIT',
      'HIGHLY_SUSPICIOUS',
      'SUSPICIOUS',
      'LOGO',
      'BRAND',
      'PRODUCT_INFORMATION',
      'INFO',
      'OTHER',
    ];
    const sortedReasons = reasons.sort((a, b) => {
      const priorityA = priorityOrder.indexOf(a.priority);
      const priorityB = priorityOrder.indexOf(b.priority);

      if (priorityA !== -1 && priorityB !== -1) {
        return priorityA - priorityB;
      }

      if (priorityA !== -1) return -1;
      if (priorityB !== -1) return 1;

      return 0;
    });
    return sortedReasons.reduce((acc, curr) => {
      if (!Object.prototype.hasOwnProperty.call(acc, curr.priority)) {
        acc[curr.priority] = [];
      }
      acc[curr.priority] = [...acc[curr.priority], curr];
      return acc;
    }, {} as GroupedReasons);
  }

  toRaw(): ModerationReasonRaw {
    return {
      id: this.id,
      automatically_generated: this.automaticallyGenerated,
      checked: this.checked,
      checked_by_user_id: this.checkedUserId,
      check_date: this.checkDate?.toISOString() ?? null,
      color: this.color,
      comment: this.comment,
      found_in_image_id: this.originalImageId,
      found_in_post_id: this.originalPostId,
      image_feature_description: this.imageFeatureDescription,
      image_feature_level: this.imageFeatureLevel,
      image_url: this.imageUrl,
      location: this.location?.toString() ?? '',
      moderation_reason_id: this.moderationReasonId,
      name: this.name,
      original_image_id: this.originalImageId,
      original_image_url: this.originalImageUrl ?? '',
      path: this.path,
      polygon_points: null,
      priority: this.priority,
      task_id: this.taskId,
      type: this.type,
      value: this.value,
      linked_children_image_feature_ids: this.linkedChildrenImageFeaturesIds,
      linked_parent_image_feature_ids: this.linkedParentImageFeaturesIds,
    };
  }
}

export const moderationReasonSort = (
  a: ModerationReason,
  b: ModerationReason,
) => {
  if (a.automaticallyGenerated && !a.checked && !b.automaticallyGenerated) {
    return -1;
  }
  if (!a.automaticallyGenerated && b.automaticallyGenerated && !b.checked) {
    return 1;
  }
  return 0;
};
