import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../../Feed/Feed';

interface GetNextPostToModerateRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GetNextPostToModerateResponse {}

const nextPostToModerate = new Endpoint<
  GetNextPostToModerateRequestModel,
  GetNextPostToModerateResponse
>({
  url: '/api/me/post_moderation/next',
  method: 'POST',
  headers: {},
});

export default nextPostToModerate;
