import { LabelSaveModel } from 'product-types/src/domain/label/Label';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface SaveAccountLabelRequestModel extends EndpointRequests {
  data: LabelSaveModel & { is_infringement: boolean };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface SaveAccountLabelResponse {}

const saveAccountLabel = new Endpoint<
  SaveAccountLabelRequestModel,
  SaveAccountLabelResponse
>({
  url: '/api/me/organisation/labels/account',
  method: 'POST',
  headers: {},
});

export default saveAccountLabel;
