import { FeedRequestParameter } from 'types/network/Feed/Feed';
import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { AccountModerationRawModel } from 'types/src/domain/account/AccountModerationModel';

interface PrevAccountToModerateRequest extends EndpointRequests {
  data: FeedRequestParameter;
}

interface PrevAccountToModerateResponse {
  account_id: string;
  account_moderation_index: number;
  number_of_accounts_to_moderate: number;
  account: AccountModerationRawModel;
}

const prevAccountToModerate = new Endpoint<
  PrevAccountToModerateRequest,
  PrevAccountToModerateResponse
>({
  url: '/api/me/accounts_moderation/previous_account_to_moderate',
  method: 'POST',
  headers: {},
});

export default prevAccountToModerate;
