import dayjs, { Dayjs } from 'dayjs';
import { CanBeSelectable } from '../../common/CanBeSelectable/CanBeSelectable';
import { Geographic } from '../geo/Geo';

export interface CrawlingDomainRaw {
  website_id: number;
  tracker_id: number;
  domain_name: string;
  is_tracked: boolean;
  is_scrapable: boolean;
  is_enforceable: boolean;
  is_searchable: boolean;
  country_code: string;
  website_category_name: string;
  tier: number;
  update_time: string | null;
}

export class CrawlingDomain implements CanBeSelectable {
  id: number;

  trackerId: number;

  domainName: string;

  isTracked: boolean;

  isTrackable: boolean;

  isSearchable: boolean;

  isEnforceable: boolean;

  countryCode: string;

  countryName?: string;

  websiteCategoryName: string;

  tier: number;

  updateTime: Dayjs | null;

  constructor(
    prop: Pick<
      CrawlingDomain,
      | 'id'
      | 'domainName'
      | 'isTracked'
      | 'trackerId'
      | 'isTrackable'
      | 'isSearchable'
      | 'isEnforceable'
      | 'countryCode'
      | 'countryName'
      | 'websiteCategoryName'
      | 'tier'
      | 'updateTime'
    >,
  ) {
    this.id = prop.id;
    this.domainName = prop.domainName;
    this.isTracked = prop.isTracked;
    this.isEnforceable = prop.isEnforceable;
    this.countryCode = prop.countryCode;
    this.countryName = prop.countryName;
    this.trackerId = prop.trackerId;
    this.isTrackable = prop.isTrackable;
    this.isSearchable = prop.isSearchable;
    this.websiteCategoryName = prop.websiteCategoryName;
    this.tier = prop.tier;
    this.updateTime = prop.updateTime;
  }

  static createFromRawModel(
    props: CrawlingDomainRaw,
    countryMap: { [key: string]: Geographic },
  ) {
    return new CrawlingDomain({
      id: props.website_id,
      domainName: props.domain_name,
      isTracked: props.is_tracked,
      countryCode: props.country_code,
      isEnforceable: props.is_enforceable,
      countryName: countryMap[props.country_code]?.label ?? undefined,
      trackerId: props.tracker_id,
      isTrackable: props.is_scrapable,
      isSearchable: props.is_searchable,
      websiteCategoryName: props.website_category_name,
      tier: props.tier,
      updateTime: props.update_time ? dayjs(props.update_time) : null,
    });
  }

  toRawModel(): Omit<
    CrawlingDomainRaw,
    | 'is_scrapable'
    | 'is_searchable'
    | 'country_code'
    | 'update_time'
    | 'website_category_name'
  > {
    return {
      tracker_id: this.trackerId,
      tier: this.tier,
      website_id: this.id,
      domain_name: this.domainName,
      is_tracked: this.isTracked,
    };
  }

  toRawFullRawModel(): CrawlingDomainRaw {
    return {
      tracker_id: this.trackerId,
      tier: this.tier,
      website_id: this.id,
      domain_name: this.domainName,
      is_tracked: this.isTracked,
      is_scrapable: this.isTrackable,
      is_enforceable: this.isEnforceable,
      is_searchable: this.isSearchable,
      country_code: this.countryCode,
      website_category_name: this.websiteCategoryName,
      update_time: this.updateTime?.toISOString() ?? null,
    };
  }
}
