import { ExportFieldModel } from './ExportField';

export interface ExportTemplateRawModel {
  name: string;
  display_name: string;
  configurable?: boolean;
  default_columns_for_export?: ExportFieldModel[] | null;
  optional_fields_to_export?: ExportFieldModel[] | null;
}

export class ExportTemplateModel {
  value: string;

  name: string;

  configurable: boolean;

  default_columns_for_export: ExportFieldModel[];

  optional_fields_to_export: ExportFieldModel[];

  constructor(props: ExportTemplateModel) {
    this.value = props.value;
    this.name = props.name;
    this.configurable = props.configurable;
    this.default_columns_for_export = props.default_columns_for_export;
    this.optional_fields_to_export = props.optional_fields_to_export;
  }

  static createFromRaw(raw: ExportTemplateRawModel): ExportTemplateModel {
    return new ExportTemplateModel({
      name: raw.display_name,
      value: raw.name,
      configurable: raw.configurable ?? false,
      default_columns_for_export: raw.default_columns_for_export ?? [],
      optional_fields_to_export: raw.optional_fields_to_export ?? [],
    });
  }

  static sortWithRowPerPostFirst(
    a: ExportTemplateModel,
    b: ExportTemplateModel,
  ) {
    if (a.value === 'default') {
      return -1;
    }
    if (b.value === 'default') {
      return 1;
    }
    return 0;
  }
}
