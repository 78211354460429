import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ModerationTaskConfigModelRaw } from 'product-types/src/domain/task/ModerationTaskConfigModel';

interface ModerationTaskRequestModel extends EndpointRequests {
  urlParams: {
    taskId: string;
    configId: string;
  };
}

const deleteModerationTaskConfig = new Endpoint<
  ModerationTaskRequestModel,
  ModerationTaskConfigModelRaw
>({
  url: 'api/me/feature_detection_tasks/:taskId/configs/:configId',
  method: 'DELETE',
  headers: {},
});

export default deleteModerationTaskConfig;
