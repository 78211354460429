import React from 'react';
import { Flex, Space } from 'antd';
import Checkbox from 'product-ui/src/components/atoms/Checkbox/Checkbox';
import { StyledCheckbox } from 'product-ui/src/components/atoms/Checkbox/StyledCheckbox';
import { ImageFeatureStatus } from 'types/filters/AtomicFiltersImplementation/ImageFeatureStatus/ImageFeatureStatusFilterValue';

interface ImageFeatureStatusOptionProps {
  value: ImageFeatureStatus;
  selected: boolean;
  onClick: (newValue: ImageFeatureStatus) => void;
}

export const ImageFeatureStatusOption = ({
  value,
  selected,
  onClick,
}: ImageFeatureStatusOptionProps) => (
  <Flex
    aria-label={value.label}
    onClick={() => onClick(value)}
    gap="0.5rem"
    align="center"
    justify="flex-start"
    style={{
      cursor: 'pointer',
      padding: '0.25rem 0.75rem',
    }}
  >
    <StyledCheckbox>
      <Checkbox className="checkbox" checked={selected} />
    </StyledCheckbox>
    <Space>{value.label}</Space>
  </Flex>
);
