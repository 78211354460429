import {
  appendNonTrackedWebsites,
  replaceFeedFilter,
} from 'layout/FiltersBar/actions';
import { SavedFilterModel } from 'product-types/src/domain/savedFilters/SavedFilters';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Domain from 'product-types/src/domain/Domain';
import { AppState } from 'store/storeAccess';
import ProductMonitor from 'types/network/Http/productMonitor';
import { FiltersContainerState } from 'types/filters/MoleculesFilter/MolecileFilter';

export const useApplyFilter = (): [
  (fil: SavedFilterModel) => Promise<void>,
] => {
  const feed = useSelector((state: AppState) => state.filters_bar.feed);
  const {
    availableLabels,
    availableImageLabels,
    accountLabels,
    availableCategories,
    availableContactTypes,
    availableWebsiteCategories,
    featureLabels,
    users,
    insightOptions,
    geographySelectOptions,
    tags,
    websites,
    takedown,
  } = useSelector((state: AppState) => state.filters_bar?.optionsLoadedData);

  const dispatch = useDispatch();

  const fetchNonTrackedWebsites = useCallback(
    async (websiteIds: Array<string>) => {
      const nonTrackedWebsiteIds = websiteIds.filter(
        (websiteId) =>
          !(websites.data ?? []).some(({ id }) => id === websiteId),
      );
      let nonTrackedWebsites: Array<WebsiteSuggestion> = [];
      if (nonTrackedWebsiteIds.length) {
        try {
          const { results } =
            await ProductMonitor.endpoints.me.websiteNames.call({
              params: { ids: nonTrackedWebsiteIds },
            });
          nonTrackedWebsites = results.map((website) => ({
            label: website.label,
            id: website.value as string,
          }));
          dispatch(appendNonTrackedWebsites({ websites: nonTrackedWebsites }));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error loading non tracked websites', e);
        }
      }
      return nonTrackedWebsites;
    },
    [websites],
  );

  const applyFilter = useCallback(
    async (newFilter: SavedFilterModel) => {
      const nonTrackedWebsites = await fetchNonTrackedWebsites(
        (newFilter.websiteId ?? []).concat(newFilter.websiteIdToExclude ?? []),
      );

      const newFeed = feed.restoreFromSavedFilters(newFilter, {
        availableWebsiteCategories: availableWebsiteCategories.data ?? [],
        reasons: featureLabels.data || [],
        websites: [...(websites.data ?? []), ...nonTrackedWebsites],
        users: users.data ?? [],
        geos: geographySelectOptions.data ?? {
          countries: [],
          zones: [],
        },
        contactTypes: availableContactTypes.data ?? [],
        insightOptions: insightOptions.data ?? [],
        availableCategories: availableCategories.data ?? [],
        labels: {
          account: accountLabels.data ?? [],
          post: availableLabels.data ?? [],
          image: availableImageLabels.data ?? [],
        },
        takenDown: takedown.data ?? {
          posts: [],
          accounts: [],
          websites: [],
        },
        tags: {
          account: tags[Domain.Tag.TagTypeEnum.account].data ?? [],
          post: tags[Domain.Tag.TagTypeEnum.post].data ?? [],
          duplicatedGroup:
            tags[Domain.Tag.TagTypeEnum.duplicatedGroup].data ?? [],
          vendor: tags[Domain.Tag.TagTypeEnum.vendor].data ?? [],
        },
      });
      newFeed.state = FiltersContainerState.Updated;
      dispatch(
        replaceFeedFilter({
          feed: newFeed,
        }),
      );
    },
    [
      fetchNonTrackedWebsites,
      availableWebsiteCategories,
      featureLabels,
      websites,
      users,
      geographySelectOptions,
      insightOptions,
      availableCategories,
      accountLabels,
      availableLabels,
      availableImageLabels,
      tags,
      takedown,
    ],
  );

  return [applyFilter];
};
