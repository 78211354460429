import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { ComplianceDocumentsResponseModel } from 'product-types/src/domain/files/ComplianceDocuments';

interface ComplianceDocumentsRequestModel extends EndpointRequests {
  urlParams: {
    organisationId: string;
  };
}

const getComplianceDocuments = new Endpoint<
  ComplianceDocumentsRequestModel,
  ComplianceDocumentsResponseModel
>({
  url: '/api/organisation/:organisationId/compliance_documents',
  method: 'GET',
  headers: {},
});

export default getComplianceDocuments;
