import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { OrganisationRawModel } from 'product-types/src/domain/organisation/Organisation';
import { BrandModel } from 'product-types/src/domain/ipCertificates/BrandModel';

interface UpdateOrganisationRequestModel extends EndpointRequests {
  data: {
    default_feed_view_date_range: string;
    kpis: OrganisationRawModel['kpis'];
    display_name: string;
    logo: string;
    brands: Array<BrandModel>;
    organisation_status: string;
  };
}

const updateOrganisation = new Endpoint<
  UpdateOrganisationRequestModel,
  Response
>({
  url: '/api/organisation/',
  method: 'PUT',
  headers: {},
});

export default updateOrganisation;
