import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/Domain';
import { produce } from 'immer';
import { ImageFeatureFilterModel } from 'types/filters/MoleculesFilterImplementation/ImageFeatureFilterModel';
import {
  UPDATE_IMAGE_FEATURE_FILTER,
  UPDATE_IMAGE_FEATURES,
} from './constants';

export interface ImageFeaturesState {
  mounted: boolean;
  imageFeatures: FetchableData<Array<ModerationReason.ModerationReason>>;
  filters: ImageFeatureFilterModel;
}

export const createInitialState: () => ImageFeaturesState = () => ({
  mounted: true,
  imageFeatures: FetchableData.default<ModerationReason.ModerationReason[]>(),
  filters: new ImageFeatureFilterModel(),
});

export const imageFeatureReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_IMAGE_FEATURES: {
        // eslint-disable-next-line no-param-reassign
        draft.imageFeatures = action.imageFeatures;
        break;
      }
      case UPDATE_IMAGE_FEATURE_FILTER: {
        // eslint-disable-next-line no-param-reassign
        draft.filters = draft.filters.updateFilterValue(action.filter);
        break;
      }
      default: {
        break;
      }
    }
  });
