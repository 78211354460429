import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface OrganisationRequestModel extends EndpointRequests {}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface OrganisationResponse {}

const organisation = new Endpoint<
  OrganisationRequestModel,
  OrganisationResponse
>({
  url: '/api/me/organisation',
  method: 'GET',
  headers: {},
});

export default organisation;
