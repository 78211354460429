/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
import { produce } from 'immer';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { FeatureDetectionTaskRaw } from 'product-types/src/domain/task';
import { LOAD_TASK_SUCCESS, RESET_STATE } from './constants';

export const createInitialState = () => ({
  tasksData: FetchableData.default<FeatureDetectionTaskRaw[]>(),
});

export type TaskFeedState = ReturnType<typeof createInitialState>;

const taskViewReducer = (state = createInitialState(), action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE: {
        const newState = createInitialState();
        Reflect.ownKeys(newState).forEach((key) => {
          draft[key] = newState[key];
        });
        break;
      }
      case LOAD_TASK_SUCCESS:
        draft.tasksData = action.tasksData;
        break;
    }
  });

export default taskViewReducer;
