import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface AddCommentRequestModel extends EndpointRequests {
  urlParams: {
    objectId: string;
  };
  data: {
    comment: string;
    file_ids: Array<number>;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface AddCommentResponseModel {}

const addComment = new Endpoint<
  AddCommentRequestModel,
  AddCommentResponseModel
>({
  url: '/api/me/posts/:objectId/comments',
  method: 'POST',
  headers: {},
});

export default addComment;
