import { Filter } from 'types/filters/AtomicFilters/Filter';
import { FetchableData } from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/Domain';
import {
  CREATE_IMAGE_FEATURE,
  DELETE_IMAGE_FEATURE,
  LOAD_IMAGE_FEATURES,
  UPDATE_IMAGE_FEATURE,
  UPDATE_IMAGE_FEATURE_FILTER,
  UPDATE_IMAGE_FEATURES,
} from './constants';

export const loadImageFeatures = () => ({
  type: LOAD_IMAGE_FEATURES,
});

export const updateImageFeatures = (
  imageFeatures: FetchableData<Array<ModerationReason.ModerationReason>>,
) => ({
  type: UPDATE_IMAGE_FEATURES,
  imageFeatures,
});

export const updateImageFeature = (
  imageFeature: ModerationReason.ModerationReason,
) => ({
  type: UPDATE_IMAGE_FEATURE,
  imageFeature,
});

export const createImageFeature = (
  imageFeature: ModerationReason.ModerationReason,
) => ({
  type: CREATE_IMAGE_FEATURE,
  imageFeature,
});

export const updateImageFeatureFilter = (filter: Filter) => ({
  type: UPDATE_IMAGE_FEATURE_FILTER,
  filter,
});

export const deleteImageFeature = (id: number) => ({
  type: DELETE_IMAGE_FEATURE,
  id,
});
