import React from 'react';
import * as Domain from 'product-types/src/domain/Domain';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import Tag from 'product-ui/src/components/atoms/Tag';
import {
  DisplayFilterComponent,
  DisplayFilterComponentProps,
} from '../../../types/filters/AtomicFilters/Filter';

export const NewTakedownStatusFilterValue: DisplayFilterComponent = ({
  filterValue: { uuid, value },
  onClickRemove,
}: DisplayFilterComponentProps) => (
  <Tag
    key={uuid}
    tag={{
      id: `${(value as Domain.TakedownStatus.TakedownStatus).value}`,
      name: (value as Domain.TakedownStatus.TakedownStatus).label,
    }}
    iconPrefix={<NaveeIcon.CardImage />}
    onClickRemove={onClickRemove}
  />
);
