import {
  SELECT_ALL_TRACKED_WEBSITES,
  SELECT_ALL_UNTRACKED_WEBSITES,
} from 'store/constants';
import * as Domain from 'product-types/src/domain/Domain';
import { removeEmptyProperties } from 'product-utils/src/object';
import { VendorFilterQueryValue } from 'types/filters/MoleculesFilterImplementation/VendorFilterModel';
import { FeedRequestParameter } from '../Feed/Feed';

export enum AllowedSortByValues {
  name = 'name',
  total_posts = 'total_posts',
  infringement_percentage = 'infringement_percentage',
  moderation_percentage = 'moderation_percentage',
  contact_info = 'contact_info',
}

export function getRequestParameter(
  action: VendorFilterQueryValue,
): FeedRequestParameter {
  const websiteValuesToExclude = [
    SELECT_ALL_TRACKED_WEBSITES,
    SELECT_ALL_UNTRACKED_WEBSITES,
  ];

  const includeTracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_TRACKED_WEBSITES,
    ).length === 1;

  const includeUntracked =
    (action.website_id || []).filter(
      (value) => value === SELECT_ALL_UNTRACKED_WEBSITES,
    ).length === 1;

  const website_id = (
    (action.website_id || []) as Array<Domain.Website.WebsiteSuggestion>
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id)
    .filter((value: any) => value !== 'NaN');

  const website_id_to_exclude = (
    (action.website_id_to_exclude ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  )
    .filter(
      (value: Domain.Website.WebsiteSuggestion) =>
        !websiteValuesToExclude.includes(value.id.toString()),
    )
    .map((val) => val.id);

  const website_category_id = (
    (action.website_category_id ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  ).map((val) => val.id);
  const website_category_id_to_exclude = (
    (action.website_category_id_to_exclude ||
      []) as Array<Domain.Website.WebsiteSuggestion>
  ).map((val) => val.id);

  return removeEmptyProperties<FeedRequestParameter>({
    perpage: action.perpage ?? 10,
    offset: action.offset,
    commenter_id: action.commenter_id,
    uploader_id: action.uploader_id,
    start_date: action.start_date,
    end_date: action.end_date,
    interval: action.interval,
    timezone_offset: new Date().getTimezoneOffset(),
    geography_to_include: (
      action.geography_to_include as Array<Domain.Geographic.Geographic>
    ).map(({ value }) => value),
    geography_to_exclude: (
      action.geography_to_exclude as Array<Domain.Geographic.Geographic>
    ).map(({ value }) => value),
    sort_by:
      action.sort_by in AllowedSortByValues ? action.sort_by : 'total_posts',
    reverse: action.reverse,
    website_id,
    post_id: action.post_id,
    account_website_id: action.account_website_id,
    account_poster_id: action.account_poster_id,
    duplicated_group_id: action.duplicated_group_id,
    cluster_id: action.cluster_id,
    post_url: action.post_url,
    poster_urls: action.poster_urls,
    vendor_names: action.vendor_names,
    domain_names: action.domain_names,
    poster_names: action.poster_names,
    keywords_to_include: action.keywords_to_include,
    keywords_to_exclude: action.keywords_to_exclude,
    comments_to_include: action.comments_to_include,
    contact_info_to_include: action.contact_info_to_include,
    label_types_logic: 'AND',
    comments_to_exclude: action.comments_to_exclude,
    website_category_id,
    website_category_id_to_exclude,
    post_tags_to_include: (
      action.post_tags_to_include as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    post_tags_to_exclude: (
      action.post_tags_to_exclude as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    account_tags_to_exclude: (
      action.account_tags_to_exclude as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    account_tags_to_include: (
      action.account_tags_to_include as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    duplicated_group_tags_to_exclude: (
      action.duplicated_group_tags_to_exclude as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    duplicated_group_tags_to_include: (
      action.duplicated_group_tags_to_include as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    cluster_tags_to_exclude: (
      action.cluster_tags_to_exclude as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    cluster_tags_to_include: (
      action.cluster_tags_to_include as Array<Domain.Tag.Tag>
    ).map(({ id }) => id),
    website_id_to_exclude,
    all_tracked_websites: includeTracked ? 'true' : undefined,
    all_untracked_websites: includeUntracked ? 'true' : undefined,
    date_filter_type: action.date_filter_type,
  });
}
