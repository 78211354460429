import * as Domain from 'product-types/src/domain/Domain';
import { FilterValue } from '../../AtomicFilters/FilterValue';
export interface readFilterFromQueryProps {
  status: Domain.Task.TaskStatus[];
}

export class TaskStatusFilterValue implements FilterValue {
  status: Domain.Task.TaskStatusOption[];

  constructor(params: Pick<TaskStatusFilterValue, 'status'>) {
    this.status = params.status;
  }

  setTaskStatus(values: Domain.Task.TaskStatusOption[]) {
    return new TaskStatusFilterValue({
      status: values,
    });
  }

  addTaskStatus(value: Domain.Task.TaskStatusOption) {
    return new TaskStatusFilterValue({
      status: [...this.status, value],
    });
  }

  removeTaskStatus(value: Domain.Task.TaskStatusOption) {
    return new TaskStatusFilterValue({
      status: this.status.filter((status) => status !== value),
    });
  }

  static get defaultValue(): TaskStatusFilterValue {
    return new TaskStatusFilterValue({
      status: [],
    });
  }

  static readFilterFromQuery(): TaskStatusFilterValue {
    const taskStatusQuery = new URLSearchParams(window.location.search).getAll(
      'task_status',
    );
    const taskStatus = (taskStatusQuery
      ?.map((insight) => Domain.Task.GetTaskStatusFilterOptionByValue(insight))
      .filter((v) => !!v) || []) as [];
    if (taskStatus.length) {
      return new TaskStatusFilterValue({
        status: taskStatus,
      });
    }
    return TaskStatusFilterValue.defaultValue;
  }
}
