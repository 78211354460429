import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'antd';

export const TreeNodeWithTooltip = ({ strTitle, children }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current) {
        const parentNode = titleRef.current.closest(
          '.ant-tree-node-content-wrapper',
        );
        if (parentNode) {
          setIsOverflowing(parentNode.scrollWidth > parentNode.clientWidth);
        }
      }
    };

    checkOverflow();
  }, []);

  return isOverflowing ? (
    <Tooltip title={strTitle} mouseEnterDelay={0.5}>
      <span ref={titleRef}>{children}</span>
    </Tooltip>
  ) : (
    <span ref={titleRef}>{children}</span>
  );
};
