import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { FeedRequestParameter } from '../../../../../../Feed/Feed';

interface GetPrevImageToModerateRequestModel extends EndpointRequests {
  data: FeedRequestParameter;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface GetPrevImageResponse {}

const getPrevImageToModerate = new Endpoint<
  GetPrevImageToModerateRequestModel,
  GetPrevImageResponse
>({
  url: '/api/me/duplicated_groups_moderation/previous_image_to_moderate',
  method: 'POST',
  headers: {},
});

export default getPrevImageToModerate;
