import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { notification } from 'antd';
import ProductMonitor from 'types/network/Http/productMonitor';
import { AppState } from 'store/storeAccess';
import { UploadPost } from 'product-types/src/domain/uploads/uploadPost';
import { CustomErrorFactory } from 'product-types/src/common/Error/CustomError';
import { UploadPostImage } from 'product-types/src/domain/uploads/uploadPostImage';
import { UploadResponse } from 'types/network/Http/productMonitor/endpoints/upload/add';
import { loadGlobalDataAction } from 'layout/FiltersBar/actions';
import { UploadPostResponse } from 'types/network/Http/productMonitor/endpoints/upload/uploadPost';
import { PostManualUploadResponse } from 'types/network/Http/productMonitor/endpoints/upload/uploadPostManual';
import { WebsiteHasPosters } from 'product-types/src/domain/website/WebsiteHasPosters';
import { UploadPostState } from './reducer';
import {
  checkWebsiteHasPosters,
  resetState,
  setLoadingPostUpload,
  udpateExistingPosts,
  updatePostList,
} from './actions';
import {
  CHECK_WEBSITE_HAS_POSTERS,
  UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST,
  UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST,
  UPLOAD_POSTS_TO_NAVEE,
  UPLOAD_POST_XLSX,
} from './constants';

function* uploadPostsToNavee(action) {
  yield put(udpateExistingPosts(null));
  yield put(
    setLoadingPostUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const data: UploadPostState = yield select(
      (state: AppState) => state.uploadPost,
    );
    const allTags = new Set(
      data.tags
        .concat(data.posts.flatMap((p) => p.tags))
        .filter((t) => t.__isNew__)
        .map((t) => t.name),
    );
    /* eslint-disable no-restricted-syntax */
    if (action.createTags) {
      for (const tag of allTags) {
        yield call(
          ProductMonitor.endpoints.me.organisation.createTag.call.bind(
            ProductMonitor.endpoints.me.organisation.createTag,
          ),
          { data: { tag_type: 'post', name: tag, id: 0 } },
        );
      }
    }
    let result: PostManualUploadResponse | null | UploadPostResponse = null;
    if (data.manualUpload) {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadPostManual.call.bind(
          ProductMonitor.endpoints.upload.uploadPostManual,
        ),
        {
          data: {
            override: action?.override ?? null,
            posts: data.posts.map((p) => p.toRaw()),
            global_tags: data.tags.map((t) => t.name),
            global_label: data.globalLabel?.value ?? '',
          },
        },
      );
    } else {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadPost.call.bind(
          ProductMonitor.endpoints.upload.uploadPost,
        ),
        {
          data: {
            override: action?.override ?? null,
            posts: data.posts.map((p) => p.toRaw()),
            global_tags: data.tags.map((t) => t.name),
            global_label: data.globalLabel?.value ?? '',
          },
        },
      );
    }

    if (result && result.existing_items_number && !action.override) {
      yield put(
        udpateExistingPosts({
          count: result.existing_items_number,
          message: result.title,
          title: result.message,
        }),
      );
    } else {
      yield put(resetState());
      notification.success({
        message: result?.message,
        placement: 'bottomRight',
        duration: 5,
      });
    }
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    yield put(loadGlobalDataAction());
  }
}

function* uploadImagesToS3AndAttachToPost({
  file,
  post,
}: {
  file: File;
  post: UploadPost;
}) {
  yield put(
    setLoadingPostUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const formData = new FormData();
    formData.append('upload_file', file);
    const s3Link: UploadResponse = yield call(
      ProductMonitor.endpoints.upload.add.call.bind(
        ProductMonitor.endpoints.upload.add,
      ),
      {
        data: formData,
      },
    );
    const posts = yield select((state: AppState) => state.uploadPost.posts);
    yield put(
      updatePostList(
        posts.map((p: UploadPost) =>
          p.uid === post.uid
            ? new UploadPost({
                ...p,
                images:
                  p.images.length === 1 && !p.images[0].url
                    ? [
                        new UploadPostImage({
                          label: null,
                          tags: [],
                          url: s3Link.url,
                        }),
                      ]
                    : [
                        ...p.images,
                        new UploadPostImage({
                          label: null,
                          tags: [],
                          url: s3Link.url,
                        }),
                      ],
              })
            : p,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* uploadArchiveLinkToS3AndAttachToPost({
  file,
  post,
}: {
  file: File;
  post: UploadPost;
}) {
  yield put(
    setLoadingPostUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const formData = new FormData();
    formData.append('upload_file', file);
    const s3Link: UploadResponse = yield call(
      ProductMonitor.endpoints.upload.add.call.bind(
        ProductMonitor.endpoints.upload.add,
      ),
      {
        data: formData,
      },
    );
    const posts = yield select((state: AppState) => state.uploadPost.posts);
    yield put(
      updatePostList(
        posts.map((p: UploadPost) =>
          p.uid === post.uid
            ? new UploadPost({
                ...p,
                archiveLink: s3Link.url,
              })
            : p,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
  } finally {
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* uploadXlsxToNavee(action) {
  yield put(
    setLoadingPostUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  // write regexp to check if url contains only numbers
  try {
    const formData = new FormData();
    formData.append('posts', action.file);
    const data: UploadPostState = yield select(
      (state: AppState) => state.uploadPost,
    );

    formData.append('global_label', data.globalLabel?.value ?? '');
    data.tags.forEach((tag) => {
      formData.append('global_tags', tag.name);
    });

    const { manualUpload } = data;
    let result: PostManualUploadResponse | null | UploadPostResponse = null;
    if (manualUpload) {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadPostFileManual.call.bind(
          ProductMonitor.endpoints.upload.uploadPostFileManual,
        ),
        { data: formData, suppressToastrOnError: true },
      );
    } else {
      result = yield call(
        ProductMonitor.endpoints.upload.uploadPostFile.call.bind(
          ProductMonitor.endpoints.upload.uploadPostFile,
        ),
        { data: formData, suppressToastrOnError: true },
      );
    }

    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
    notification.success({
      message: result?.title ?? 'Posts sent for scrapping successfully',
      placement: 'bottomRight',
      duration: 5,
    });
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

function* loadTheWebsiteHasOtherPosters(
  action: ReturnType<typeof checkWebsiteHasPosters>,
) {
  yield put(
    setLoadingPostUpload(
      new FetchableData<null>({
        state: FetchableDataState.LOADING,
        error: null,
        abortController: null,
        data: null,
      }),
    ),
  );
  try {
    const result: WebsiteHasPosters = yield call(
      ProductMonitor.endpoints.websites.hasPosters.call.bind(
        ProductMonitor.endpoints.websites.hasPosters,
      ),
      {
        params: {
          post_url: action.post.url,
        },
        suppressToastrOnError: true,
      },
    );

    const posts = yield select((state: AppState) => state.uploadPost.posts);
    yield put(
      updatePostList(
        posts.map((p: UploadPost) =>
          p.uid === action.post.uid
            ? new UploadPost({
                ...p,
                url: action.post.url,
                domain_information: result,
              })
            : p,
        ),
      ),
    );
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.LOADED,
          error: null,
          abortController: null,
          data: null,
        }),
      ),
    );
  } catch (err) {
    console.error(err);
    notification.error({
      message: `Error when making a call: ${err?.message}`,
      placement: 'bottomRight',
      duration: 5,
    });
    yield put(
      setLoadingPostUpload(
        new FetchableData<null>({
          state: FetchableDataState.ERROR,
          error: CustomErrorFactory.create(err),
          abortController: null,
          data: null,
        }),
      ),
    );
  }
}

// Individual exports for testing
export default function* uploadsPostSaga() {
  yield takeEvery(UPLOAD_POSTS_TO_NAVEE, uploadPostsToNavee);
  yield takeEvery(UPLOAD_POST_XLSX, uploadXlsxToNavee);
  yield takeEvery(CHECK_WEBSITE_HAS_POSTERS, loadTheWebsiteHasOtherPosters);
  yield takeEvery(
    UPLOAD_IMAGES_TO_S3_AND_ATTACH_TO_POST,
    uploadImagesToS3AndAttachToPost,
  );
  yield takeEvery(
    UPLOAD_ARCHIVE_LINK_TO_S3_AND_ATTACH_TO_POST,
    uploadArchiveLinkToS3AndAttachToPost,
  );
}
