export interface TakedownStatus {
  label: string;
  value: true | false | null | string;
}

export const TakedownScope = {
  POSTS: 'posts',
  ACCOUNTS: 'accounts',
  WEBSITES: 'websites',
  IMAGES: 'images',
} as const;

export type TakedownScopeType =
  (typeof TakedownScope)[keyof typeof TakedownScope];

export const getTakedownScope = (
  scopeString: string,
): TakedownScopeType | undefined =>
  Object.values(TakedownScope).includes(scopeString as any)
    ? (scopeString as TakedownScopeType)
    : undefined;

export const GetTakeDownStatusFilterOptionByValue = (
  takeDownStatuses: TakedownStatus[],
  value: string | null,
) =>
  takeDownStatuses.find((takeDown) => {
    if (value === '') {
      return takeDown.value === null;
    }
    return `${takeDown.value}` === value;
  });
