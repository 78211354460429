import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

export type DeleteFilterData = { id: number };

type DeleteFilterRequestModel = {
  data: DeleteFilterData;
} & EndpointRequests;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface DeleteFilterResponse {}

const deleteFilter = new Endpoint<
  DeleteFilterRequestModel,
  DeleteFilterResponse
>({
  url: '/api/me/filter_presets',
  method: 'DELETE',
  headers: {},
});

export default deleteFilter;
