import React, { useMemo, useState } from 'react';
import { Flex, Form, notification, Upload } from 'antd';
import ProductMonitor from 'types/network/Http/productMonitor';
import orgLogoPlaceholder from 'assets/images/org-logo-placeholder.jpg';
import Link from 'product-ui/src/components/atoms/Link/Link';
import Button from 'product-ui/src/components/atoms/Button/Button';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import { PictureDiv } from 'containers/SettingsPage/styled-components';
import { StyledSpin } from 'product-ui/src/components/atoms/Spin/StyledSpin';
import { usePermissionManager } from 'providers/PermissionManager';
import { CAN_EDIT_ORGANISATION_SETTINGS } from 'providers/PermissionManager/knownPermissions';
import { FormInstance } from 'antd/lib';

interface ImageUploadFormProps {
  form: FormInstance;
  imageUrl?: string;
  maxWidth?: string;
  isCenter?: boolean;
}

export const ImageUploadForm = ({
  form,
  imageUrl,
  maxWidth = '100%',
  isCenter = false,
}: ImageUploadFormProps) => {
  const { hasPermission } = usePermissionManager();

  const [showLogoUrlInput, setShowLogoUrlInput] = useState<boolean>(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);

  const logoUrlPattern = /^(https?:\/\/).+/;
  const logoUrl: string | undefined = Form.useWatch('logoUrl', form);

  const isImageUploadDisabled = useMemo(
    () => (logoUrl ? !logoUrlPattern.test(logoUrl) : true),
    [logoUrl],
  );

  const validateLogoUrl = (_, value: string) => {
    if (!value || logoUrlPattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid IMAGE URL'));
  };

  const backgroundImage = useMemo(
    () =>
      `url(${
        uploadedImageUrl ||
        form.getFieldValue('logo') ||
        imageUrl ||
        orgLogoPlaceholder
      })`,
    [form.getFieldValue('logo'), uploadedImageUrl],
  );

  const handleUploadImage = ({
    file: logo,
    onSuccess,
    onError,
  }: {
    file: File;
    onSuccess: (body: unknown) => void;
    onError: (event: Error, body?: unknown) => void;
  }) => {
    const formLogo = new FormData();
    formLogo.append('logo', logo);
    ProductMonitor.endpoints.organisation.uploadNewLogo
      .call({
        data: formLogo,
      })
      .then((response) => {
        onSuccess(response.object.logo_url);
      })
      .catch(onError);
  };

  const handleUrlImageUpload = async () => {
    const formData = new FormData();

    formData.append('logo_url', form.getFieldValue('logoUrl'));

    return ProductMonitor.endpoints.organisation.uploadNewLogo
      .call({ data: formData })
      .then((response) => {
        form.setFieldValue('logo', response.object.logo_url);
        setUploadedImageUrl(response.object.logo_url);
      })
      .catch(() => {
        notification.error({
          message: 'Failed to upload image via URL link',
          placement: 'bottomRight',
          duration: 5,
        });
      });
  };

  return (
    <Flex vertical gap="0.5rem">
      <PictureDiv $loading={false} $hasPhoto>
        <Flex
          justify="center"
          className="pic-container"
          style={{
            backgroundSize: 'contain',
            backgroundImage,
            margin: isCenter ? '0 auto' : '0',
          }}
        >
          <StyledSpin spinning={false}>
            {!showLogoUrlInput && (
              <Form.Item style={{ width: 120 }} name="logo">
                <Upload
                  id="upload-profile-picture"
                  customRequest={handleUploadImage}
                  onChange={(resp) => {
                    form.setFieldValue('logo', resp.file.response);
                    form.validateFields(['logo']);
                    setUploadedImageUrl(resp.file.response);
                  }}
                  disabled={!hasPermission(CAN_EDIT_ORGANISATION_SETTINGS)}
                  listType="picture"
                >
                  <Button
                    dataTestId="upload-logo-button"
                    size="Medium"
                    loading={false}
                    style={{ letterSpacing: 0 }}
                    disabled={!hasPermission(CAN_EDIT_ORGANISATION_SETTINGS)}
                    onClick={(e) => e.preventDefault()}
                    label={imageUrl ? 'Change Logo' : 'Upload Logo'}
                  />
                </Upload>
              </Form.Item>
            )}
          </StyledSpin>
        </Flex>
      </PictureDiv>
      <Flex
        vertical
        align={isCenter ? 'center' : 'flex-start'}
        style={{ padding: '0.25rem 0' }}
      >
        <Link
          variant="small"
          fontWeight="medium"
          onClick={() => setShowLogoUrlInput(!showLogoUrlInput)}
        >
          {!showLogoUrlInput
            ? 'Upload logo via URL link'
            : 'Hide upload logo via URL link'}
        </Link>
        {showLogoUrlInput && (
          <Flex style={{ width: '100%', marginTop: '1rem' }}>
            <Form.Item
              label="IMAGE URL"
              name="logoUrl"
              required
              rules={[{ validator: validateLogoUrl }]}
              validateTrigger="onBlur"
              style={{
                flexBasis: '45%',
                flexGrow: 1,
                maxWidth,
              }}
            >
              <Flex gap="1rem" vertical={false}>
                <Input type="url" placeholder="http://" />
                <Button
                  icon={
                    <NaveeIcon.RightArrowAlt
                      fill={isImageUploadDisabled ? '#C7C7C7' : undefined}
                    />
                  }
                  variant="Outline"
                  buttonType="button"
                  disabled={isImageUploadDisabled}
                  style={{ width: '52px' }}
                  onClick={handleUrlImageUpload}
                />
              </Flex>
            </Form.Item>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
