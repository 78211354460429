import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { ModerationReason } from 'product-types/src/domain/moderationReason/ModerationReason';
import { UserLightModel } from 'product-types/src/domain/user/UserLightModel';
import { WebsiteCategory } from 'product-types/src/domain/website/WebsiteCategory';
import { TagModel, TagTypeEnum } from 'product-types/src/domain/tag/Tag';
import { GeographySelectOptions } from 'product-types/src/domain/geo/Geo';
import { WebsiteSuggestion } from 'product-types/src/domain/website/Website';
import { Label } from 'product-types/src/domain/label/Label';
import { ProductCategoryTree } from 'product-types/src/domain/productCategory';
import { Insight } from 'product-types/src/domain/insight/insight';
import { RoleModel } from 'product-types/src/domain/role/Role';
import { ContactMainInfo } from 'product-types/src/domain/contact/Contact';
import { Currency } from 'product-types/src/domain/Currency';
import { OrganisationStatus } from 'product-types/src/domain/organisation/OrganisationStatus';
import { LanguageModel } from 'product-types/src/domain/language/language';
import { CrawlingDomain } from 'product-types/src/domain/crawlingDomain';
import { ScrappingSource } from 'product-types/src/domain/scrappingSource/ScrappingSource';
import { FilterPresets } from 'product-types/src/domain/globalData/GlobalData';
import { FeatureFlagModel } from 'product-types/src/domain/featureFlags/FeatureFlag';
import { TakedownOptions } from 'product-types/src/domain/takedownStatus/TakedownModel';
import {
  BrandType,
  ObjectCategoryType,
} from 'product-types/src/domain/image/ImageFeatures';
import { TaskCategory, TaskType } from 'product-types/src/domain/task';
import { TasksFilterModel } from 'types/filters/MoleculesFilterImplementation/TasksFilterModel';
import { IPAssetModel } from 'product-types/src/domain/ipAsset/IPAssetModel';
import { IpRemovalReasonCategoryModel } from 'product-types/src/domain/ipCertificates/IpRemovalReasonCategoryModel';
import { FeedFilterModel } from '../../types/filters/MoleculesFilterImplementation/FeedFilterModel';
import { VendorFilterModel } from '../../types/filters/MoleculesFilterImplementation/VendorFilterModel';
import { DashboardFiltersModel } from '../../types/filters/MoleculesFilterImplementation/DashboardFilterModel';
import { UploadHistoryFiltersModel } from '../../types/filters/MoleculesFilterImplementation/UploadHistoryFiltersModel';

interface TagOptionData {
  [TagTypeEnum.account]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.duplicatedGroup]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.post]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.uploadHistory]: FetchableData<Array<TagModel>>;
  [TagTypeEnum.vendor]: FetchableData<Array<TagModel>>;
}

export interface LoadedDataState {
  availableCategories: FetchableData<ProductCategoryTree>;
  availableWebsiteCategories: FetchableData<Array<WebsiteCategory>>;
  availableContactTypes: FetchableData<Array<ContactMainInfo>>;
  geographySelectOptions: FetchableData<GeographySelectOptions>;
  availableLabels: FetchableData<Array<Label>>;
  availableImageLabels: FetchableData<Array<Label>>;
  featureFlags: FetchableData<Array<FeatureFlagModel>>;
  accountLabels: FetchableData<Array<Label>>;
  organisationStatuses: FetchableData<Array<OrganisationStatus>>;
  languages: FetchableData<Array<LanguageModel>>;
  scrappingSource: FetchableData<Array<ScrappingSource>>;
  insightOptions: FetchableData<Array<Insight>>;
  postsData: any;
  websites: FetchableData<Array<WebsiteSuggestion>>;
  tags: TagOptionData;
  filtersFetchState: FetchableDataState;
  filters: FilterPresets;
  featureLabels: FetchableData<Array<ModerationReason>>;
  users: FetchableData<Array<UserLightModel>>;
  roles: FetchableData<Array<RoleModel>>;
  currencies: FetchableData<Array<Currency>>;
  crawlingDomains: FetchableData<Array<CrawlingDomain>>;
  takedown: FetchableData<TakedownOptions>;
  L1ObjectCategories: FetchableData<Array<ObjectCategoryType>>;
  brands: FetchableData<Array<BrandType>>;
  taskCategories: FetchableData<Array<TaskCategory>>;
  taskTypes: FetchableData<Array<TaskType>>;
  ipAssets: FetchableData<Array<IPAssetModel>>;
  ipRemovalReasons: FetchableData<Array<IpRemovalReasonCategoryModel>>;
}

// refactor loaded data initial state
const loadedDataInitialState = {
  availableCategories: FetchableData.default<ProductCategoryTree>(),
  availableContactTypes: FetchableData.default<Array<ContactMainInfo>>(),
  availableWebsiteCategories: FetchableData.default<Array<WebsiteCategory>>(),
  geographySelectOptions: FetchableData.default<GeographySelectOptions>(),
  organisationStatuses: FetchableData.default<Array<OrganisationStatus>>(),
  featureFlags: FetchableData.default<Array<FeatureFlagModel>>(),
  currencies: FetchableData.default<Array<Currency>>(),
  insightOptions: FetchableData.default<Array<Insight>>(),
  availableLabels: FetchableData.default<Array<Label>>(),
  availableImageLabels: FetchableData.default<Array<Label>>(),
  accountLabels: FetchableData.default<Array<Label>>(),
  languages: FetchableData.default<Array<LanguageModel>>(),
  crawlingDomains: FetchableData.default<Array<CrawlingDomain>>(),
  scrappingSource: FetchableData.default<Array<ScrappingSource>>(),
  roles: FetchableData.default<Array<RoleModel>>(),
  postsData: {},
  websites: FetchableData.default<Array<WebsiteSuggestion>>(),
  tags: {
    [TagTypeEnum.account]: FetchableData.default<Array<TagModel>>(),
    [TagTypeEnum.duplicatedGroup]: FetchableData.default<Array<TagModel>>(),
    [TagTypeEnum.post]: FetchableData.default<Array<TagModel>>(),
    [TagTypeEnum.uploadHistory]: FetchableData.default<Array<TagModel>>(),
    [TagTypeEnum.vendor]: FetchableData.default<Array<TagModel>>(),
  },
  filters: {
    post: [],
    image: [],
    website: [],
    account: [],
  },
  filtersFetchState: FetchableDataState.NOT_LOADED,
  featureLabels: FetchableData.default<Array<ModerationReason>>(),
  users: FetchableData.default<Array<UserLightModel>>(),
  takedown: FetchableData.default<TakedownOptions>(),
  L1ObjectCategories: FetchableData.default<Array<ObjectCategoryType>>(),
  brands: FetchableData.default<Array<BrandType>>(),
  taskCategories: FetchableData.default<Array<TaskCategory>>(),
  taskTypes: FetchableData.default<Array<TaskType>>(),
  ipAssets: FetchableData.default<Array<IPAssetModel>>(),
  ipRemovalReasons:
    FetchableData.default<Array<IpRemovalReasonCategoryModel>>(),
};

export interface SearchBarState {
  post_url: Array<any>;
  not_found_images: Array<any>;
  searching_images: Array<any>;
  found_images: Array<any>;
  duplicated_group_id: Array<any>;
  post_id: Array<any>;
}

export const createDashboardFiltersInitialState = (props?: any) =>
  new DashboardFiltersModel(props);

export const createVendorsFiltersInitialState = (props?: any) =>
  new VendorFilterModel(props);

export const createTasksFiltersInitialState = (props?: any) =>
  new TasksFilterModel(props);

export const createFeedFiltersInitialState = (props?: any) =>
  new FeedFilterModel(props);

export const createLoadedDataInitialState = (): LoadedDataState => ({
  ...loadedDataInitialState,
});

export const createUploadHistoryFiltersInitialState = (props?: any) =>
  new UploadHistoryFiltersModel(props);
