import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';

interface DeleteRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const deleteFeature = new Endpoint<DeleteRequestModel, object>({
  url: 'api/me/image_features/:id',
  method: 'DELETE',
  headers: {},
});

export default deleteFeature;
