import { put, takeLatest } from 'redux-saga/effects';
import {
  FetchableData,
  FetchableDataState,
} from 'product-types/src/common/FetchableData/FetchableData';
import { FeatureDetectionTaskListModel } from 'product-types/src/domain/task';
import Network from 'types/network';
import queryString from 'query-string';
import { push } from 'redux-first-history';
import { loadTasksAction, loadTasksSuccessAction } from './actions';
import { LOAD_TASKS } from './constants';

function* loadTasksSaga(action: ReturnType<typeof loadTasksAction>) {
  const requestParameters = Network.Task.getRequestParameter(action.pageState);

  if (
    window.location.search.replace(/^\?/, '') !==
    queryString.stringify(requestParameters)
  ) {
    yield put(
      push(
        {
          search: queryString.stringify(requestParameters),
        },
        { navigation: true },
      ),
    );
  }

  yield put(
    loadTasksSuccessAction(
      action.successAction,
      new FetchableData<FeatureDetectionTaskListModel>({
        abortController: null,
        state: FetchableDataState.LOADING,
        data: null,
        error: null,
      }),
    ),
  );

  // TODO: implement endpoint call

  yield put(
    loadTasksSuccessAction(
      action.successAction,
      new FetchableData<FeatureDetectionTaskListModel>({
        abortController: null,
        state: FetchableDataState.LOADED,
        data: {
          feature_detection_tasks: [],
        },
        error: null,
        //   data: [
        //     {
        //       ...FeatureDetectionTask.createDefaultModerationReason(),
        //       id: 1,
        //       assignee: [869, 1297, 4692],
        //       name: 'Packaging Labelling',
        //       taskCategory: {
        //         label: 'Pre-Filtering Mode',
        //         value: 2,
        //       },
        //       taskType: {
        //         label: 'Post moderation',
        //         value: 2,
        //       },
        //       dueDate: dayjs(new Date(Date.now())),
        //       isNew: false,
        //       completion: {
        //         completed: 100,
        //         total: 100,
        //       },
        //       status: {
        //         label: 'Completed',
        //         value: TaskStatus.COMPLETED,
        //       },
        //     },
        //     {
        //       ...FeatureDetectionTask.createDefaultModerationReason(),
        //       id: 2,
        //       assignee: [869, 1297, 4692],
        //       name: 'Packaging Labelling',
        //       taskCategory: {
        //         label: 'Pre-Filtering Mode',
        //         value: 2,
        //       },
        //       taskType: {
        //         label: 'Post moderation',
        //         value: 2,
        //       },
        //       dueDate: dayjs(new Date(Date.now())),
        //       isNew: false,
        //       completion: {
        //         completed: 60,
        //         total: 100,
        //       },
        //       status: {
        //         label: 'In Progress',
        //         value: TaskStatus.IN_PROGRESS,
        //       },
        //     },
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //     FeatureDetectionTask.createDefaultModerationReason(),
        //   ],
        //   error: null,
      }),
    ),
  );
}

export default function* taskViewSaga() {
  yield takeLatest(LOAD_TASKS, loadTasksSaga);
}
