import { Endpoint, EndpointRequests } from 'types/network/Http/endpoint';
import { TaskModerationRawModel } from 'product-types/src/domain/task/TaskModerationModel';

interface ModerationTaskRequestModel extends EndpointRequests {
  urlParams: { id: string };
}

const moderationTask = new Endpoint<
  ModerationTaskRequestModel,
  TaskModerationRawModel
>({
  url: 'api/me/feature_detection_tasks/:id',
  method: 'GET',
  headers: {},
});

export default moderationTask;
