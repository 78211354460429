import {
  OrganisationModel,
  OrganisationRawModel,
} from '../organisation/Organisation';

export interface BrandRaw {
  id: number;
  name: string;
  alternative_name?: string | null;
  organization?: OrganisationRawModel;
}

export class BrandModel {
  id?: number;

  name: string;

  alternativeName?: string | null;

  organization?: OrganisationModel;

  constructor(
    prop: Pick<BrandModel, 'id' | 'name' | 'alternativeName' | 'organization'>,
  ) {
    this.id = prop.id;
    this.name = prop.name;
    this.alternativeName = prop.alternativeName ?? null;
    this.organization = prop.organization;
  }

  static createFromRawModel(prop: BrandRaw) {
    return new BrandModel({
      id: prop.id,
      name: prop.name,
      alternativeName: prop.alternative_name,
      organization: prop.organization
        ? OrganisationModel.createFromRawModel(prop.organization)
        : undefined,
    });
  }
}
