import { TableParamsQueryValue } from 'types/filters/AtomicFiltersImplementation/TableParams/TableParamsFilter';
import { QueryValue } from 'product-types/src/network/Query/Query';
import { removeEmptyProperties } from 'product-utils/src/object';
import { AccountInformationQueryValue } from 'types/filters/AtomicFiltersImplementation/AccountInformation/AccountInformation';
import { TakedownStatusFilterQueryValue } from 'types/filters/AtomicFiltersImplementation/TakedownStatus/TakedownStatusFilter';

type RelatedAccountsRequestParameter = TableParamsQueryValue &
  AccountInformationQueryValue &
  TakedownStatusFilterQueryValue;

export interface RelatedAccountsRequest extends QueryValue {
  perpage?: number;
  offset?: number;
  sort_by?: string;

  minimum_followers_count?: number;
  maximum_followers_count?: number;

  contact_types?: { value: string }[];
  taken_down_accounts?: { value: boolean }[];
  taken_down_websites?: { value: boolean }[];
  taken_down_posts?: { value: boolean }[];
}

export function getRequestParameter(
  action: RelatedAccountsRequestParameter,
): RelatedAccountsRequest {
  const label_type_to_include = (action.contact_types || []).map(
    (ct) => ct.value,
  );
  const taken_down_accounts = (action.taken_down_accounts || []).map(
    (td) => td.value,
  );
  const taken_down_websites = (action.taken_down_websites || []).map(
    (td) => td.value,
  );
  const taken_down_posts = (action.taken_down_posts || []).map(
    (td) => td.value,
  );

  return removeEmptyProperties({
    perpage: action.perpage ?? 3,
    offset: action.offset,
    sort_by: action.sort_by,

    minimum_followers_count: action.minimum_followers_count,
    maximum_followers_count: action.maximum_followers_count,

    label_type_to_include,

    taken_down_accounts,
    taken_down_websites,
    taken_down_posts,
  });
}
