import React, { useCallback, useState } from 'react';
import { Flex, Form } from 'antd';
import { Input } from 'product-ui/src/components/atoms/Input/Input';
import NaveeIcon from 'product-ui/src/components/atoms/NaveeIcon/NaveeIcon';
import { Select } from 'product-ui/src/components/atoms/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'product-ui/src/components/organism/Modal';
import { useSubmitButton } from 'product-ui/src/hooks/useSubmitButton';
import { BrandTags } from 'containers/SettingsPage/components/BrandTags';
import { BrandModel } from 'product-types/src/domain/ipCertificates/BrandModel';
import { ImageUploadForm } from 'containers/SettingsPage/General/components/ImageUploadForm';
import { createOrganisation } from '../../../Navbar/actions';
import { CreateNewOrganizationSuccess } from './CreateNewOrganizationSuccess';
import { AppState } from '../../../../store/storeAccess';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const FormItemStyle = {
  flexBasis: '45%',
  flexGrow: 1,
  maxWidth: 'calc(50% - 8px)',
};

export const CreateNewOrganizationModal = ({ open, setOpen }: Props) => {
  const createOrganizationState = useSelector(
    (state: AppState) => state.navbar.createOrganisation,
  );
  const organisationStatuses = useSelector(
    (state: AppState) =>
      state.filters_bar.optionsLoadedData.organisationStatuses?.data ?? [],
  );
  const currencyList = useSelector(
    (state: AppState) => state.filters_bar.optionsLoadedData.currencies,
  );
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = useCallback(() => {
    const values: {
      organizationName: string;
      legalName: string;
      status: string;
      currency: string;
      logo: string;
      organisation_status: string;
      brands: Array<BrandModel>;
    } = form.getFieldsValue();

    dispatch(
      createOrganisation({
        ...{
          ...values,
          logo: values.logo || form.getFieldValue('logo'),
        },
        successCb: () => setSuccess(true),
      }),
    );
  }, []);

  const handleOk = () => {
    setSuccess(false);
    form.resetFields();
    handleCancel();
  };

  const { submittable } = useSubmitButton({ form });

  return (
    <Modal
      destroyOnClose
      open={open}
      size="large"
      title="Create New Organization"
      buttons={{
        submit: {
          onClick: success ? handleOk : handleSubmit,
          label: success ? 'OK' : 'CREATE',
          dataTestId: 'create-org-submit-button',
          disabled:
            (!success && !submittable) || createOrganizationState.isLoading,
        },
        cancel: {
          show: !success,
          onClick: handleCancel,
          disabled: createOrganizationState.isLoading,
        },
      }}
      onClose={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ currency: 'EUR' }}
        requiredMark={false}
        disabled={createOrganizationState.isLoading}
        onFinish={handleSubmit}
        validateTrigger="onBlur"
      >
        <Flex vertical>
          {success ? (
            <CreateNewOrganizationSuccess />
          ) : (
            <Flex vertical gap="1.5rem">
              <ImageUploadForm form={form} isCenter />
              <Flex vertical gap="1rem" style={{ width: '100%' }}>
                <Flex
                  wrap
                  gap="24px"
                  style={{
                    columnGap: '16px',
                  }}
                >
                  <Form.Item
                    label="ORGANIZATION NAME"
                    name="organizationName"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the organization name',
                      },
                    ]}
                    style={FormItemStyle}
                  >
                    <Input placeholder="Organization name" />
                  </Form.Item>

                  <Form.Item
                    label="LEGAL NAME"
                    name="legalName"
                    tooltip={{
                      icon: (
                        <NaveeIcon.InfoIconWithQuestion
                          width={12}
                          height={12}
                        />
                      ),
                      overlayStyle: { width: 193 },
                      overlayInnerStyle: {
                        textAlign: 'center',
                        fontWeight: 500,
                        padding: 10,
                      },
                      title: 'Registered legal name for IP and trademarks',
                    }}
                    style={FormItemStyle}
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the legal name',
                      },
                    ]}
                  >
                    <Input placeholder="Legal name" />
                  </Form.Item>

                  <Form.Item
                    name="organisation_status"
                    label="STATUS"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the status',
                      },
                    ]}
                    style={FormItemStyle}
                  >
                    <Select
                      dataTestId="organisation-status"
                      placeholder="Choose status"
                      style={{
                        width: '100%',
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={organisationStatuses}
                    />
                  </Form.Item>
                  <Form.Item
                    name="currency"
                    label="DEFAULT CURRENCY"
                    rules={[
                      {
                        required: true,
                        message: 'Please fill the default currency',
                      },
                    ]}
                    style={FormItemStyle}
                    tooltip={{
                      icon: (
                        <NaveeIcon.InfoIconWithQuestion
                          width={12}
                          height={12}
                        />
                      ),
                      overlayStyle: { width: 193 },
                      overlayInnerStyle: {
                        textAlign: 'center',
                        fontWeight: 500,
                        padding: 10,
                      },
                      title: 'Registered legal name for IP and trademarks',
                    }}
                  >
                    <Select
                      dataTestId="organization-currency"
                      showSearch
                      filterOption={(input, option) =>
                        (option.label as string)
                          .toLowerCase()
                          .includes((input || '')?.toLowerCase())
                      }
                      getPopupContainer={(trigger) => trigger.parentNode}
                      options={(currencyList.data ?? []).map((currency) => ({
                        label: `(${currency.code}) ${currency.label}`,
                        value: currency.code,
                      }))}
                      style={{
                        width: '100%',
                      }}
                      labelRender={(option) => option.label}
                    />
                  </Form.Item>
                  <Form.Item
                    name="brands"
                    label="BRAND NAMES"
                    rules={[
                      {
                        required: true,
                        message: 'Please add organisation brands',
                      },
                    ]}
                    style={FormItemStyle}
                    tooltip={{
                      icon: (
                        <NaveeIcon.InfoIconWithQuestion
                          width={12}
                          height={12}
                        />
                      ),
                      overlayStyle: { width: 193 },
                      overlayInnerStyle: {
                        textAlign: 'center',
                        fontWeight: 500,
                        padding: 10,
                      },
                      title:
                        'Select the brands associated with this organisation',
                    }}
                  >
                    <BrandTags />
                  </Form.Item>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Form>
    </Modal>
  );
};
