import { CanBeSelectable } from 'product-types/src/common/CanBeSelectable/CanBeSelectable';
import {
  CanBeModeratable,
  Moderatable,
} from 'product-types/src/common/Moderatable/Moderatable';
import { HasModerationPage } from 'product-types/src/common/HasModerationPage/HasModerationPage';
import { TagModel, TagRaw } from 'product-types/src/domain/tag/Tag';
import { Occurrences } from 'product-types/src/domain/occurences/Occurences';
import { ModerationMethodEnum } from 'product-types/src/domain/moderationMethod/ModerationMethod';
import { Id } from 'product-types/src/domain/image/Image';
import { AccountAssociatedImageRaw } from 'product-types/src/domain/image/AssociatedImage';
import { transformUrl } from 'product-utils/src/urls/urls';
import { Magnifiable } from '../../common/Magnifiable/Magnifiable';
import { HasAttributes } from '../attribute/HasAttributes';
import { AttributeModel, AttributeRaw } from '../attribute/Attribute';

export interface ImageFeedRawModel {
  can_edit_moderation: boolean;
  can_edit_qa_check: boolean;
  can_edit_reasons: boolean;
  can_edit_validation: boolean;
  can_remove_validation: boolean;
  can_view_qa_check: boolean;
  can_view_validation: boolean;
  creation_date: string;
  id: number;
  image_moderation_date: null | string;
  image_moderation_method: null;
  image_status: null | string;
  image_status_color: null | string;
  moderation_reasons: any;
  occurences_in_accounts: number;
  occurences_in_vendors: number;
  occurrences: number;
  picture_url: string;
  qa_checked: boolean | null;
  qa_checked_date: string | null;
  rank: number;
  tags: Array<TagRaw>;
  validated: boolean | null;
  attributes: {
    [key: string]: Array<AttributeRaw<any>>;
  };
}

export class ImageFeedModel
  implements
    CanBeSelectable,
    CanBeModeratable,
    HasModerationPage,
    Magnifiable,
    HasAttributes
{
  moderation: Moderatable;

  creationDate: Date;

  id: Id;

  imageModerationDate: Date | null;

  imageModerationMethod: ModerationMethodEnum;

  occurences: Occurrences;

  url: string;

  rank: number;

  tags: Array<TagModel>;

  color: string | null = null;

  attributes: { expiry_date: AttributeModel<'date'>[] };

  private constructor(
    props?: Pick<
      ImageFeedModel,
      | 'tags'
      | 'color'
      | 'creationDate'
      | 'id'
      | 'imageModerationDate'
      | 'imageModerationMethod'
      | 'moderation'
      | 'occurences'
      | 'url'
      | 'rank'
      | 'attributes'
    >,
  ) {
    this.moderation = props?.moderation
      ? new Moderatable(props.moderation)
      : Moderatable.default;
    this.id = props?.id ?? NaN;
    this.creationDate = new Date(props?.creationDate ?? NaN);
    this.imageModerationDate = new Date(props?.imageModerationDate ?? NaN);
    this.imageModerationMethod =
      props?.imageModerationMethod ?? ModerationMethodEnum.all;
    this.occurences = props?.occurences
      ? new Occurrences(props?.occurences)
      : Occurrences.default;
    this.url = transformUrl(props?.url || '');
    this.rank = props?.rank || NaN;
    this.color = props?.color ?? null;
    this.tags = props?.tags || [];
    this.attributes = props?.attributes || {
      expiry_date: new Array<AttributeModel<'date'>>(),
    };
  }

  get midSizeImage() {
    return this.url;
  }

  get queryParam() {
    return `duplicated_group_id=${this.id}`;
  }

  get formattedName() {
    return `IM#${this.id}`;
  }

  get idForModeration() {
    return this.id;
  }

  get linkToModerationPage() {
    return `/image/${this.id}`;
  }

  static createFromImageFeedRawModel(props: ImageFeedRawModel) {
    return new ImageFeedModel({
      color: props.image_status_color,
      creationDate: new Date(props.creation_date),
      id: props.id,
      imageModerationDate: new Date(props.image_moderation_date as string),
      imageModerationMethod:
        props.image_moderation_method as unknown as string as ModerationMethodEnum,
      moderation: new Moderatable({
        canEditModeration: props.can_edit_moderation,
        canEditValidation: props.can_edit_validation,
        canRemoveValidation: props.can_remove_validation,
        canEditQaCheck: props.can_edit_qa_check,
        canViewQaCheck: props.can_view_qa_check,
        canEditReasons: props.can_edit_reasons,
        canViewValidation: props.can_view_validation,
        validated: props.validated,
        moderation: props.image_status,
        moderationColor: props.image_status_color,
        moderationMethod: props.image_moderation_method,
        qaChecked: props.qa_checked,
        qaCheckedDate: props.qa_checked_date
          ? new Date(props.qa_checked_date)
          : undefined,
      }),
      occurences: new Occurrences({
        account: props.occurences_in_accounts,
        post: props.occurrences,
        vendor: props.occurences_in_vendors,
      }),
      attributes: Object.keys(props.attributes).reduce(
        (acc, key) => {
          if (key === 'expiry_date') {
            acc[key] = props.attributes[key].map(
              AttributeModel.createDateFromRaw,
            );
          }
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
      url: props.picture_url,
      rank: props.rank,
      tags: (props.tags || []).map(TagModel.createFromRawModel),
    });
  }

  static createFromAssoicatedImage(props: AccountAssociatedImageRaw) {
    return new ImageFeedModel({
      color: props.image_label_color,
      creationDate: new Date(),
      id: props.id,
      imageModerationDate: null,
      imageModerationMethod: ModerationMethodEnum.all,
      moderation: new Moderatable({
        canEditModeration: props.can_edit_moderation,
        canEditValidation: props.can_edit_validation,
        canRemoveValidation: props.can_remove_validation,
        canEditQaCheck: props.can_edit_qa_check,
        canViewQaCheck: props.can_view_qa_check,
        canEditReasons: props.can_edit_reasons,
        canViewValidation: props.can_view_validation,
        validated: props.validated,
        moderation: props.image_status,
        moderationColor: props.image_label_color,
        moderationMethod: null,
        qaChecked: props.qa_checked,
        qaCheckedDate: undefined,
      }),
      occurences: new Occurrences({
        account: 0,
        post: 0,
        vendor: 0,
      }),
      url: props.image_link,
      rank: NaN,
      tags: (props.tags || []).map(TagModel.createFromRawModel),
      attributes: Object.keys(props.attributes ?? {}).reduce(
        (acc, key) => {
          acc[key] = props?.attributes?.[key].map(
            AttributeModel.createDateFromRaw,
          );
          return acc;
        },
        { expiry_date: new Array<AttributeModel<'date'>>() },
      ),
    });
  }

  static createEmpty() {
    return new ImageFeedModel();
  }
}
